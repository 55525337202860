import { ITaxExemptCodeParams, ITaxTypeParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDTaxType = () => {
  return useCUDMutationEnhancer<ITaxTypeParams>({
    endPoint: ENDPOINTS.taxType(),
    webTable: WebTable.TAX_TYPE
  });
};

export const useCUDTaxExemptCode = () => {
  return useCUDMutationEnhancer<ITaxExemptCodeParams>({
    endPoint: ENDPOINTS.taxExemptCode(),
    webTable: WebTable.TAX_EXEMPT_CODE
  });
};
