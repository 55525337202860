"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const Typography_1 = require("../Typography");
const KTouchable = (0, react_1.forwardRef)((props, ref) => {
    const { onPress, avoidParentPress } = props, otherProps = __rest(props, ["onPress", "avoidParentPress"]);
    const { innerProps, innerStyle } = (0, react_1.useMemo)(() => {
        const { style } = otherProps, rest = __rest(otherProps, ["style"]);
        const { mStyle, mProps } = Typography_1.TypoHelper.destructPropsToStyle(rest);
        const mergeStyles = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ((rest === null || rest === void 0 ? void 0 : rest.disabled) || !onPress ? undefined : touchableStyle)), style), mStyle.layout), mStyle.spacing), mStyle.styling);
        return {
            innerProps: mProps,
            innerStyle: mergeStyles
        };
    }, [onPress, otherProps]);
    const onPressWrapper = (0, react_1.useCallback)((e) => {
        var _a;
        if (!(innerProps === null || innerProps === void 0 ? void 0 : innerProps.disabled)) {
            if (avoidParentPress) {
                (_a = e === null || e === void 0 ? void 0 : e.stopPropagation) === null || _a === void 0 ? void 0 : _a.call(e);
            }
            onPress === null || onPress === void 0 ? void 0 : onPress(e);
        }
    }, [avoidParentPress, innerProps === null || innerProps === void 0 ? void 0 : innerProps.disabled, onPress]);
    return (react_1.default.createElement("div", Object.assign({}, innerProps, { onClick: (0, lodash_1.debounce)(onPressWrapper, 300, {
            leading: true,
            trailing: false
        }), style: innerStyle, ref: ref })));
});
exports.default = (0, react_1.memo)(KTouchable);
const touchableStyle = {
    cursor: 'pointer'
};
