import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { snakeCase } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as yup from 'yup';

import { DATE_FORMAT_SERVER } from '@constants';
import { PreferencesProvider } from 'context/Preferences';
import APIManager from 'services';
import { store } from 'store';
import { APIProvider } from 'uikit';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initLocalization, i18n } from './translation';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'react-toastify/dist/ReactToastify.css';

initLocalization();

yup.setLocale({
  mixed: {
    required: i18n.t('validation.required.default'),
    notType: ({ type }) => {
      switch (type) {
        case 'date':
          return i18n.t('validation.invalid_date');

        default:
          return 'Invalid format';
      }
    }
  },
  string: {
    email: i18n.t('validation.invalid_email')
  },
  number: {
    min: ({ min }) => i18n.t('validation.min_number', { min }),
    max: ({ max }) => i18n.t('validation.max_number', { max }),
    moreThan: ({ more }) =>
      i18n.t('validation.more_than_number', { num: more }),
    lessThan: ({ less }) => i18n.t('validation.less_than_number', { num: less })
  },
  date: {
    min: ({ min }) =>
      i18n.t('validation.min_date', {
        date: moment(min).format(DATE_FORMAT_SERVER)
      })
  },
  array: {
    min: ({ min }) => i18n.t('validation.min_array', { min })
  }
});

const formatError = yup.ValidationError.formatError;

yup.ValidationError.formatError = (message, params) => {
  if (!params.label) {
    params.label = i18n.t(snakeCase(String(params.path)));
  }

  return formatError.call(this, message, params);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: 2e3,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        console.log(e?.message);
        // toast.error(e?.message);
      }
    },
    mutations: {
      onError: (e: any) => {
        console.log(e?.message);
        // toast.error(e?.message);
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <PreferencesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <APIProvider apiInstance={APIManager.request as any}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <App />
            </LocalizationProvider>
          </APIProvider>

          <ToastContainer
            position="top-center"
            hideProgressBar
            limit={3}
            closeButton={false}
            theme="colored"
            pauseOnHover
            pauseOnFocusLoss
          />
        </BrowserRouter>
      </Provider>
    </PreferencesProvider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
