import { createTheme } from '@mui/material/styles';
import { KColors, KFontWeights } from 'uikit';

import breakpoints from './base/breakpoints';
import colors from './base/colors';
import globals from './base/globals';
import typography from './base/typography';
import backdrop from './components/backdrop';
import button from './components/button';
import icon from './components/icon';
import iconButton from './components/iconButton';
import navbar from './components/navbar';
import sidebar from './components/sidebar';

export default createTheme({
  spacing: 4,
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals
      }
    },
    MuiButton: { ...(button as any) },
    MuiIconButton: { ...iconButton },
    MuiDrawer: { ...sidebar },
    MuiAppBar: { ...navbar },
    MuiIcon: { ...icon },
    MuiBackdrop: { ...backdrop },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: 0
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0.25rem !important'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.label && { marginTop: '0.75rem' })
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: '0 !important'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            '& > fieldset > legend > span': {
              display: 'none'
            },
            '&.Mui-disabled': {
              backgroundColor: '#EEEEEE'
            },
            ...(!ownerState.label &&
              ['timeRange', 'keyword'].includes(ownerState.name as string) && {
                '& > fieldset': {
                  top: 0
                }
              })
          };
        },
        input: {
          fontSize: '0.75rem',
          lineHeight: 1.42,
          padding: '0.5rem'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: KColors.black,
          top: -6,
          left: -14
        },
        asterisk: {
          color: KColors.secondary.normal
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          fontSize: '0.625rem',
          fontWeight: 400
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          borderWidth: 1,
          borderColor: KColors.primary.normal,
          borderStyle: 'solid',
          borderRadius: 4,

          '& > .MuiChip-deleteIcon': {
            color: KColors.secondary.normal
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 12
        }
      }
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          minHeight: 48,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: '0.5rem'
        },
        icon: {
          width: 28,
          height: 28
        },
        iconActive: {
          width: 28,
          height: 28
        },
        actions: {
          display: 'flex',
          flex: 'initial',
          // retarget toolbar icons
          // move all icons to the right
          '& > span, & > button': {
            order: 99
          },
          // arrange common icons
          // Download Icon
          '& > span:nth-child(2), & > button:nth-child(2)': {
            order: 1
          },
          // Refresh Icon
          '& > span:nth-child(3), & > button:nth-child(3)': {
            order: 2
          },
          // View Column Icon
          '& > span:nth-child(1), & > button:nth-child(1)': {
            order: 3,
            display: 'inline-flex'
          }
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        data: {
          textTransform: 'capitalize'
        },
        sortAction: {
          alignItems: 'center'
        },
        toolButton: {
          minWidth: '15px',
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: 'normal !important'
        },
        contentWrapper: {
          display: 'block'
        }
      }
    },
    MUIDataTablePagination: {
      styleOverrides: {
        navContainer: {
          justifyContent: 'flex-start'
        },
        tableCellContainer: {
          padding: '0.5rem 0 0 0 !important',
          borderBottom: 'none !important'
        },
        toolbar: {
          padding: '0 !important'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontWeight: KFontWeights.regular
        },
        displayedRows: {
          fontWeight: KFontWeights.regular
        },
        select: {
          fontSize: '0.75rem',
          fontWeight: KFontWeights.medium
        },
        selectIcon: {
          width: 20,
          height: 20,
          top: 3
        }
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&.mui-row-selected': {
            backgroundColor: KColors.white
          }
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: '0.25rem 0.5rem !important',
          fontWeight: 400
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0.25rem 1rem !important'
        },
        footer: {
          borderBottom: 'none'
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        root: {
          backgroundColor: KColors.white,
          padding: '0.25rem 0 !important'
        },
        checkboxRoot: {
          color: `${KColors.primary.normal} !important`
        }
      }
    },
    MUIDataTableViewCol: {
      styleOverrides: {
        root: {
          padding: '1rem 1rem 0.5rem !important',
          minWidth: '200px !important'
        },
        title: {
          fontSize: '0.825rem !important',
          fontWeight: `${KFontWeights.medium} !important`,
          color: `${KColors.black} !important`
        },
        formControl: {
          marginLeft: '-7px !important',
          marginRight: '0 !important'
        }
      }
    }
  }
});
