import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';
import { formatSummary } from 'utils/common';

import { useQueryEnhancer, usePageCUDMutationEnhancer } from '../core';

export const useFetchShipment = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipment, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(':id', { id })
      });

      return Prototype.response.normalizeData(res.data, 'shipmentNumber');
    },
    enabled: !!id
  });
};

export const useCUDShipment = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.shipment(),
    webTable: WebTable.CONSOL_SHIPMENT,
    queryKey: QUERY_KEYS.shipment
  });
};

export const useFetchListAttachShipment = (consolId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.attachShipment],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment('list-for-attach'),
        body: {
          consolId,
          includeFields: [
            'incoterm',
            'notifyParty',
            'consignee',
            'consignor',
            'firstOrigin',
            'finalDes',
            'pol',
            'pod'
          ]
        }
      });
      return res?.data?.data;
    }
  });
};

export const useFetchSummaryShipment = (shipmentId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipmentSummary],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(':id/summary', { id: shipmentId })
      });

      let val = formatSummary(res.data);

      return val || null;
    },
    enabled: !!shipmentId
  });
};

export const useShipmentCheckThreshold = (shipmentId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipmentCheckThreshold],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipment(':shipmentId/check-threshold', { shipmentId })
      });

      return res?.data || null;
    },
    enabled: !!shipmentId
  });
};
