import { IArChargeCodes } from '@invoice';
import { IRowNode } from 'ag-grid-community';
import React from 'react';

import { InvoiceCategory, InvoiceType } from '@constants';
import FormArWip from 'containers/FromWip/Ar/FormArWip';
import trans from 'translation';
import { UIUtils } from 'utils';

import FormExRate from './Form.ExchangeRate';

export const onShowChargeableExRate = (
  node: IRowNode<IArChargeCodes>,
  callBackNodeData: (v: any) => void
) => {
  UIUtils.popup.open({
    title: trans('chargeable_exchange_rate'),
    maxWidth: 'md',
    content: () => {
      return <FormExRate node={node} callBackNodeData={callBackNodeData} />;
    }
  });
};

export const valueChargeableExRate = (exRate: number, uplift: number) => {
  const value = (exRate || 0) * (1 + (uplift || 0) / 100);
  return value;
};

export const onShowListChargeCode = (
  onAddLastRow: ({ params }: { params: any[] }) => void,
  {
    excludeIds,
    paymentCurrencyId,
    exchangeRateDate,
    paramsChargeCode,
    invoiceCategory
  }: {
    excludeIds: any[];
    paymentCurrencyId: any;
    exchangeRateDate: any;
    paramsChargeCode: {
      arClientId: string;
      invoiceType: InvoiceType;
      serviceId?: string;
    };
    invoiceCategory: InvoiceCategory;
  }
) =>
  UIUtils.popup.open({
    title: trans('add_charge_code'),
    maxWidth: false,
    content: () => (
      <FormArWip
        paramsChargeCode={paramsChargeCode}
        onAddLastRow={onAddLastRow}
        excludeIds={excludeIds}
        paymentCurrencyId={paymentCurrencyId}
        exchangeRateDate={exchangeRateDate}
        invoiceCategory={invoiceCategory}
      />
    )
  });
