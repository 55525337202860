/* eslint-disable max-lines */
import { IArChargeCodes } from '@invoice';
import { debounce } from 'lodash';
import React, { memo, useMemo, useState } from 'react';

import {
  ArApType,
  ClientType,
  ENDPOINTS,
  InvoiceCategory,
  InvoiceType,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import {
  isOnBehalfOfClient,
  mapFromWipToTableAr
} from 'containers/FromWip/helpers';
import { useOceanFreightContext } from 'context';
import { useGlobalTable, useLocalTable, useNewButton } from 'hooks';
import { useFetchBillingArInvoiceDetails } from 'hooks/consol/billing';
import { useFetchApOrArInvoice } from 'hooks/consol/service';
import { useFetchInvoiceWip } from 'hooks/wip';
import { listExchangeRate } from 'pages/OceanFreight/Shipment/Billing/helpers';
import trans from 'translation';
import { KColors, KContainer, KInput, KLabel } from 'uikit';
import { TableUtils, UIUtils } from 'utils';

// Ap
const FormArWip = ({
  paramsChargeCode: { arClientId, serviceId, invoiceType, arInvoiceId },
  excludeIds,
  paymentCurrencyId,
  exchangeRateDate,
  invoiceCategory,

  onAddLastRow
}: {
  onAddLastRow?: ({ params }: { params: any[] }) => void;
  excludeIds: any[];
  paymentCurrencyId: any;
  exchangeRateDate: any;
  invoiceCategory: InvoiceCategory;
  paramsChargeCode: {
    arClientId: string;
    invoiceType: InvoiceType;
    serviceId?: string;
    arInvoiceId?: string | number;
  };
}) => {
  const [indexes, setIndexes] = useState<number[]>([]);
  const [listSelectArChargeCode, setListSelectArChargeCode] = useState<any[]>();
  const [taxType, setTaxType] = useState<any>();
  const { ids } = useOceanFreightContext();

  let arInvoice: any;
  if (serviceId) {
    const { data } = useFetchApOrArInvoice({
      id: arInvoiceId,
      clientType: ClientType.AccountReceivable
    });

    arInvoice = data;
  } else {
    const { data } = useFetchBillingArInvoiceDetails(
      ids.consolId,
      ids.shipmentId,
      ids.invoiceId
    );
    arInvoice = data;
  }

  const otherParams = useMemo(() => {
    let _params = serviceId
      ? {
          serviceId
        }
      : {
          consolId: ids.consolId,
          shipmentId: ids.shipmentId
        };
    return {
      excludeIds,
      arClientId,
      isOnBehalfOfClient: isOnBehalfOfClient(invoiceType),
      ..._params
    };
  }, [
    arClientId,
    excludeIds,
    ids.consolId,
    ids.shipmentId,
    invoiceType,
    serviceId
  ]);

  const {
    data: listInvoiceWip,
    refetch,
    isLoading
  } = useFetchInvoiceWip(invoiceCategory, ArApType.ar, otherParams);

  const _listInvoiceWip = useMemo(() => {
    return taxType
      ? (listInvoiceWip || []).filter((item: any) => {
          return item?.taxTypeId === taxType?.id;
        })
      : listInvoiceWip;
  }, [listInvoiceWip, taxType]);

  const columns = useMemo(() => {
    return [
      {
        label: trans('charge_code'),
        name: 'chargeCode.code'
      },
      {
        label: trans('description'),
        name: 'chargeCode.description'
      },
      {
        label: trans('ar_code'),
        name: 'arClient.code'
      },
      {
        label: trans('ar_name'),
        name: 'arClient.name',
        options: TableUtils.options.baseXLg
      },
      {
        label: trans('cont_size'),
        name: 'containerTypeSize.name'
      },
      {
        label: trans('currency'),
        name: 'currency.code'
      },
      {
        label: trans('unit'),
        name: 'chargeCodeUnit.name'
      },
      {
        label: trans('unit_price_quantity'),
        name: 'wipPriceList',
        options: TableUtils.options.withMoreIcon({
          onPress: item => {
            UIUtils.popup.open({
              title: trans('unit_price_quantity'),
              maxWidth: 'xs',
              touchOutsideToDismiss: true,
              content: () => (
                <>
                  {item?.map((v, idx) => {
                    const marginT = idx === 0 ? 0 : '0.75rem';
                    return (
                      <KContainer.View
                        key={`charge-code-level-list-popup-${idx}`}
                        dp="flex"
                        center
                        paddingV="0.25rem"
                        paddingH="0.75rem"
                        br="x"
                        brW={1}
                        brC={KColors.warning.normal}
                        marginT={marginT}
                      >
                        <KLabel.Text typo="TextMdNormal">{v}</KLabel.Text>
                      </KContainer.View>
                    );
                  })}
                </>
              )
            });
          },
          renderItem: (i: any, idx) => {
            const marginL = idx === 0 ? 0 : '0.25rem';
            return (
              <KContainer.View
                key={`charge-code-level-list-popup-${idx}`}
                minW={83}
                height={20}
                marginL={marginL}
                dp="flex"
                center
                background={'#FFF5E5'}
                brC={KColors.warning.normal}
                br="2x"
                brW={1}
              >
                <KLabel.Text color={KColors.warning.dark}>{i}</KLabel.Text>
              </KContainer.View>
            );
          }
        })
      },
      {
        label: trans('est_sub_total'),
        name: 'estSubTotal',
        options: TableUtils.options.number()
      },
      {
        label: trans('tax'),
        name: 'taxType.code'
      },
      {
        label: trans('tax_amount'),
        name: 'taxAmount',
        options: TableUtils.options.number()
      },
      {
        label: trans('est_total_amount'),
        name: 'estTotal',
        options: TableUtils.options.number()
      },
      {
        label: trans('created_by'),
        name: 'createdUsername'
      },
      {
        label: trans('updated_by'),
        name: 'updatedUsername'
      },
      {
        label: trans('created_at'),
        name: 'createdAt'
      },
      {
        label: trans('updated_at'),
        name: 'updatedAt'
      }
    ];
  }, []);

  const table = useLocalTable({
    name: WebTable.BILLING_ADD_AR_CHARGE_CODE,
    columns,
    data: _listInvoiceWip || [],
    showTimeRange: true,
    onRefresh: refetch
  });

  useGlobalTable(table);

  const newButton = useNewButton({
    title: trans('add'),
    marginR: '0.75rem',
    textColor: KColors.primary.normal,
    disabled: !indexes.length,
    onPress: debounce(async () => {
      const currencyId = arInvoice?.paymentCurrencyId || paymentCurrencyId;
      const exchangeRateList = await listExchangeRate(
        table?.data?.data,
        currencyId,
        arInvoice?.exchangeRateDate || exchangeRateDate
      );
      const newListSelect: IArChargeCodes[] = indexes.map((i: number) => {
        const item = table?.data?.data?.[i];
        return mapFromWipToTableAr(item, exchangeRateList[i]);
      });
      onAddLastRow?.({
        params: newListSelect
      });
      UIUtils.popup.dismiss();
    }, 400)
  });

  return (
    <>
      <DataTable
        {...table}
        isLoading={isLoading}
        options={{
          ...table.options,
          rowsSelected: indexes,
          onRowSelectionChange: (_cur, _row) => {
            const curValue = table?.data?.data?.[_cur?.[0]?.index];
            let newListSelect = JSON.parse(
              JSON.stringify(listSelectArChargeCode || [])
            );
            const isCur = (listSelectArChargeCode || []).some(
              o => o?.fromArChargeCodeId === curValue?.id
            );

            if (isCur) {
              newListSelect = newListSelect.filter(
                (o: any) => o?.fromArChargeCodeId !== curValue?.id
              );
            } else {
              newListSelect.push({
                fromArChargeCodeId: curValue?.id,
                arChargeCodeLevels: curValue?.arChargeCodeLevels
              });
            }

            setListSelectArChargeCode(newListSelect ?? []);
            setIndexes(_row.map(o => o?.index));
          }
        }}
        leftNode={
          <KContainer.View marginB={'0.75rem'} minW={'200px'}>
            <KInput.Autocomplete
              label={trans('tax_type')}
              apiURL={ENDPOINTS.taxType()}
              getOptionLabel={(o: any) => o?.code ?? ''}
              value={taxType}
              onChange={(v: any) => {
                setTaxType(v);
              }}
            />
          </KContainer.View>
        }
      />

      {newButton}
    </>
  );
};

export default memo(FormArWip);
