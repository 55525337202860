export default {
  required: {
    default: 'This field cannot be blank',
    default_number: 'This field is a number',
    number_greater_than: 'Number greater than',
    number_must_be_less_than_or_by: 'Number must be less than or by'
  },
  custom: {
    at_least_one: 'Please select at least one record to continue',
    existed_role: 'This role name already exists',
    re_password_not_match: 'Password does not match. Please retype'
  },
  invalid_date: 'Invalid date format',
  invalid_email: 'Invalid email format',
  invalid_container_no: 'Invalid container number',
  this_is_an_integer: 'This Is An Integer',

  must_not_be_after_eta: 'Must not be after ETA',
  must_not_be_after_ata: 'Must not be after ATA',
  must_not_be_before_etd: 'Must not be before ETD',
  must_not_be_before_atd: 'Must not be before ATD',
  pickup_delivery_not_same: 'Pickup and delivery cannot be the same',

  must_not_be_after_final_eta: 'Must not be after FINAL ETA',
  must_not_be_before_pod_eta: 'Must not be before POD ETA',
  must_not_be_after_final_ata: 'Must not be after FINAL ATA',
  must_not_be_after_polAtd_podAta_lastAta_finalAta:
    'Must not be after POL ATD, POD ATA, LAST ATA, FINAL ATA',
  must_not_be_before_firstAtd_polAtd_lastAta:
    'Must not be before FIRST ATD, POL ATD, LAST ATA',
  must_not_be_before_firstAtd_polAtd_lastAta_podAta:
    'Must not be before, FIRST ATD, POL ATD, LAST ATA, POD ATA',
  must_not_be_before_firstAtd_polAtd_podAta:
    'Must not be before FIRST Atd, POL ATD, POD ATA',
  must_not_be_before_firstEtd_polEtd_lastEta:
    'Must not be before FIRST ETD, POL ETD, LAST ETA',
  must_not_be_after_polEtd_cldEtd_podEta_finalEta:
    'Must not be after POL ETD, LAST ETD, POD ETA, FINAL ETA',

  must_not_be_after_finalEta: 'Must not be after finalEta',

  must_not_be_after_polEtd: 'Must not be after POL ETD',
  must_not_be_after_podEta: 'Must not be after podEta',
  must_not_be_before_first_etd: 'Must not be before FIRST ETD',
  must_not_be_before_polEtd: 'Must not be before POL ETD',

  must_not_be_after_polAtd: 'Must not be after POL ATD',
  must_not_be_before_first_atd: 'Must not be before FIRST ATD',
  must_not_be_before_polAtd: 'Must not be before POL ATD',
  must_not_be_before_podAta: 'Must not be before POL ATA',

  min_number: 'Min number is {{min}}',
  max_number: 'Max number is {{max}}',
  more_than_number: 'Value must be greater than {{num}}',
  less_than_number: 'Value must be less than {{num}}',

  min_date: 'Min date is {{date}}',
  min_array: 'Please select at least {{min}} record(s)',

  first_loading_last_discharge:
    '1st Loading and Last Discharge cannot be the same.',
  pol_pod: 'Port of Loading and Port of Discharge cannot be the same.',
  unknown_timezone: 'Unknown timezone',
  the_total_of_the_sale_must_be_100:
    'The total ratio of all sale persons must be 100%',
  have_not_entered_the_code: 'Have not entered the code',

  client_ar_credit_hold: 'The credit of this client is hold',

  must_be_between: 'Must be between {{min}} and {{max}}',
  select_at_least_one: 'Select At Least One',
  select_at_most_one: 'Select At Most One',
  unit_must_not_be_empty: 'Unit Must Not Be Empty',
  min_max_markup_level: 'Markup Level 1 < Level 2 < Level 3',
  some_charge_codes_are_duplicated:
    'Some Charge Codes Are Duplicated: {{chargeCodes}}',

  in_correct_currency: 'In Correct Currency',
  total_quantity_is_not_equal_to_sum_of_level_quantity:
    'Total Quantity Is Not Equal To Sum Of Level Quantity',
  conflicted_currency_with_this_wip: 'Conflicted Currency With This Wip',
  service_uniques_address: 'Service uniques address',
  duplicate_trip: 'Duplicate Trip',
  greater_than_or_equal: 'Greater Than Or Equal {{temp}}',
  less_than_or_equal: 'Less Than Or Equal {{temp}}',
  must_be_at_least_a_deposit_or_withdraw:
    'Must be at least a deposit or withdraw',
  choose_one_of_the_two: 'Choose One Of The Two'
};
