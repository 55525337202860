import { DataTableProps } from '@ui';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { memo, useCallback, useMemo } from 'react';
import { KLoading } from 'uikit';
import { v4 as uuidv4 } from 'uuid';

import { noActionColumns } from './helpers';
import LeftActions from './LeftActions';
import RightActions from './RightActions';

const DataTable = (props: DataTableProps) => {
  const {
    id,
    columns,
    data,
    options,
    keyword,
    showFilter = true,
    showDownload = true,
    showRefresh = true,
    advanceSearch,
    searchConditions,
    selectedData,
    isLoading,
    isModifying,
    excludedExportColumns,
    showStatus = false,
    showTimeRange = false,
    showSearch = true,
    status,
    timeRange,
    isLocal,
    showRightActions = true,
    leftNode,
    leftButtons,
    rightButtons,

    filterScope: scope = 'page',
    filterZIndex,

    setKeyword,
    setSearchConditions,
    setStatus,
    setTimeRange,

    init,

    onAdd,
    onCopy,
    onDelete,
    onDownload,
    onRefresh
  } = props;

  const randomId = useMemo(() => id || uuidv4(), [id]);

  const renderLeftActions = useMemo(() => {
    return (
      <LeftActions
        id={randomId}
        keyword={keyword}
        setKeyword={setKeyword}
        setSearchConditions={setSearchConditions}
        advanceSearch={advanceSearch}
        searchConditions={searchConditions}
        onAdd={onAdd}
        onCopy={onCopy ? () => onCopy?.(selectedData) : undefined}
        onDelete={onDelete ? () => onDelete?.(selectedData) : undefined}
        selectedData={selectedData}
        showStatus={showStatus}
        showTimeRange={showTimeRange}
        setStatus={setStatus}
        setTimeRange={setTimeRange}
        status={status}
        timeRange={timeRange}
        showFilter={showFilter}
        leftNode={leftNode}
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        showSearch={showSearch}
        filterZIndex={filterZIndex}
        filterScope={scope}
      />
    );
  }, [
    advanceSearch,
    filterZIndex,
    keyword,
    leftButtons,
    leftNode,
    onAdd,
    onCopy,
    onDelete,
    randomId,
    rightButtons,
    scope,
    searchConditions,
    selectedData,
    setKeyword,
    setSearchConditions,
    setStatus,
    setTimeRange,
    showFilter,
    showSearch,
    showStatus,
    showTimeRange,
    status,
    timeRange
  ]);

  const renderLeftActionsForLocal = useCallback(
    (searchText: string, handleSearch: (text: string) => void) => {
      return (
        <LeftActions
          id={randomId}
          keyword={searchText}
          showSearch={showSearch}
          setKeyword={handleSearch}
          setSearchConditions={setSearchConditions}
          advanceSearch={[]}
          searchConditions={[]}
          selectedData={selectedData}
          showFilter={false}
          onDelete={onDelete ? () => onDelete?.(selectedData) : undefined}
          onAdd={onAdd}
          leftNode={leftNode}
          leftButtons={leftButtons}
          rightButtons={rightButtons}
        />
      );
    },
    [
      leftButtons,
      leftNode,
      onAdd,
      onDelete,
      randomId,
      rightButtons,
      selectedData,
      setSearchConditions,
      showSearch
    ]
  );

  const renderRightActions = useMemo(() => {
    return (
      <RightActions
        id={randomId}
        onDownload={onDownload}
        showDownload={showDownload}
        showRefresh={showRefresh}
        onRefresh={onRefresh || init}
        columns={columns.filter(
          i =>
            i.name &&
            ![...noActionColumns(), ...(excludedExportColumns || [])].includes(
              i.label
            )
        )}
      />
    );
  }, [
    columns,
    excludedExportColumns,
    init,
    onDownload,
    onRefresh,
    randomId,
    showDownload,
    showRefresh
  ]);

  const mOptions: MUIDataTableOptions = useMemo(() => {
    const _options = {
      ...options,
      customToolbar: () => (showRightActions ? renderRightActions : <></>)
    };
    if (isLocal) {
      _options.customSearchRender = renderLeftActionsForLocal;
    }
    if (!showRightActions) {
      _options.viewColumns = false;
    }

    return _options;
  }, [
    isLocal,
    options,
    renderLeftActionsForLocal,
    renderRightActions,
    showRightActions
  ]);

  return (
    <div style={{ position: 'relative' }}>
      {(isModifying || isLoading) && <KLoading thickness={3} />}

      <MUIDataTable
        title={isLocal ? <></> : renderLeftActions}
        data={data?.data}
        columns={columns}
        options={mOptions}
      />
    </div>
  );
};

export default memo(DataTable);
