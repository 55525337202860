import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';
import { usePageCUDMutationEnhancer, useQueryEnhancer } from 'hooks/core';
import {
  AP_CLIENT_TO,
  MoneySource
} from 'pages/Accounting/Bankingg/Money/helpers';

export const useSpendReceiveMoney = ({
  category,
  params
}: {
  category: MoneySource;
  params: {
    id?: string;
  };
}) => {
  const moneyId = params?.id;
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, moneyId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          category,
          path: ':id',
          query: params
        })
      });

      return res.data;
    },
    enabled: !!moneyId,
    select(data: any) {
      if (!data.isFromWip) {
        const details = data.details.map((item: any) => {
          return {
            ...item,
            client: item.client
              ? { ...item.client, apClientTo: AP_CLIENT_TO.input }
              : null
          };
        });
        data.details = details;
        return data;
      } else {
        return data;
      }
    }
  });
};

export const useCUDBankingPayment = ({
  category,
  isFromWip
}: {
  category: MoneySource;
  isFromWip: boolean;
}) => {
  const screen = {
    [MoneySource.SPEND]: {
      webTable: WebTable.SPEND_MONEY,
      queyKey: QUERY_KEYS.spendBankingPayment
    },
    [MoneySource.RECEIVE]: {
      webTable: WebTable.RECEIVE_MONEY,
      queyKey: QUERY_KEYS.receiveBankingPayment
    }
  };
  const endPoint = isFromWip
    ? ENDPOINTS.bankingPayment({
        category,
        path: '/from-wip'
      })
    : ENDPOINTS.bankingPayment({
        category
      });
  return usePageCUDMutationEnhancer({
    mutationKeys: [[screen[category].queyKey]],
    endPoint,
    webTable: screen[category].webTable,
    queryKey: screen[category].queyKey
  });
};

export const useSpendReceiveWip = ({
  category,
  params
}: {
  category: MoneySource;
  params: {
    excludeIds: string[];
    clientId: string;
  };
}) => {
  const screen = {
    [MoneySource.SPEND]: {
      path: 'ap-wip'
    },
    [MoneySource.RECEIVE]: {
      path: 'ar-wip'
    }
  };
  const clientId = params?.clientId;
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          category,
          path: screen[category].path,
          query: params
        }),
        body: {
          excludeIds: params.excludeIds,
          includeFields: [
            'chargeCode',
            'apClient',
            'arClient',
            'addrFrom',
            'addrTo',
            'taxType',
            'containerTypeSize',
            'chargeCodeUnit',
            'wipPrices',
            'wipSubChargeCodes',
            'onBehalfClient',
            'currency',
            'consol',
            'address'
          ],
          apClientId: clientId,
          arClientId: clientId
        }
      });

      return res.data;
    },
    enabled: !!clientId
  });
};
