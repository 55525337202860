import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { WithPopperProps } from '@ui';
import { isEqual, last } from 'lodash';
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  useImperativeHandle
} from 'react';

import { Z_INDEX_PRIORITY } from '@constants';
import { KContainer, KPopperProps } from 'uikit';

const KPopper = forwardRef<WithPopperProps>((_, ref) => {
  const queue = useRef<KPopperProps[]>([]);

  const [data, setData] = useState<KPopperProps | undefined>(undefined);

  const {
    content,
    touchOutsideToDismiss,
    maxW,
    maxH,
    padding,
    cardProps,
    sx,
    withMaxZIndex,
    onDismiss,
    ...rest
  } = data || {};

  const dismiss = useCallback(() => {
    const newData = queue.current.shift();
    setData(newData);
    onDismiss?.();
  }, [onDismiss]);

  useImperativeHandle(
    ref,
    () => ({
      open: payload => {
        if (!isEqual(last(queue.current), payload)) {
          if (data) {
            queue.current?.push(payload);
          } else {
            setData(payload);
          }
        }
      },
      dismiss,
      dismissAll: () => {
        setData(undefined);
        queue.current = [];
      }
    }),
    [data, dismiss]
  );

  const renderBody = useMemo(() => {
    if (!data || !content) {
      return null;
    }

    return content(dismiss);
  }, [data, content, dismiss]);

  const _content = (
    <Popper
      open={true}
      {...rest}
      sx={{ zIndex: withMaxZIndex ? Z_INDEX_PRIORITY.popper : 1, ...sx }}
    >
      <KContainer.Card
        maxH={maxH || 500}
        maxW={maxW || 600}
        overflow="hidden"
        padding={padding || '0.5rem'}
        {...cardProps}
      >
        {renderBody}
      </KContainer.Card>
    </Popper>
  );

  if (!data) {
    return null;
  }

  if (touchOutsideToDismiss) {
    return (
      <ClickAwayListener onClickAway={dismiss}>{_content}</ClickAwayListener>
    );
  }

  return _content;
});

export default memo(KPopper);
