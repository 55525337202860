import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KGrid, KInput, KForm } from 'uikit';
import * as yup from 'yup';

import {
  TypeAdjustment,
  WebTable,
  generateOptions,
  tableRef
} from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm } from 'hooks';
import {
  KeyInvoiceEnum,
  clientHelpers
} from 'pages/Accounting/Banking/helpers';
import trans from 'translation';
import { UIUtils } from 'utils';

interface IFormData {
  type: KeyInvoiceEnum;
  adjustmentType: TypeAdjustment;
  description: string;
  payment?: number | string;
}

interface IProps {
  setListSelected: React.Dispatch<React.SetStateAction<any[]>>;
  amount?: number | string;
  currencyCode?: string;
}

const AdjustmentForm = ({ setListSelected, amount, currencyCode }: IProps) => {
  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({}),
    configs: {
      values: {
        type: KeyInvoiceEnum.reconcileAdjustment,
        adjustmentType: TypeAdjustment.BANK_FEE,
        description: '',
        payment: amount
      }
    }
  });

  const { isAccountReceivable, clientValue } = clientHelpers(
    tableRef[WebTable.BANKING_BANK_TRANSACTION]?.data?.data?.[0]
  );

  const onFormValid = useCallback(
    (dataForm: IFormData) => {
      const keyClient = isAccountReceivable
        ? 'accountReceivable'
        : 'accountPayable';

      setListSelected(pre => {
        pre.push({
          ...dataForm,
          [keyClient]: clientValue
        });
        return JSON.parse(JSON.stringify(pre));
      });

      UIUtils.popup.dismiss();
    },
    [clientValue, isAccountReceivable, setListSelected]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <Controller
            name="adjustmentType"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('type')}
                  options={generateOptions(TypeAdjustment)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="currency"
            value={currencyCode}
            disabled
            label={trans('currency')}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="amount"
            value={Prototype.number.formatNumber(amount)}
            disabled
            label={trans('amount')}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField {...field} label={trans('description')} />
              );
            }}
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions btnProps={[{ isLoading: false }]} />
    </KForm>
  );
};

export default memo(AdjustmentForm);
