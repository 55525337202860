import React, { useCallback } from 'react';
import { KColors } from 'uikit';

import { ENDPOINTS, InvoiceTypeUrl, TTypeAdvice } from '@constants';
import { useCUDBankTransaction, useDeleteHelper, useUnreconciled } from 'hooks';
import trans from 'translation';
import { UIUtils } from 'utils';

import Form from './BankTransaction/Form';
import FormAddSelectedList from './BankTransaction/SelectedList/Form.Add';
import FormAdjustment from './BankTransaction/SelectedList/Form.Adjustment';
import FormReconcile from './Reconcile/Form';

export enum TypeTransaction {
  Matched = 'Matched',
  Selected = 'Selected'
}

export enum KeyInvoiceEnum {
  arInvoice = 'AR_INVOICE',
  apInvoice = 'AP_INVOICE',
  receivableAdvice = 'RECEIVABLE_ADVICE',
  paymentAdvice = 'PAYMENT_ADVICE',
  reconcileAdjustment = 'RECONCILE_ADJUSTMENT'
}

export const typeInvoice = [
  {
    key: KeyInvoiceEnum.arInvoice,
    label: 'ar_invoices',
    deposit: true,
    noDeposit: true
  },
  {
    key: KeyInvoiceEnum.apInvoice,
    label: 'ap_invoices',
    deposit: true,
    noDeposit: true
  },
  {
    key: KeyInvoiceEnum.receivableAdvice,
    label: 'receivable_advice',
    deposit: true,
    noDeposit: false
  },
  {
    key: KeyInvoiceEnum.paymentAdvice,
    label: 'payment_advice',
    deposit: false,
    noDeposit: true
  }
];

export const onShowAddSelectedTransaction = (
  isAdjustment: boolean,
  setListSelected: React.Dispatch<React.SetStateAction<any[]>>,
  objData: {
    transactionId?: number | string;
    isDeposit?: boolean;
    amount?: number | string;
    currencyCode?: string;
  }
) => {
  UIUtils.popup.open({
    title: trans('selected_transaction'),
    maxWidth: false,
    content: () => {
      const { transactionId, amount, currencyCode, isDeposit } = objData;
      return isAdjustment ? (
        <FormAdjustment
          setListSelected={setListSelected}
          amount={amount}
          currencyCode={currencyCode}
        />
      ) : (
        <FormAddSelectedList
          setListSelected={setListSelected}
          transactionId={transactionId}
          isDeposit={isDeposit}
        />
      );
    }
  });
};

export const onShowFormReconcile = (v: any) => {
  UIUtils.popup.open({
    title: trans('reconcile_detail'),
    maxWidth: 'lg',
    content: () => {
      return <FormReconcile item={v} />;
    }
  });
};

export const urlSelectedTransactions = {
  [KeyInvoiceEnum.arInvoice]: ENDPOINTS.arOrApInvoice(InvoiceTypeUrl.ar),
  [KeyInvoiceEnum.apInvoice]: ENDPOINTS.arOrApInvoice(InvoiceTypeUrl.ap),
  [KeyInvoiceEnum.receivableAdvice]: ENDPOINTS.advice(
    TTypeAdvice.receivableAdvice
  ),
  [KeyInvoiceEnum.paymentAdvice]: ENDPOINTS.advice(TTypeAdvice.paymentAdvice)
};

export const keyNotUpdatePayment = {
  [KeyInvoiceEnum.reconcileAdjustment]: true,
  [KeyInvoiceEnum.paymentAdvice]: true,
  [KeyInvoiceEnum.receivableAdvice]: true
};

export const clientHelpers = (firstData: any) => {
  const isAccountReceivable = !!firstData?.accountReceivableId;
  const clientValue =
    firstData?.accountReceivable || firstData?.accountPayable || null;
  const clientId = firstData?.clientId;

  return { isAccountReceivable, clientValue, clientId };
};

export const useDeleteBankTransactionHelper = () => {
  const {
    deleteMutation: { mutate, ...rest }
  } = useCUDBankTransaction();

  const onDelete = useDeleteHelper({
    name: 'bank_transaction',
    mutate
  });

  return { onDelete, ...rest };
};

export const useUndoReconcile = () => {
  const {
    unreconciledMutation: { mutate, isLoading }
  } = useUnreconciled();

  const onUndoReconcile = useCallback(
    (id: number, onSuccess?: (data: any) => void) => {
      UIUtils.alert.open({
        message: trans('message.unreconciled'),
        buttons: [
          {
            title: trans('undo'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () =>
              mutate(
                { id },
                {
                  onSuccess
                }
              )
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [mutate]
  );

  return { onUndoReconcile, isLoading };
};

interface IId {
  id: number | string;
}

export const mapListData = (listData: IId[]) => {
  const res = listData.reduce((pre, cur) => {
    pre[cur.id] = cur;
    return pre;
  }, {});
  return res;
};

export const onShowAddBankTransaction = () => {
  return UIUtils.popup.open({
    title: trans('bank_transaction'),
    maxWidth: 'xl',
    content: () => <Form />
  });
};
