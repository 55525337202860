import React, { createRef, memo, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import {
  useDataTable,
  useGlobalTable,
  useNewButton,
  usePopperListItem
} from 'hooks';
import trans from 'translation';
import {
  KBreadcrumbs,
  KChip,
  KColors,
  KContainer,
  KLabel,
  KListItemBaseItemProps
} from 'uikit';
import { TableUtils, UIUtils } from 'utils';

import { useDeleteClientsHelper } from './helpers';

const Client = () => {
  const navigate = useNavigate();

  const ref = createRef<HTMLButtonElement>();

  const { onDelete, isLoading } = useDeleteClientsHelper();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => navigate(`${item.id}`)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('is_corporate'),
          name: 'isPerson',
          options: TableUtils.options.iconCheckCircle({ revertKey: true })
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    navigate(`${item.id}`);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('name'),
          name: 'name',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('country'),
          name: 'defaultAddress.countryName'
        },
        {
          label: trans('state'),
          name: 'defaultAddress.stateName'
        },
        {
          label: trans('city'),
          name: 'defaultAddress.cityName'
        },
        {
          label: trans('suburb'),
          name: 'defaultAddress.suburbName'
        },
        {
          label: trans('unloco'),
          name: 'defaultAddress.unlocoCode'
        },
        {
          label: trans('telephone'),
          name: 'defaultAddress.telephone',
          options: {
            customBodyRender: (v?: string, rowData?: any) => {
              if (v) {
                const item = data?.[rowData.rowIndex];
                return [item?.country?.phoneCountryCode?.trim(), v.trim()]
                  .filter(i => i)
                  .join('');
              }
              return '';
            }
          }
        },
        {
          label: trans('email'),
          name: 'email'
        },
        {
          label: trans('office_full_address'),
          name: 'defaultAddress.fullAddress',
          options: TableUtils.options.base3XLg
        },
        {
          label: trans('tax_id'),
          name: 'taxId'
        },
        {
          label: trans('stage'),
          name: 'clientStageName',
          options: TableUtils.options.chip({
            uppercase: true,
            props: {
              background: KColors.palette.blue.w50,
              brC: KColors.palette.blue.w600,
              color: KColors.palette.blue.w600,
              style: { whiteSpace: 'nowrap' }
            }
          })
        },
        {
          label: trans('client_group'),
          name: 'clientGroup',
          options: TableUtils.options.chip({
            uppercase: true,
            props: {
              background: KColors.palette.blue.w50,
              brC: KColors.palette.blue.w600,
              color: KColors.palette.blue.w600,
              style: { whiteSpace: 'nowrap' }
            }
          })
        },
        {
          label: trans('category'),
          name: 'clientCategoryName',
          options: TableUtils.options.chip({
            uppercase: true,
            props: {
              background: KColors.palette.blue.w50,
              brC: KColors.palette.blue.w600,
              color: KColors.palette.blue.w600,
              style: { whiteSpace: 'nowrap' }
            }
          })
        },
        {
          label: trans('client_type'),
          name: 'clientTypes',
          options: TableUtils.options.withMoreIcon({
            onPress: v => {
              UIUtils.popup.open({
                title: trans('client_type'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`client-type-popup-${i.id}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.warning.dark}
                          marginT={marginT}
                        >
                          <KLabel.Text
                            typo="TextMdNormal"
                            textTransform="uppercase"
                          >
                            {i.name}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx) => {
              const marginL = idx === 0 ? 0 : '0.5rem';
              return (
                <KChip
                  key={`client-type-${i.id}`}
                  background={KColors.palette.warning.w50}
                  brC={KColors.warning.dark}
                  label={i.name}
                  textTransform={'uppercase'}
                  color={KColors.warning.dark}
                  marginL={marginL}
                  style={{ whiteSpace: 'nowrap' }}
                />
              );
            }
          })
        },
        {
          label: trans('ecus_client_type'),
          name: 'ecusClientTypes',
          options: TableUtils.options.withMoreIcon({
            onPress: v => {
              UIUtils.popup.open({
                title: trans('ecus_client_type'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {v.map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`ecus-client-type-popup-${i.id}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.warning.dark}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">
                            {i.name}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (i, idx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KChip
                  key={`ecus-client-type-${i.id}`}
                  background={KColors.palette.warning.w50}
                  brC={KColors.warning.dark}
                  label={i.name}
                  textTransform={'uppercase'}
                  color={KColors.warning.dark}
                  marginL={marginL}
                  style={{ whiteSpace: 'nowrap' }}
                />
              );
            }
          })
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('last_update'),
          name: 'updatedAt'
        }
      ];
    },
    [navigate, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: [
        'country',
        'clientTypes',
        'ecusClientTypes',
        'defaultAddress'
      ]
    };
  }, []);

  const mappedFields = useMemo(() => {
    return {
      'defaultAddress.countryName': 'country',
      'defaultAddress.cityName': 'city',
      'defaultAddress.stateName': 'state',
      'defaultAddress.suburbName': 'suburb',
      'defaultAddress.unlocoCode': 'unloco',
      'defaultAddress.telephone': 'telephone',
      'defaultAddress.fullAddress': 'fullAddress',
      clientStageName: 'clientStage',
      clientCategoryName: 'clientCategory'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.CLIENT,
    tableName: TableName.CLIENT,
    apiURL: ENDPOINTS.client(),
    columnsFunc,
    otherParams,
    mappedFields,
    otherOptions: {
      selectableRows: 'none'
    }
  });

  useGlobalTable(table);

  const toolsData = useMemo(() => {
    const _data: KListItemBaseItemProps[] = [
      {
        title: trans('import_excel'),
        icon: {
          name: 'CloudUploadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('export_excel'),
        icon: {
          name: 'CloudDownloadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('print'),
        icon: {
          name: 'Print',
          color: KColors.primary.normal
        },
        onPress: () => {}
      }
    ];
    return _data;
  }, []);

  const { onPress } = usePopperListItem({ btnRef: ref, data: toolsData });

  const onAdd = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        title={trans('routes.client')}
        rightNode={{
          tools: {
            ref,
            onPress
          }
        }}
      />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={onAdd}
          onDelete={onDelete}
          isModifying={isLoading}
        />
      </KContainer.Card>

      {newButton}

      <Outlet />
    </>
  );
};

export default memo(Client);
