import { isEmpty } from 'lodash';
import { ILinkedChartOfAccountResponse } from 'response-dto';

import { ENDPOINTS, LinkedAccountCode, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDClassification = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.classification(),
    webTable: WebTable.CLASSIFICATION,
    dependentWebTable: [WebTable.CHART_OF_ACCOUNTS]
  });
};

export const useCUDChartOfAccount = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.chartOfAccount(),
    webTable: WebTable.CHART_OF_ACCOUNTS
  });
};

export const useFetchLinkedChartOfAccount = () => {
  return useQueryEnhancer<ILinkedChartOfAccountResponse | null>({
    queryKey: [QUERY_KEYS.linkedChartOfAccount],
    queryFn: async () => {
      const { data } = await APIManager.request({
        url: ENDPOINTS.linkedChartOfAccount()
      });

      if (!isEmpty(data)) {
        const obj: any = {};
        Object.keys(data).forEach(i => {
          obj[i] = data[i].map((d: any) => {
            return {
              ...d,
              code: d.subCategory,
              name: d.subCategoryName,
              chartOfAccount: d.chartOfAccountId
                ? {
                    id: d.chartOfAccountId,
                    displayName: d.chartOfAccountDisplayName,
                    currencyCode: [
                      LinkedAccountCode.SalesAssetUSD,
                      LinkedAccountCode.PurchasesAssetUSD
                    ].includes(d.subCategory)
                      ? 'USD'
                      : 'VND'
                  }
                : undefined
            };
          });
        });
        return obj;
      }

      return null;
    }
  });
};

export const useModifyLinkedChartOfAccount = () => {
  return useMutationEnhancer<any, any>({
    mutationKeys: [[QUERY_KEYS.linkedChartOfAccount]],
    mutationFn: data =>
      APIManager.request({
        url: ENDPOINTS.linkedChartOfAccount(),
        method: 'POST',
        body: data,
        showToast: true
      })
  });
};
