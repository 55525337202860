import { IArChargeCodes } from '@invoice';
import { IRowNode } from 'ag-grid-community';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { KContainer, KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import { BottomActions } from 'components';
import { useResolverForm } from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

import { valueChargeableExRate } from './helper';

import FormInfoChargeCode from '../../../Form.InfoChargeCode';

interface IFormData {
  exchangeRate: number;
  uplift: number;
}
interface IProps {
  node: IRowNode<IArChargeCodes>;
  callBackNodeData: (v: any) => void;
}

const FormExRate = ({ node, callBackNodeData }: IProps) => {
  const { data: item } = node;

  const { chargeCode, arChargeCodeFrom, arChargeCodeTo, containerTypeSize } =
    item as IArChargeCodes;

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({
      exchangeRate: ValidationUtils.requiredNumberNullAble(),
      uplift: ValidationUtils.requiredMinMaxNumber()
    }),
    configs: {
      values: {
        exchangeRate: item?.exchangeRate ?? 0,
        uplift: item?.uplift ?? 0
      }
    }
  });

  const [exchangeRate, uplift] = useWatch({
    control: methods.control,
    name: ['exchangeRate', 'uplift']
  });

  const chargeableExRate = valueChargeableExRate(
    Number(exchangeRate),
    Number(uplift)
  );

  const onFormValid = useCallback(
    (data: IFormData) => {
      callBackNodeData({
        ...(item as IArChargeCodes),
        exchangeRate: +data?.exchangeRate,
        uplift: +data?.uplift,
        chargeableExchangeRate: chargeableExRate
      });
    },
    [callBackNodeData, chargeableExRate, item]
  );

  const infoChargeCode = useMemo(() => {
    const list = [
      {
        label: 'charge_code',
        value: chargeCode?.code
      },
      {
        label: 'from',
        value: arChargeCodeFrom?.code
      },
      {
        label: 'to',
        value: arChargeCodeTo?.code
      },
      {
        label: 'container_size',
        value: containerTypeSize?.code
      }
    ];

    return list;
  }, [
    arChargeCodeFrom?.code,
    arChargeCodeTo?.code,
    chargeCode?.code,
    containerTypeSize?.code
  ]);

  return (
    <KContainer.View>
      <KContainer.View dp="flex" row justifyContent="space-between">
        <FormInfoChargeCode infoChargeCode={infoChargeCode} />
      </KContainer.View>

      <KContainer.View marginT={'2.5rem'}>
        <FormProvider {...methods}>
          <KForm onSubmit={methods.handleSubmit(onFormValid)}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <Controller
                  name="exchangeRate"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('ex_rate')}
                        required
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <Controller
                  name="uplift"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        label={trans('with_unit.uplift', { unit: '' })}
                        required
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField
                  name="chargeableExRate"
                  label={trans('chargeable_ex_rate')}
                  value={chargeableExRate}
                  disabled
                />
              </KGrid.Item>
            </KGrid.Container>
            <BottomActions
              btnProps={[
                {
                  //   isLoading: modifyLoading
                }
              ]}
            />
          </KForm>
        </FormProvider>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(FormExRate);
