import { createModel } from '@rematch/core';

import { RootModel } from '.';

interface AuthState {
  token?: string;
}

const init: AuthState = {
  token: undefined
};

export const auth = createModel<RootModel>()({
  state: init, // initial state
  reducers: {
    setToken: (state: AuthState, token?: string) => {
      return {
        ...state,
        token
      };
    }
  },
  effects: dispatch => ({
    // eslint-disable-next-line require-await
    async login(payload: any): Promise<any> {
      console.log(payload);
      dispatch.auth.setToken('abc');
      return true;
    }
  })
});
