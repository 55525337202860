import React from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { SpendReceiveStatus } from '@constants';
import trans from 'translation';
import { KChip } from 'uikit';
import { UIUtils, ValidationUtils, mappedSpendReceiveMoneyStatus } from 'utils';

import FormBankingPaymentWip from './common/ChargeCodeList/FormBankingPaymentWip';

export interface IMoney {
  client: any;
  address: any;
  companyBank: any;
  code: string;
  paymentMode: any;
  currency: any;
  description: any;
  chargeCodes: any[];
  accountingNote: any;
  resources: string[];
  status: any;
  syncBy: any;
  syncAt: any;
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  isFromWip: boolean;
  details: any[];
  estSubTotal: number;
  estTotal: number;
  taxAmount: number;
  resource: number[];
  subTotal: number;
  totalAmount: number;
  bankingPaymentStatus: SpendReceiveStatus;
}

export type IParamsBankingPayment = {
  companyBankId: number;
  currencyId: number;
  description: string;
  paymentModeId: number;
  accountingNoteId: number;
  subTotal: number;
  totalAmount: number;
  taxAmount: number;
  resourceIds: string[];
  clientId: number;
  details: {
    chartOfAccountId: number;
    chargeCodeId: number;
    chargeCodeUnitId: number;
    description: string;
    clientId: number;
    currencyId: number;
    taxTypeId: number;
    quantity: number;
    unitPrice: number;
    subTotal: number;
    exchangeRate: number;
    paymentSubTotal: number;
    paymentTaxAmount: number;
    paymentTotalAmount: number;
  }[];
};

export const initValue = (item: any, { currency }: any): IMoney => {
  const init: IMoney = {
    ...item,
    client: item?.client,
    address: item?.address,
    companyBank: item?.companyBank,
    paymentMode: item?.paymentMode,
    currency: item?.currency ?? currency,
    description: item?.description,
    chargeCodes: item?.chargeCodes,
    accountingNote: item?.accountingNote,
    bankingPaymentStatus:
      item?.bankingPaymentStatus ?? SpendReceiveStatus.DRAFT,
    subTotal: item?.subTotal ?? 0,
    taxAmount: item?.taxAmount ?? 0,
    totalAmount: item?.totalAmount ?? 0,
    syncBy: item?.syncBy,
    syncAt: item?.syncAt,
    createdBy: item?.createdBy,
    createdAt: item?.createdAt,
    updatedBy: item?.updatedBy,
    updatedAt: item?.updatedAt,
    details: item?.details ?? [],
    resources:
      item?.resources ?? [].map((o?: any) => ({ ...o, name: o?.fileName })),
    defaultResources:
      item?.resources ??
      [].map((o?: any) => ({
        ...o,
        name: o?.fileName
      }))
  };

  return init;
};

export const useStatusSpendReceive = (status: SpendReceiveStatus) => {
  const srStatus = mappedSpendReceiveMoneyStatus()[status];

  return {
    _status: status,
    _chipStatus: (
      <KChip
        textTransform="uppercase"
        typo="TextMdMedium"
        padding="0.5rem"
        marginH="1.5rem"
        height={30}
        {...srStatus}
      />
    )
  };
};

export const initSchema = (isFormWip: boolean) => {
  const defaultSchema = {
    companyBank: ValidationUtils.requiredAny(),
    currency: ValidationUtils.requiredAny(),
    details: ValidationUtils.requiredAny().test(
      'details',
      'message.chart_of_account',
      function (v: any) {
        if (v.findIndex((val: any) => !val.chartOfAccountId) !== -1) {
          toast.error(trans('message.chart_of_account'));
          return false;
        } else {
          return true;
        }
      }
    )
  };
  const formWip = yup.object().shape({
    client: ValidationUtils.requiredAny(),
    ...defaultSchema
  });

  const withoutJob = yup.object().shape({
    ...defaultSchema
  });

  return isFormWip ? formWip : withoutJob;
};

export const commonSpendReceiveMoney = (status: SpendReceiveStatus) => {
  const valueStatus = {
    fullAction: 'fullAction',
    cancel: 'cancel'
  };

  const statusUpdate = {
    [SpendReceiveStatus.DRAFT]: valueStatus.fullAction,
    [SpendReceiveStatus.ACTIVE]: valueStatus.cancel
  };

  return {
    isDraft: statusUpdate[status] === valueStatus.fullAction,
    isActive: statusUpdate[status] === valueStatus.cancel
  };
};

export enum PaymentSource {
  WITHOUT_JOB = 'without-job',
  FROM_WIP = 'from-wip'
}

export enum MoneySource {
  SPEND = 'spend',
  RECEIVE = 'receive'
}

export enum AP_CLIENT_TO {
  table = 'table',
  input = 'input'
}

export const isFromWip = (paymentSource: PaymentSource) => {
  return paymentSource === PaymentSource.FROM_WIP;
};

export const onShowBankingPaymentTemplate = (params: {
  onAddLastRow: ({ params, remove }: { params: any[]; remove: any[] }) => void;
  category: MoneySource;
  params: {
    consolId?: string;
    serviceId?: string;
    shipmentId?: string;
    clientId: string;
    currencyId: number;
  };
  excludeIds: string[];
}) => {
  UIUtils.popup.open({
    title: trans('list_banking_payment'),
    maxWidth: false,
    content: () => {
      return <FormBankingPaymentWip {...params} />;
    }
  });
};
