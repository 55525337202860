import {
  IModifyServicePackingDetails,
  IModifyServicePenalty
} from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import {
  ClientType,
  ENDPOINTS,
  InvoiceCategory,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager, { END_POINTS } from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchServiceContainerDate = (
  serviceContainerId?: number | string | null,
  isLinkToShipment?: boolean,
  isDataService?: boolean
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceContainerDate, serviceContainerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.serviceContainer(':serviceContainerId/date', {
          serviceContainerId
        })
      });
      return res.data;
    },
    enabled: !!serviceContainerId && !isLinkToShipment && isDataService
  });
};

export const useFetchServiceContainerDetails = (
  serviceContainerId?: number | string | null,
  isLinkToShipment?: boolean,
  isDataService?: boolean
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceContainer, serviceContainerId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.serviceContainer(
          'unlink-to-shipment/:serviceContainerId',
          {
            serviceContainerId
          }
        ),
        body: {
          includeFields: [
            'polCarrierPolicy',
            'podCarrierPolicy',
            'shipment',
            'client'
          ]
        }
      });
      return res.data;
    },
    enabled: !!serviceContainerId && !isLinkToShipment && isDataService
  });
};

export const useUpdateServiceContainerDate = (
  serviceContainerId?: number | string
) => {
  const queryClient = useQueryClient();
  const mutation = useMutationEnhancer<any, any>({
    mutationFn: async body => {
      const res = await APIManager.request({
        // url: generatePath(END_POINTS.SERVICE.SERVICE_CONTAINER_DATE, {
        //   serviceContainerId: serviceContainerId ?? ''
        // }),
        url: ENDPOINTS.serviceContainer(':serviceContainerId/date', {
          serviceContainerId
        }),
        method: 'PUT',
        showToast: true,
        body
      });
      return res?.data;
    },
    onSuccess: data => {
      if (data) {
        queryClient.setQueriesData(
          { queryKey: [QUERY_KEYS.serviceContainerDate], type: 'active' },
          data
        );
      }
    },
    mutationKeys: [[QUERY_KEYS.serviceContainer]]
  });

  return {
    updateMutation: mutation.mutate,
    isLoading: mutation.isLoading
  };
};

export const useCUDServiceUnLinkToShipment = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: END_POINTS.SERVICE.UNLINK_TO_SHIPMENT,
    dependentWebTable: [WebTable.SERVICE_CONTAINER_PACKAGE],
    webTable: WebTable.SERVICE_CONTAINER,
    queryKey: QUERY_KEYS.serviceContainer
  });
};

export const useCUDServicePackingDetails = (
  serviceContainerId?: number | string
) => {
  return useCUDMutationEnhancer<IModifyServicePackingDetails>({
    mutationKeys: [[QUERY_KEYS.serviceContainer]],
    endPoint: ENDPOINTS.serviceContainer(':serviceContainerId/package', {
      serviceContainerId
    }),
    webTable: WebTable.SERVICE_CONTAINER_PACKAGE
  });
};

export const useCUDServicePenalTyDetails = (
  serviceContainerId?: number | string
) => {
  return useCUDMutationEnhancer<IModifyServicePenalty>({
    mutationKeys: [[QUERY_KEYS.serviceContainer]],
    endPoint: ENDPOINTS.serviceContainer(
      ':serviceContainerId/container-penalty',
      {
        serviceContainerId
      }
    ),
    webTable: WebTable.SERVICE_CONTAINER_PENALTY
  });
};

export const useFetchApOrArInvoice = ({
  id,
  clientType
}: {
  id?: string | number;
  clientType: ClientType;
}) => {
  const paramsArOrAp: {
    [key: string]: {
      queryKey: string;
      endPoint: string;
    };
  } = {
    [ClientType.AccountReceivable]: {
      queryKey: QUERY_KEYS.arInvoice,
      endPoint: ENDPOINTS.arInvoice(InvoiceCategory.Service, ':id', { id })
    },
    [ClientType.AccountPayable]: {
      queryKey: QUERY_KEYS.apInvoice,
      endPoint: ENDPOINTS.apInvoice(InvoiceCategory.Service, ':id', { id })
    }
  };
  return useQueryEnhancer<any>({
    queryKey: [
      paramsArOrAp[clientType].queryKey,
      id ? parseInt(id as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: paramsArOrAp[clientType].endPoint,
        body: {
          includeFields: [
            // ar
            'arInvoiceStatus',
            'accountReceivable',
            'arBank',
            // ap
            'apInvoiceStatus',
            'accountPayable',
            'accountPayableBank',
            'payableAdvice',

            'paymentMode',
            'companyBank',
            'paymentCurrency'
          ]
        }
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchDefaultCalculateDueDate = ({
  id,
  clientType
}: {
  id: string | number;
  clientType: ClientType;
}) => {
  const paramsArOrAp: {
    [key: string]: {
      queryKey: string;
      endPoint: string;
    };
  } = {
    [ClientType.AccountReceivable]: {
      queryKey: QUERY_KEYS.calculateDueDateAr,
      endPoint: END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AR
    },
    [ClientType.AccountPayable]: {
      queryKey: QUERY_KEYS.calculateDueDateAp,
      endPoint: END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AP
    }
  };
  return useQueryEnhancer<any>({
    queryKey: [paramsArOrAp[clientType].queryKey, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(paramsArOrAp[clientType].endPoint, {
          billingCategory: InvoiceCategory.Service
        }),
        body: {
          arInvoiceId: id,
          apInvoiceId: id
        }
      });

      return res?.data;
    },
    enabled: !!id
  });
};

export const useFetchCustomDeclarationSheet = ({
  serviceId
}: {
  serviceId: string | number;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.customsServiceDeclarationSheet, serviceId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.serviceCustomDeclarationSheet('', {
          serviceId
        })
      });
      return res.data ?? null;
    },
    enabled: !!serviceId
  });
};
