import React, { useCallback, memo, useMemo } from 'react';
import { KChip, KColors, KContainer, KLabel } from 'uikit';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable } from 'hooks';
import trans from 'translation';
import { TableUtils, UIUtils } from 'utils';

import {
  onShowQuotationTemplate,
  useDeleteQuotationTemplateHelper
} from '../../helpers';

const QuotationTemplate = ({ source }: { source: 'quotation' | 'invoice' }) => {
  const { onDelete, deleteLoading } = useDeleteQuotationTemplateHelper(source);

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => {
            const mActions = [
              {
                title: trans('edit'),
                icon: 'EditOutlined',
                onPress: () => onShowQuotationTemplate(source, item)
              }
            ];

            if (!item?.isSystem) {
              mActions.push({
                title: trans('delete'),
                icon: 'Delete',
                onPress: () => onDelete?.([item])
              });
            }

            return mActions as any;
          })
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowQuotationTemplate(source, item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('name'),
          name: 'name'
        },
        {
          label: trans('description'),
          name: 'description'
        },
        {
          label: trans('remark'),
          name: 'remark'
        },
        {
          label: trans('air'),
          name: 'isAir',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('ocean'),
          name: 'isOcean',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('charter'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('linked'),
          name: 'isLinked',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('dangerous'),
          name: 'isDangerous',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('refrigerated'),
          name: 'isRefrigerated',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('type'),
          name: 'type',
          options: {
            sort: false,
            customBodyRenderLite: (index: number) => {
              const item = data?.[index];
              if (item) {
                const { isImport, isExport, isDomestic } = item;
                return (
                  <KContainer.View row dp="flex" alignItems gap="0.5rem">
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={isImport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('import')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isExport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('export')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isDomestic}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('domestic')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },
        {
          label: trans('charge_code_list'),
          name: 'chargeCodes',
          options: {
            sort: false,
            ...TableUtils.options.withMoreIcon({
              onPress: v => {
                UIUtils.popup.open({
                  title: trans('sub_charge_code'),
                  maxWidth: 'xs',
                  touchOutsideToDismiss: true,
                  content: () => (
                    <>
                      {v.map((i, idx) => {
                        const marginT = idx === 0 ? 0 : '0.75rem';
                        return (
                          <KContainer.View
                            key={`charge-code-popup-${i.id}`}
                            dp="flex"
                            center
                            paddingV="0.25rem"
                            paddingH="0.75rem"
                            br="x"
                            brW={1}
                            brC={KColors.palette.primary.w600}
                            marginT={marginT}
                          >
                            <KLabel.Text
                              typo="TextMdNormal"
                              textTransform="uppercase"
                            >
                              {i?.code}
                            </KLabel.Text>
                          </KContainer.View>
                        );
                      })}
                    </>
                  )
                });
              },
              renderItem: (i, idx) => {
                const marginL = idx === 0 ? 0 : '0.5rem';
                return (
                  <KChip
                    key={`charge-code-${i.id}`}
                    background={KColors.palette.primary.w100}
                    brC={KColors.palette.primary.w600}
                    label={i?.code}
                    textTransform={'uppercase'}
                    color={KColors.palette.primary.w700}
                    marginL={marginL}
                    style={{ whiteSpace: 'nowrap' }}
                  />
                );
              }
            })
          }
        },
        {
          label: trans('is_system'),
          name: 'isSystem',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        }
      ];
    },
    [onDelete, source]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: ['chargeCodes', 'currency']
    };
  }, []);

  const table = useDataTable({
    name: WebTable.QUOTATION_TEMPLATE,
    tableName: TableName.QUOTATION_TEMPLATE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.quotationTemplate(source),
    otherParams,
    columnsFunc
  });

  useGlobalTable(table);

  return (
    <KContainer.CardWithAccordion
      header={{
        icon: 'ErrorOutlineOutlined',
        title: trans(`${source}_template`)
      }}
      marginT={source === 'quotation' ? 0 : undefined}
    >
      <DataTable
        {...table}
        onAdd={() => onShowQuotationTemplate(source)}
        onDelete={onDelete}
        isModifying={deleteLoading}
      />
    </KContainer.CardWithAccordion>
  );
};

export default memo(QuotationTemplate);
