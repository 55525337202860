"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDidUpdate = (effect, deps) => {
    const mounted = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
exports.default = useDidUpdate;
