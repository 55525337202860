"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const cancelable_promise_1 = require("cancelable-promise");
const react_1 = require("react");
const useCancelablePromise = () => {
    const promise = (0, react_1.useRef)(undefined);
    const handleNewPromise = (0, react_1.useCallback)((newPromise) => {
        if (promise.current) {
            promise.current.cancel();
        }
        promise.current = new cancelable_promise_1.CancelablePromise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield newPromise;
            resolve(data);
        }));
    }, []);
    return { promise, handleNewPromise };
};
exports.default = useCancelablePromise;
