import {
  IModifyInvoicePaymentModeParams,
  IItemParams,
  IModifyQuotationTemplate,
  IModifyInvoiceTemplate
} from '@request-dto';

import { ENDPOINTS, InvoiceTemplateEnum, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDARInvoiceStatus = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.arInvoiceStatus(),
    webTable: WebTable.AR_INVOICE_STATUS
  });
};

export const useCUDAPInvoiceStatus = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.apInvoiceStatus(),
    webTable: WebTable.AP_INVOICE_STATUS
  });
};

export const useCUDInvoicePaymentMode = () => {
  return useCUDMutationEnhancer<IModifyInvoicePaymentModeParams>({
    endPoint: ENDPOINTS.invoicePaymentMode(),
    webTable: WebTable.INVOICE_PAYMENT_MODE
  });
};

export const useCUDInvoiceTemplate = (source: InvoiceTemplateEnum) => {
  return useCUDMutationEnhancer<IModifyInvoiceTemplate>({
    endPoint: ENDPOINTS.invoiceTemplate(),
    webTable:
      source === InvoiceTemplateEnum.InvoiceAr
        ? WebTable.INVOICE_AR_TEMPLATE
        : WebTable.INVOICE_AP_TEMPLATE
  });
};
