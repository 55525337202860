import { useQueryClient } from '@tanstack/react-query';
import React, { createRef, memo, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AirOceanType,
  ENDPOINTS,
  QUERY_KEYS,
  TableName,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import {
  useDataTable,
  useGlobalTable,
  useNewButton,
  usePopperListItem
} from 'hooks';
import { useImpExpTab } from 'pages/OceanFreight/helpers';
import trans from 'translation';
import {
  KBreadcrumbs,
  KColors,
  KContainer,
  KLabel,
  KListItemBaseItemProps
} from 'uikit';
import { TableUtils, UIUtils, mappedConsolStatusData } from 'utils';
import { showNoteContent } from 'utils/common';

import { useConsolActions } from './helpers';

const Consolidation = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const ref = createRef<HTMLButtonElement>();

  const { onAlert, isLoading } = useConsolActions();

  const { Tab, tabData } = useImpExpTab();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () =>
                navigate(`${item.id}`, {
                  state: {
                    code: item.consolNumber
                  }
                })
            },
            {
              title: trans('duplicate'),
              icon: 'ContentCopyOutlined',
              onPress: () => onAlert({ key: 'DUPLICATE', data: [item] })
            },
            {
              title: trans('copy_to_another_branch'),
              icon: 'InputOutlined',
              onPress: () => onAlert({ key: 'COPY', data: [item] })
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onAlert({ key: 'DELETE', data: [item] })
            }
          ])
        },
        {
          label: trans('number'),
          name: 'consolNumber',
          options: {
            ...TableUtils.options.baseXLg,
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    navigate(`${item.id}`, {
                      state: {
                        code: v
                      }
                    });
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('job_order_no'),
          name: 'jobOrderNumber',
          options: {
            ...TableUtils.options.base
          }
        },
        {
          label: trans('status'),
          name: 'consolStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 152,
            css: { textAlign: 'center' },
            mapLabelData: mappedConsolStatusData()
          })
        },
        {
          label: trans('booking_cancel'),
          name: 'isBookingCancel',
          options: TableUtils.options.iconCheckCircle({ revert: true })
        },
        {
          label: trans('type'),
          name: 'consolType',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('agent_type'),
          name: 'agentType',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('service'),
          name: 'serviceLevelName'
        },
        {
          label: trans('container_mode'),
          name: 'contMode'
        },
        {
          label: trans('unloco_1st_loading'),
          name: 'firstLoadingCode'
        },
        {
          label: trans('unloco_last_discharge'),
          name: 'lastDischargeCode'
        },
        {
          label: trans('flight'),
          name: 'flight',
          options: TableUtils.options.baseMd
        },
        {
          label: trans('is_linked'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle({ revertKey: true })
        },
        {
          label: trans('is_charter'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('express'),
          name: 'isCargoOnly',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('sending_agent'),
          name: 'sendingAgentName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('receiving_agent'),
          name: 'receivingAgentName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('airline'),
          name: 'airline.name',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('co_loader'),
          name: 'coLoaderName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('mawb_no'),
          name: 'oblNo'
        },
        {
          label: trans('last_update'),
          name: 'updatedAt'
        },
        {
          label: trans('container'),
          name: 'containerNumberList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: item => {
              UIUtils.popup.open({
                title: trans('container'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {item?.map((v, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`container-number-list-popup-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.primary.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">{v}</KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (v: any, idx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KContainer.View
                  key={`container-number-list-${idx}`}
                  minW={150}
                  height={20}
                  marginL={marginL}
                  dp="flex"
                  center
                  background="#ACDCD7"
                  br="2x"
                  brW={1}
                  brC={KColors.primary.normal}
                >
                  <KLabel.Text
                    typo="TextSmMedium"
                    color={KColors.primary.normal}
                  >
                    {v}
                  </KLabel.Text>
                </KContainer.View>
              );
            }
          })
        },
        {
          label: trans('total_packs'),
          name: 'totalPacks',
          options: TableUtils.options.number()
        },
        {
          label: trans('with_unit.total_gross_weight', {
            unit: 'Kg'
          }),
          name: 'totalGrossWeight',
          options: TableUtils.options.number()
        },
        {
          label: trans('with_unit.total_chargeable_weight', {
            unit: 'Kg'
          }),
          name: 'totalChargeableWeightVolume',
          options: TableUtils.options.number()
        },
        {
          label: trans('onboard_date'),
          name: 'onboardDate',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('etd'),
          name: 'firstEtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('atd'),
          name: 'firstAtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('eta'),
          name: 'lastEta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('ata'),
          name: 'lastAta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('general_note'),
          name: 'noteContent',
          options: TableUtils.options.contentMore((item: any) => {
            showNoteContent(item);
          }, data)
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        }
      ];
    },
    [navigate, onAlert]
  );

  const otherParams = useMemo(() => {
    return {
      freightMode: AirOceanType.Air,
      includeFields: [
        'firstLoadingTimezone',
        'lastDischargeTimezone',
        'airline'
      ],
      consolType: tabData
    };
  }, [tabData]);

  const mappedFields = useMemo(() => {
    return {
      'airline.name': 'airline',
      sendingAgentName: 'sendingAgent',
      receivingAgentName: 'receivingAgent',
      coLoaderName: 'coLoader',
      serviceLevelName: 'serviceLevel',
      firstLoadingCode: 'firstLoading',
      lastDischargeCode: 'lastDischarge'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.CONSOL,
    tableName: TableName.CONSOL,
    columnsFunc,
    showTimeRange: true,
    apiURL: ENDPOINTS.consol(),
    mappedFields,
    otherParams,
    otherOptions: {
      selectableRows: 'none'
    }
  });

  useGlobalTable(table);

  const toolsData = useMemo(() => {
    const _data: KListItemBaseItemProps[] = [
      {
        title: trans('import_excel'),
        icon: {
          name: 'CloudUploadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('export_excel'),
        icon: {
          name: 'CloudDownloadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('print'),
        icon: {
          name: 'Print',
          color: KColors.primary.normal
        },
        onPress: () => {}
      }
    ];
    return _data;
  }, []);

  const onAdd = useCallback(() => {
    queryClient.removeQueries([QUERY_KEYS.consol]);
    navigate('new');
  }, [navigate, queryClient]);

  const { onPress } = usePopperListItem({ btnRef: ref, data: toolsData });

  const newButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.air_freight'),
          trans('routes.consolidation')
        ]}
        rightNode={{
          tools: {
            ref,
            onPress
          }
        }}
      />

      {Tab()}

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={onAdd}
          onCopy={data => onAlert({ key: 'DUPLICATE', data })}
          onDelete={data => onAlert({ key: 'DELETE', data })}
          showStatus={true}
          isModifying={isLoading}
        />
      </KContainer.Card>

      {newButton}

      <Outlet />
    </>
  );
};

export default memo(Consolidation);
