/* eslint-disable max-lines */
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

import { GroupClient, GroupLibrary, GroupOther } from '@constants';
import Client from 'pages/Client';
import Dashboard from 'pages/Dashboard';
// eslint-disable-next-line import/order
import ExchangeRate from 'pages/ExchangeRate';

import Staff from 'pages/Staff';
import LibraryCategory from 'pages/SystemSettings/Library';
import SSSetting from 'pages/SystemSettings/Setting';

import accountingRoutes from './accounting';
import airFreightRoutes from './airFreight';
import { IAdminRoute } from './helpers';
import oceanFreightRoutes from './oceanFreight';
import sales from './sales';
import serviceRoutes from './service';

const DemoComponents = loadable(() =>
  pMinDelay(import('pages/DemoComponents'), 200)
);

const Role = loadable(() => pMinDelay(import('pages/Role'), 200));
const RoleDetail = loadable(() => pMinDelay(import('pages/Role/Details'), 200));

const StaffDetails = loadable(() =>
  pMinDelay(import('pages/Staff/Details'), 200)
);

const ClientDetails = loadable(() =>
  pMinDelay(import('pages/Client/DetailsLayout'), 200)
);

// Help & Support
const HelpSupportCase = loadable(() =>
  pMinDelay(import('pages/HelpSupport/Case'), 200)
);

const HelpSupportDetails = loadable(() =>
  pMinDelay(import('pages/HelpSupport/Case/DetailsLayout'), 200)
);

const HelpSupportFAQ = loadable(() =>
  pMinDelay(import('pages/HelpSupport/FAQ'), 200)
);

const adminRoutes: IAdminRoute[] = [
  {
    name: 'Dashboard',
    path: '',
    icon: 'dashboard',
    // code: [GroupDashboard.DASHBOARD_OPERATION],
    element: <Dashboard />
  },
  {
    noChildren: true,
    code: [GroupClient.CLIENT],
    name: 'Client',
    path: 'client',
    icon: 'group',
    children: [
      {
        index: true,
        element: <Client />,
        code: [GroupClient.CLIENT]
      },
      {
        path: 'new',
        element: <ClientDetails key="new" />,
        code: [GroupClient.CLIENT_DETAIL]
      },
      {
        path: ':id',
        element: <ClientDetails key=":id" />,
        code: [GroupClient.CLIENT_DETAIL]
      }
    ]
  },
  oceanFreightRoutes,
  airFreightRoutes,
  serviceRoutes,
  {
    name: 'Library',
    path: 'library',
    code: [GroupLibrary.LIBRARY],
    icon: 'settings_suggest',
    children: [
      // {
      //   parentKey: 'library',
      //   name: 'Vessel Schedule',
      //   path: 'vessel-schedule',
      //   icon: 'reorder',
      //   element: <Dashboard />
      // },
      // {
      //   parentKey: 'library',
      //   name: 'Flight Schedule',
      //   path: 'flight-schedule',
      //   icon: 'reorder',
      //   element: <Dashboard />
      // },
      // {
      //   parentKey: 'library',
      //   name: 'HS Code',
      //   path: 'hs-code',
      //   icon: 'reorder',
      //   element: <Dashboard />
      // },
      {
        parentKey: 'library',
        name: 'Exchange Rate',
        code: [GroupLibrary.LIBRARY_EXCHANGE_RATE],
        path: 'exchange-rate',
        icon: 'currency_exchange',
        element: <ExchangeRate />
      },
      {
        parentKey: 'library',
        name: 'Category',
        code: [GroupLibrary.LIBRARY_SETTING],
        path: 'category',
        icon: 'widgets',
        element: <LibraryCategory />
      }
    ]
  },
  accountingRoutes,
  sales,
  {
    name: 'System Settings',
    path: 'system-settings',
    code: [GroupOther.SYSTEM_SETTING],
    icon: 'settings',
    children: [
      // {
      //   parentKey: 'system-settings',
      //   name: 'API Integration',
      //   path: 'api-integration',
      //   icon: 'reorder',
      //   element: <Dashboard />
      // },
      {
        parentKey: 'system-settings',
        name: 'Setting',
        path: 'setting',
        icon: 'tune',
        element: <SSSetting />
      }
      // {
      //   parentKey: 'system-settings',
      //   name: 'System Log',
      //   path: 'system-log',
      //   icon: 'reorder',
      //   element: <Dashboard />
      // }
    ]
  },
  {
    name: 'Administration',
    path: 'administration',
    code: [GroupOther.ADMIN],
    icon: 'vpn_key',
    children: [
      {
        parentKey: 'administration',
        name: 'Staff',
        path: 'staff',
        icon: 'groups',
        children: [
          {
            index: true,
            element: <Staff />
          },
          {
            path: 'new',
            element: <StaffDetails key="new" />
          },
          {
            path: ':id',
            element: <StaffDetails key=":id" />
          }
        ]
      },
      {
        parentKey: 'administration',
        name: 'User',
        path: 'user',
        icon: 'account_circle_outlined',
        element: <Dashboard />
      },
      {
        parentKey: 'administration',
        name: 'Role & Permission',
        path: 'role-permission',
        icon: 'security_outlined',
        children: [
          {
            index: true,
            element: <Role />
          },
          {
            path: 'new',
            element: <RoleDetail key="new" />
          },
          {
            path: ':id',
            element: <RoleDetail key=":id" />
          }
        ]
      }
    ]
  },
  {
    name: 'help_and_support',
    path: 'help-support',
    icon: 'vpn_key',
    children: [
      {
        parentKey: 'help-support',
        name: 'Case',
        path: 'case',
        icon: 'groups',
        children: [
          {
            index: true,
            element: <HelpSupportCase />
          },
          {
            path: ':id',
            element: <HelpSupportDetails key=":id" />
          }
        ]
      },
      {
        parentKey: 'help-support',
        name: 'FAQ',
        path: 'faq',
        icon: 'account_circle_outlined',
        children: [
          {
            index: true,
            element: <HelpSupportFAQ />
          }
        ]
      }
    ]
  }
];

if (process.env.NODE_ENV === 'development') {
  adminRoutes.unshift({
    name: 'Demo Components',
    path: 'components',
    icon: 'apps',
    element: <DemoComponents />
  });
}

export default adminRoutes;
