/* eslint-disable max-lines */
import React, { memo, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { KColors, KContainer, KInput, KLabel, KListItem } from 'uikit';

import { DATE_FORMAT_SHORT, WebTable, tableRef } from '@constants';
import DataTable from 'containers/DataTable';
import { Prototype } from 'core';
import {
  useFetchDataAddSelectedTransaction,
  useGlobalTable,
  useLocalTable,
  useNewButton
} from 'hooks';
import {
  KeyInvoiceEnum,
  typeInvoice,
  clientHelpers,
  mapListData
} from 'pages/Accounting/Banking/helpers';
import trans from 'translation';
import { TableUtils, UIUtils } from 'utils';

interface IProps {
  setListSelected: React.Dispatch<React.SetStateAction<any[]>>;
  transactionId?: number | string;
  isDeposit?: boolean;
}

const AddSelectedList = ({
  setListSelected,
  transactionId,
  isDeposit
}: IProps) => {
  const [indexes, setIndexes] = useState<number[]>([]);
  const [objId, setObjId] = useState({}); // danh sách Id đã được checked
  const [objPayment, setObjPayment] = useState<{}>({});

  const [keyInvoiceType, setKeyInvoiceType] = useState<KeyInvoiceEnum>(
    KeyInvoiceEnum.arInvoice
  );

  const excludeIds = useMemo(() => {
    const tableRefData =
      tableRef[WebTable.BANKING_SELECTED_TRANSACTION]?.data?.data || [];
    const result = tableRefData
      .filter(x => x?.type === keyInvoiceType)
      .map(o => o?.id);

    return result;
  }, [keyInvoiceType]);

  const { clientId } = clientHelpers(
    tableRef[WebTable.BANKING_SELECTED_TRANSACTION]?.data?.data?.[0]
  );

  const { data: listAddSelected = [], isLoading } =
    useFetchDataAddSelectedTransaction(
      keyInvoiceType,
      transactionId,
      clientId,
      excludeIds
    );

  const { isAccountReceivable } = clientHelpers(listAddSelected[0]);

  const isNotAdvice = useMemo(() => {
    return (
      keyInvoiceType !== KeyInvoiceEnum.paymentAdvice &&
      keyInvoiceType !== KeyInvoiceEnum.receivableAdvice
    );
  }, [keyInvoiceType]);

  const isCheckPayment = useCallback(
    (valueInput: number, valueBalance: number) => {
      if (valueBalance > 0) {
        if (valueInput > 0 && valueInput <= valueBalance) {
          return true;
        }
      }

      if (valueBalance < 0) {
        if (valueInput < 0 && valueInput >= valueBalance) {
          return true;
        }
      }

      return false;
    },
    []
  );

  // const mapListData = useMemo(() => {
  //   const res = listAddSelected.reduce((pre, cur) => {
  //     pre[cur?.id] = cur;
  //     return pre;
  //   }, {});
  //   return res;
  // }, [listAddSelected]);

  const columns = useMemo(() => {
    return [
      {
        label: trans('number'),
        name: 'code'
      },
      {
        label: trans('created_date'),
        name: 'createdAt'
      },
      {
        label: trans('due_date'),
        name: 'dueDate',
        options: TableUtils.options.date(DATE_FORMAT_SHORT)
      },
      {
        label: trans('client_code'),
        name: isAccountReceivable
          ? 'accountReceivable.code'
          : 'accountPayable.code'
      },

      {
        label: trans('client_name'),
        name: isAccountReceivable
          ? 'accountReceivable.name'
          : 'accountPayable.name'
      },

      {
        label: trans('amount'),
        name: 'totalAmount',
        options: TableUtils.options.number()
      },
      {
        label: trans('paid'),
        name: 'collect',
        options: TableUtils.options.number()
      },
      {
        label: trans('balance'),
        name: 'balance',
        options: TableUtils.options.number()
      },
      {
        label: trans('currency'),
        name: 'paymentCurrency.code'
      },
      {
        label: trans('payment'),
        // dùng id của row để lấy được row cần xử lý
        name: 'id',
        options: {
          ...TableUtils.options.baseLg,
          customBodyRender: (valueId: any) => {
            const keyPayment = isNotAdvice ? 'balance' : 'totalAmount';
            // lấy default data để hiển thị
            const mapData = mapListData(listAddSelected);
            const valuePayment = mapData[valueId]?.[keyPayment];

            return objId[valueId] ? (
              <KInput.TextField
                key={`${valueId}`}
                value={objPayment[valueId] || valuePayment}
                onChange={e => {
                  const valueInput = e.target.value as any;

                  isCheckPayment(valueInput, Number(valuePayment)) &&
                    setObjPayment(pre => {
                      return {
                        ...pre,
                        [valueId]: valueInput // lấy valueId để làm key hashmap data
                      };
                    });
                }}
              />
            ) : (
              <KLabel.Text>
                {Prototype.number.formatNumber(valuePayment)}
              </KLabel.Text>
            );
          }
        }
      }
    ];
  }, [
    isAccountReceivable,
    isCheckPayment,
    isNotAdvice,
    listAddSelected,
    objId,
    objPayment
  ]);

  const table = useLocalTable({
    name: WebTable.BANKING_SELECTED_ADD,
    columns,
    data: listAddSelected
  });

  useGlobalTable(table);

  const handleAddTransaction = useCallback(() => {
    const objChecked = indexes.reduce((pre, cur) => {
      pre[cur] = true;
      return pre;
    }, {});

    const newData = listAddSelected
      .map(item => {
        return {
          ...item,
          clientAccount: item?.accountPayable || item?.accountReceivable,
          payment:
            objPayment[item?.id] ||
            (isNotAdvice ? item?.balance : item?.totalAmount),
          type: keyInvoiceType
        };
      })
      .filter((_, index) => objChecked[index]);

    setListSelected(pre => {
      return [...pre, ...newData];
    });
    UIUtils.popup.dismiss();
  }, [
    indexes,
    isNotAdvice,
    keyInvoiceType,
    listAddSelected,
    objPayment,
    setListSelected
  ]);

  const newButton = useNewButton({
    title: trans('add'),
    marginR: '0.75rem',
    marginB: '0.5rem',
    textColor: KColors.primary.normal,
    disabled: indexes.length === 0,
    onPress: handleAddTransaction
  });

  return (
    <>
      <KContainer.Card>
        <KListItem.RadioGroup
          direction="row"
          containerStyle={{ marginTop: '0.75rem' }}
          data={typeInvoice
            .filter(o => {
              return isDeposit ? o.deposit : o.noDeposit;
            })
            .map(i => ({
              id: i.key,
              label: trans(i.label),
              disabled: !!indexes?.length,
              checked: i.key === keyInvoiceType,
              onChange: () => {
                setKeyInvoiceType(i?.key);
              }
            }))}
        />

        <DataTable
          {...table}
          isLoading={isLoading}
          showRightActions={false}
          options={{
            ...table.options,
            rowsSelected: indexes,

            onRowSelectionChange: (_cur, _row) => {
              const listIndex = _row.map(o => o?.dataIndex) || [];

              const objListIndex = listIndex.reduce((pre, cur) => {
                pre[cur] = true;
                return pre;
              }, {});

              const firstIndex = listIndex[0];
              const lastIndex = listIndex[listIndex.length - 1];
              const isNotDupClient =
                listAddSelected[firstIndex]?.clientId !==
                listAddSelected[lastIndex]?.clientId;

              if (isNotDupClient) {
                setIndexes([...indexes]);
                toast.error(trans('message.no_duplicate_client'));
              } else {
                const keyId = listAddSelected[_cur[0]?.dataIndex]?.id;
                const newObjId = listAddSelected
                  .map(o => o?.id)
                  .filter((x, index) => objListIndex[index])
                  .reduce((pre, cur) => {
                    pre[cur] = true;
                    return pre;
                  }, {});

                setObjId(newObjId);
                setIndexes(listIndex);
                setObjPayment(pre => {
                  // clear data and setDefault data when change checkbox
                  return { ...pre, [keyId]: '' };
                });
              }
            }
          }}
        />
        {newButton}
      </KContainer.Card>
    </>
  );
};

export default memo(AddSelectedList);
