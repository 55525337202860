import { cloneDeep, get } from 'lodash';
import { useCallback, useMemo } from 'react';

import { IAdminRoute } from 'routes/helpers';
import trans from 'translation';
import { TableUtils } from 'utils';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

export const getCachedTableConfigs = () => {
  const cacheTableConfigs = localStorage.getItem('tableConfigs');
  return cacheTableConfigs ? JSON.parse(cacheTableConfigs) : undefined;
};

export const cacheColumnOrder = (name: string, columnOrder: number[]) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      columnOrder
    }
  };
  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const cacheViewColumns = (
  name: string,
  columnName: string,
  action: string
) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      viewColumns: {
        ...(tableConfigs?.[name]?.viewColumns || {}),
        [columnName]: action === 'remove' ? true : undefined
      }
    }
  };

  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const getColumnOrderFromCache = (
  name: string,
  length: number
): number[] | undefined => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  const { columnOrder } = tableConfigs[name];
  if (columnOrder?.length === length) {
    return columnOrder;
  } else {
    // reset cache if add or delete in columns
    let newColumnOrder = [];
    for (let i = 0; i < length; i++) {
      newColumnOrder.push(i);
    }
    cacheColumnOrder(name, newColumnOrder);
    return newColumnOrder;
  }
};

export const getViewColumnsFromCache = (name: string) => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  return tableConfigs[name]?.viewColumns;
};

export const reorderColumnsBasedOnCache = (
  name: string,
  columns: any[]
): any[] => {
  // const columnOrder = getColumnOrderFromCache(name, columns.length);
  const viewColumns = getViewColumnsFromCache(name);

  if (viewColumns) {
    columns = columns.map(i => {
      if (viewColumns[i.name]) {
        i.options = { ...(i.options || {}), display: false };
      } else {
        i.options = { ...(i.options || {}), display: true };
      }
      return i;
    });
  }

  return columns;

  // if (columnOrder?.length === columns.length) {
  //   return columnOrder.map(i => columns[i]);
  // } else {
  //   return columns;
  // }
};

export const objPermissions = () => {
  const localUser = StorageEnhance.get(STORAGE_KEYS.user);
  const resPer = useMemo(() => {
    let res = localUser?.staffPermission?.permissionGroup
      ?.map((o: any) => {
        return [...o.permissionSubGroup, { code: o?.code }];
      })
      .flat();

    res = res?.reduce((result: any, item: any) => {
      const key = item?.code;
      result[key] = item?.code;
      return result;
    }, {});
    return res;
  }, [localUser?.staffPermission?.permissionGroup]);

  const isAdmin = localUser?.staffPermission?.isAdmin ?? false;
  const isBranchAdmin = localUser?.staffPermission?.isBranchAdmin ?? false;

  const convertTab = useCallback(
    (tabs: any) => {
      return isAdmin || isBranchAdmin
        ? tabs
        : tabs.filter((o: any) => {
            const isCode = (o?.code ?? []).some((x: any) => resPer?.[x]);
            return o?.code && !!resPer ? isCode : true;
          });
    },
    [isAdmin, isBranchAdmin, resPer]
  );

  return {
    resPer,
    // super admin
    isAdmin: localUser?.staffPermission?.isAdmin,
    // branch admin
    isBranchAdmin: localUser?.staffPermission?.isBranchAdmin,
    convertTab
  };
};

export const normalizeFinalColumns = (
  columns: any[],
  mappedFields?: {
    [key: string]: string;
  }
) => {
  return columns.map(i => ({
    ...i,
    mappedName: get(mappedFields, i.name, i.name),
    options: ['createdAt', 'updatedAt'].includes(i.name)
      ? { ...TableUtils.options.date(), ...i.options }
      : ['createdUsername', 'updatedUsername'].includes(i.name)
      ? { ...TableUtils.options.baseXLg, ...i.options }
      : // : ['remark', 'description'].includes(i.name)
        // ? { ...TableUtils.options.withEllipsis, ...i.options }
        { ...TableUtils.options.base, ...i.options },
    allowedToSort: i.options?.sort ?? true
  }));
};

export const usePermissionAdmin = (keyPer: string) => {
  const { resPer, isAdmin, isBranchAdmin } = objPermissions();
  return !resPer?.[keyPer] && !isAdmin && !isBranchAdmin;
};

// export const useListPermissionAdmin = (listKeyPer: string[]) => {
//   const {  resPer, isAdmin, isBranchAdmin } = objPermissions();
//   const isListKey =  listKeyPer.some((keyPer: string) => {
//     return !resPer?.[keyPer]
//   })
//   return !isListKey && !isAdmin && !isBranchAdmin
// }

export const usePermissionAdminBilling = (isShowBilling: boolean) => {
  const { isAdmin, isBranchAdmin } = objPermissions();
  return !isShowBilling && !isAdmin && !isBranchAdmin;
};

interface groupFreight {
  summaryKey: string;
  chargeCodeKey: string;
  arKey: string;
  apKey: string;
  wipKey: string;
}

export enum TabActBil {
  INVOICE = 'invoice',
  PROFIT_AND_LOSS = 'details_profit_loss'
}

export const useShowPermissionActBill = (groupFreight: groupFreight) => {
  const { summaryKey, chargeCodeKey, arKey, apKey, wipKey } = groupFreight;

  const { resPer, isAdmin, isBranchAdmin } = objPermissions();
  const showPer = useMemo(() => {
    let obj = {
      summary: true,
      chargeCode: true,
      ar: true,
      ap: true,
      all: function () {
        return this.ar || this.ap;
      },
      showTab: function () {
        return this.all() || this.chargeCode;
      }
    };

    if (isAdmin || isBranchAdmin) {
      return obj;
    }

    if (resPer) {
      obj = {
        summary: !!resPer?.[summaryKey],
        chargeCode: !!resPer?.[chargeCodeKey],
        ar: !!resPer?.[arKey],
        ap: !!resPer?.[apKey],
        // wip: !!resPer?.[wipKey],
        all: function () {
          return this.ar || this.ap;
          // || this.wip;
        },
        showTab: function () {
          return this.all() || this.chargeCode;
        }
      };
    }

    return obj;
  }, [apKey, arKey, chargeCodeKey, isAdmin, isBranchAdmin, resPer, summaryKey]);

  const tabs = useMemo(() => {
    let _tab = [
      {
        key: 'invoice',
        label: trans('tab.invoice'),
        isShow: showPer.all()
      },
      {
        key: 'details_profit_loss',
        label: trans('tab.details_profit_loss'),
        isShow: showPer.chargeCode
      }
    ];

    _tab = _tab.filter(i => i?.isShow);

    return _tab;
  }, [showPer]);

  const indexTab = useCallback(
    (key: TabActBil) => {
      const idx = tabs.findIndex(i => i?.key === key);
      return idx;
    },
    [tabs]
  );

  return { showPer, tabs, indexTab };
};

export const useAdminRouter = () => {
  const { resPer, isAdmin, isBranchAdmin } = objPermissions();

  const convertAdminRouter = useCallback(
    (array: IAdminRoute[]) => {
      if (isAdmin || isBranchAdmin) {
        return array;
      }

      const _arr = cloneDeep(array);
      const res = _arr.filter(i => {
        const isCode = (i.code ?? []).some((x: any) => !!resPer?.[x]);

        if (i.code && !isCode) {
          return false; // xóa đi
        }

        if (i.children && Array.isArray(i.children)) {
          i.children = convertAdminRouter(i.children);
        }

        return true;
      });

      return res;
    },
    [isAdmin, isBranchAdmin, resPer]
  );

  return { convertAdminRouter };
};
