import { useNavigate } from 'react-router-dom';

import {
  AirOceanType,
  ConsolType,
  ContainerMode,
  DeliveryMode,
  DeliveryModeAir,
  ENDPOINTS,
  OriginDestinationType,
  QUERY_KEYS
} from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';

import { useMutationEnhancer } from '../core';

interface ICDSParams {
  lodgementDate: string;
  clearanceNo: string;
  assignRoute: string;
  insCustom: string;
  customOffice: string;
}

interface ISubmitEcusDataParams {
  id: string | number;
  data: ICDSParams[];
}

export const useSubmitEcusData = () => {
  return useMutationEnhancer<any, ISubmitEcusDataParams>({
    mutationFn: async mParams => {
      const { id, data } = mParams;
      const res = await APIManager.request({
        url: ENDPOINTS.service(':id/custom-declaration-sheet/bulk-create', {
          id
        }),
        body: data,
        method: 'POST',
        showToast: true
      });

      return res.success;
    },
    mutationKeys: [[QUERY_KEYS.service]],
    onSuccess: () => {
      UIUtils.popup.dismiss();
    }
  });
};

export interface ISubmitEcusDataWithServiceParams {
  transportServiceType: OriginDestinationType;
  isNewClient: boolean;
  isTransport?: boolean;
  isHandling?: boolean;
  type: ConsolType;
  mode: AirOceanType;
  onboardDate?: string;
  refClientBookingNo?: string;
  deliveryMode: DeliveryMode | DeliveryModeAir;
  contMode: ContainerMode;
  oblNo: string;
  oblReleasedDate?: string;
  polCode: string;
  polEtd: string;
  polAtd?: string;
  podCode: string;
  podEta: string;
  podAta?: string;
  customOffice: string;
  bookingClient: any;
  customDeclarationSheets: ICDSParams[];
  vessel?: string;
  flight?: string;
}

export const useSubmitEcusDataWithService = () => {
  const navigate = useNavigate();

  return useMutationEnhancer<any, ISubmitEcusDataWithServiceParams>({
    mutationFn: async mParams => {
      const res = await APIManager.request({
        url: ENDPOINTS.service('import-ecus'),
        body: mParams,
        method: 'POST',
        showToast: true
      });

      return res.data;
    },
    onSuccess: data => {
      UIUtils.popup.dismiss();
      if (!!data?.id) {
        navigate(`/admin/service/${data.id}`, { replace: true });
      }
    }
  });
};
