import { EventSourcePolyfill as _EventSource } from 'event-source-polyfill';
import { isEmpty, set } from 'lodash';

import { EventName } from '@constants';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

interface IEventDataMessage {
  data: any;
  type: EventName;
}

interface IEventMessage {
  data: string;
}

interface IEventSourceState {
  instance: _EventSource | undefined;
  attempts: number;
}

const MAX_ATTEMPTS = 1;

class AppEventSource {
  instances: Record<string, IEventSourceState> = {};

  constructor() {}

  init = (url: string) => {
    const user = StorageEnhance.get(STORAGE_KEYS.user);
    if (!this.instances[url]?.instance && !!user?.token) {
      console.log('[EVENT SOURCE] => init', Date.now(), url);
      const _instance = new _EventSource(
        `${process.env.REACT_APP_BASE_API}${url}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`
          },
          heartbeatTimeout: 60000
        }
      );
      if (!this.instances[url]) {
        this.instances[url] = {
          instance: _instance,
          attempts: 0
        };
      } else {
        set(this.instances[url], 'instance', _instance);
      }

      const ins = this.instances[url].instance;

      ins!.onopen = () => {
        console.log('[EVENT SOURCE][CONNECTED]', Date.now());
        set(this.instances[url], 'attempts', 0);
      };

      ins!.onerror = e => {
        console.log('[EVENT SOURCE][ERROR]', e);
        if (ins!.readyState === _EventSource.CLOSED) {
          console.log('[EVENT SOURCE][DISCONNECTED]');
          this.instances[url].instance = undefined;

          if (this.instances[url].attempts < MAX_ATTEMPTS) {
            setTimeout(() => {
              console.log('[EVENT SOURCE] => reconnect');
              this.instances[url].attempts += 1;
              this.init(url);
            }, 5000);
          }
        }
      };
    }
  };

  instance = (url: string) => {
    return this.instances[url].instance;
  };

  addEventListener = (ev: EventName, url: string, cb?: (d: any) => void) => {
    if (!!this.instances[url]?.instance) {
      // @ts-ignore
      this.instances[url].instance!.addEventListener(ev, (d: IEventMessage) => {
        console.log(`[EVENT SOURCE][${ev}]`, Date.now(), d);
        const newMessage = d.data;
        if (newMessage) {
          const data: IEventMessage = JSON.parse(newMessage);
          if (!isEmpty(data)) {
            cb?.(data.data);
          }
        }
      });
    }
  };

  onMessage = (url: string, cb?: (d: IEventDataMessage) => void) => {
    if (!!this.instances[url]?.instance) {
      console.log('[EVENT SOURCE] => init onMessage', Date.now());
      this.instances[url].instance!.onmessage = (d: IEventMessage) => {
        const newMessage = d.data;
        if (newMessage) {
          const data: IEventDataMessage = JSON.parse(newMessage);
          if (!isEmpty(data)) {
            if (data.type === EventName.PING) {
              console.log('[EVENT SOURCE][PING]', Date.now(), url);
            } else {
              console.log('[EVENT SOURCE][MESSAGE]', Date.now(), data);
              cb?.(data);
            }
          }
        }
      };
    }
  };

  disconnect = (url?: string) => {
    console.log('[EVENT SOURCE] => disconnect', Date.now(), url);
    if (url && this.instances[url]?.instance) {
      this.instances[url].attempts = MAX_ATTEMPTS;
      this.instances[url].instance?.close();
      delete this.instances[url];
    } else {
      this.instances = {};
    }
  };
}

export default new AppEventSource();
