import React, { memo } from 'react';
import { KColors, KLabel } from 'uikit';

import trans from 'translation';

interface IProps {
  infoChargeCode: {
    label: string;
    value: string | number;
    unit?: string;
  }[];
}
const InfoChargeCode = ({ infoChargeCode }: IProps) => {
  return (
    <>
      {infoChargeCode.map((o, index) => {
        const colorText = KColors.primary.normal;
        return (
          <KLabel.Text key={index}>
            <KLabel.Text>{trans(o.label)}</KLabel.Text>

            {o?.unit && (
              <KLabel.Text marginL={'0.25rem'} color={colorText}>
                {`(${o?.unit})`}
              </KLabel.Text>
            )}

            <KLabel.Text color={colorText}>: {o.value}</KLabel.Text>
          </KLabel.Text>
        );
      })}
    </>
  );
};

export default memo(InfoChargeCode);
