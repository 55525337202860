/* eslint-disable max-lines */
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { GroupAirFreight } from '@constants';
import Consol from 'pages/AirFreight/Consol';
import ShipmentNoConsol from 'pages/AirFreight/ShipmentNoConsol';

import { IAdminRoute } from './helpers';

const ConsolLayout = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/DetailsLayout'), 200)
);
const ConsolDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Details'), 200)
);
const ConsolRouting = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Routing'), 200)
);
const ConsolTransport = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Transport'), 200)
);
const ConsolWip = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Wip'), 200)
);
const ConsolContainer = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Container'), 200)
);
const ConsolContainerDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Container/Details'), 200)
);
const ConsolAlertNote = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/AlertNote'), 200)
);
const ConsolDocument = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/EmailEdoc'), 200)
);
const ConsolAccounting = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Accounting'), 200)
);
const ConsolApInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Accounting/AP/Detail'), 200)
);
const ConsoleArInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Consol/Accounting/AR/Detail'), 200)
);

const Shipment = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment'), 200)
);
const ShipmentLayout = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/DetailsLayout'), 200)
);
const ShipmentDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Details'), 200)
);
const ShipmentRouting = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Routing'), 200)
);
const ShipmentService = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Service'), 200)
);
const ShipmentContainer = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Container'), 200)
);
const ShipmentContainerDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Container/Details'), 200)
);
const ShipmentAlertNote = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/AlertNote'), 200)
);
const ShipmentDocument = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/EmailEdoc'), 200)
);
const ShipmentBilling = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Billing'), 200)
);
const ShipmentArInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Billing/AR/Details'), 200)
);
const ShipmentApInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Billing/AP/Detail'), 200)
);
const ShipmentWip = loadable(() =>
  pMinDelay(import('pages/AirFreight/Shipment/Wip'), 200)
);

const ShipmentNoConsolLayout = loadable(() =>
  pMinDelay(import('pages/AirFreight/ShipmentNoConsol/DetailsLayout'), 200)
);
const ShipmentNoConsolWip = loadable(() =>
  pMinDelay(import('pages/AirFreight/ShipmentNoConsol/Wip'), 200)
);

const ShipmentNoConsolDetails = loadable(() =>
  pMinDelay(import('pages/AirFreight/ShipmentNoConsol/Details'), 200)
);

const ShipmentNoConsolAlertNote = loadable(() =>
  pMinDelay(import('pages/AirFreight/ShipmentNoConsol/AlertNote'), 200)
);

const airFreightRoutes: IAdminRoute = {
  name: 'Air Freight',
  path: 'air-freight',
  code: [
    GroupAirFreight.AIR_CONSOL_DETAIL,
    GroupAirFreight.AIR_SHIPMENT_DETAIL
  ],
  icon: 'flight',
  children: [
    {
      parentKey: 'air-freight',
      name: 'Consolidation',
      code: [GroupAirFreight.AIR_CONSOL_DETAIL],
      path: 'consolidation',
      icon: 'view_in_ar',
      children: [
        {
          index: true,
          element: <Consol />,
          code: [GroupAirFreight.AIR_CONSOL_DETAIL]
        },
        {
          path: 'new',
          element: <ConsolLayout key="new" />,
          code: [GroupAirFreight.AIR_CONSOL_DETAIL],
          children: [
            {
              index: true,
              element: <ConsolDetails key="new" />,
              code: [GroupAirFreight.AIR_CONSOL_DETAIL]
            }
          ]
        },
        {
          path: ':id',
          element: <ConsolLayout key=":id" />,
          code: [GroupAirFreight.AIR_CONSOL_DETAIL],
          children: [
            {
              index: true,
              element: <ConsolDetails key=":id" />,
              code: [GroupAirFreight.AIR_CONSOL_DETAIL]
            },
            {
              path: 'routing',
              element: <ConsolRouting />,
              code: [GroupAirFreight.AIR_CONSOL_ROUTING]
            },
            {
              path: 'transport',
              element: <ConsolTransport />,
              code: [GroupAirFreight.AIR_CONSOL_FAK_TRANSPORT]
            },
            {
              path: 'shipment',
              code: [GroupAirFreight.AIR_SHIPMENT_DETAIL],
              children: [
                {
                  index: true,
                  element: <Shipment />,
                  code: [GroupAirFreight.AIR_SHIPMENT_DETAIL]
                },
                {
                  path: 'new',
                  element: <ShipmentLayout key="new" />,
                  code: [GroupAirFreight.AIR_SHIPMENT_DETAIL],
                  children: [
                    {
                      index: true,
                      element: <ShipmentDetails key="new" />,
                      code: [GroupAirFreight.AIR_SHIPMENT_DETAIL]
                    }
                  ]
                },
                {
                  path: ':shipmentId',
                  element: <ShipmentLayout key=":shipmentId" />,
                  code: [GroupAirFreight.AIR_SHIPMENT_DETAIL],
                  children: [
                    {
                      index: true,
                      element: <ShipmentDetails key=":airShipmentId" />,
                      code: [GroupAirFreight.AIR_SHIPMENT_DETAIL]
                    },
                    {
                      path: 'routing',
                      element: <ShipmentRouting />,
                      code: [GroupAirFreight.AIR_SHIPMENT_ROUTING]
                    },
                    {
                      path: 'containers-packing',
                      code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER],
                      children: [
                        {
                          index: true,
                          element: <ShipmentContainer />,
                          code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER]
                        },
                        {
                          path: 'new',
                          element: <ShipmentContainerDetails key="new" />,
                          code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER]
                        },
                        {
                          path: ':containerId',
                          element: (
                            <ShipmentContainerDetails key=":containerId" />
                          ),
                          code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER]
                        }
                      ]
                    },
                    {
                      path: 'service',
                      element: <ShipmentService />
                    },
                    {
                      path: 'wip',
                      element: <ShipmentWip />,
                      code: [GroupAirFreight.AIR_SHIPMENT_WIP]
                    },
                    {
                      path: 'billing',
                      code: [
                        GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
                        GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
                        GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
                        GroupAirFreight.AIR_SHIPMENT_AP_INVOICE
                      ],
                      children: [
                        {
                          index: true,
                          element: <ShipmentBilling />,
                          code: [
                            GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
                            GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
                            GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
                            GroupAirFreight.AIR_SHIPMENT_AP_INVOICE
                          ]
                        },
                        {
                          path: 'ar',
                          code: [GroupAirFreight.AIR_SHIPMENT_AR_INVOICE],
                          children: [
                            {
                              index: true,
                              element: <Navigate to="../" />,
                              code: [GroupAirFreight.AIR_SHIPMENT_AR_INVOICE]
                            },
                            {
                              path: 'new',
                              code: [GroupAirFreight.AIR_SHIPMENT_AR_INVOICE],
                              element: <ShipmentArInvoiceDetails key="new" />
                            },
                            {
                              path: ':arInvoiceId',
                              code: [GroupAirFreight.AIR_SHIPMENT_AR_INVOICE],
                              element: (
                                <ShipmentArInvoiceDetails key=":arInvoiceId" />
                              )
                            }
                          ]
                        },
                        {
                          path: 'ap',
                          code: [GroupAirFreight.AIR_SHIPMENT_AP_INVOICE],
                          children: [
                            {
                              index: true,
                              element: <Navigate to="../" />,
                              code: [GroupAirFreight.AIR_SHIPMENT_AP_INVOICE]
                            },
                            {
                              path: 'new',
                              code: [GroupAirFreight.AIR_SHIPMENT_AP_INVOICE],
                              element: <ShipmentApInvoiceDetails key="new" />
                            },
                            {
                              path: ':apInvoiceId',
                              code: [GroupAirFreight.AIR_SHIPMENT_AP_INVOICE],
                              element: (
                                <ShipmentApInvoiceDetails key=":apInvoiceId" />
                              )
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'alert-note',
                      code: [GroupAirFreight.AIR_SHIPMENT_ALERT_NOTE],
                      element: <ShipmentAlertNote />
                    },
                    {
                      path: 'document',
                      code: [GroupAirFreight.AIR_SHIPMENT_DOCUMENT],
                      element: <ShipmentDocument />
                    }
                  ]
                }
              ]
            },
            {
              path: 'containers-packing',
              code: [GroupAirFreight.AIR_CONSOL_CONTAINER],
              children: [
                {
                  index: true,
                  element: <ConsolContainer />,
                  code: [GroupAirFreight.AIR_CONSOL_CONTAINER]
                },
                {
                  path: 'new',
                  element: <ConsolContainerDetails key="new" />,
                  code: [GroupAirFreight.AIR_CONSOL_CONTAINER]
                },
                {
                  path: ':containerId',
                  element: <ConsolContainerDetails key=":containerId" />,
                  code: [GroupAirFreight.AIR_CONSOL_CONTAINER]
                }
              ]
            },
            {
              path: 'wip',
              code: [GroupAirFreight.AIR_CONSOL_WIP],
              element: <ConsolWip />
            },
            {
              path: 'accounting',
              code: [
                GroupAirFreight.AIR_CONSOL_ACCOUNTING_SUMMARY,
                GroupAirFreight.AIR_CONSOL_CHARGE_CODE,
                GroupAirFreight.AIR_CONSOL_AR_INVOICE,
                GroupAirFreight.AIR_CONSOL_AP_INVOICE
              ],
              children: [
                {
                  index: true,
                  element: <ConsolAccounting />,
                  code: [
                    GroupAirFreight.AIR_CONSOL_ACCOUNTING_SUMMARY,
                    GroupAirFreight.AIR_CONSOL_CHARGE_CODE,
                    GroupAirFreight.AIR_CONSOL_AR_INVOICE,
                    GroupAirFreight.AIR_CONSOL_AP_INVOICE
                  ]
                },
                {
                  path: 'ap',
                  code: [GroupAirFreight.AIR_CONSOL_AP_INVOICE],
                  children: [
                    {
                      index: true,
                      code: [GroupAirFreight.AIR_CONSOL_AP_INVOICE],
                      element: <Navigate to="../" />
                    },
                    {
                      path: 'new',
                      code: [GroupAirFreight.AIR_CONSOL_AP_INVOICE],
                      element: <ConsolApInvoiceDetails key="new" />
                    },
                    {
                      path: ':apInvoiceId',
                      code: [GroupAirFreight.AIR_CONSOL_AP_INVOICE],
                      element: <ConsolApInvoiceDetails key=":apInvoiceId" />
                    }
                  ]
                },
                {
                  path: 'ar',
                  code: [GroupAirFreight.AIR_CONSOL_AR_INVOICE],
                  children: [
                    {
                      index: true,
                      code: [GroupAirFreight.AIR_CONSOL_AR_INVOICE],
                      element: <Navigate to="../" />
                    },
                    {
                      path: 'new',
                      code: [GroupAirFreight.AIR_CONSOL_AR_INVOICE],
                      element: <ConsoleArInvoiceDetails key="new" />
                    },
                    {
                      path: ':arInvoiceId',
                      code: [GroupAirFreight.AIR_CONSOL_AR_INVOICE],
                      element: <ConsoleArInvoiceDetails key=":arInvoiceId" />
                    }
                  ]
                }
              ]
            },
            {
              path: 'alert-note',
              code: [GroupAirFreight.AIR_CONSOL_ALERT_NOTE],
              element: <ConsolAlertNote />
            },
            {
              path: 'document',
              code: [GroupAirFreight.AIR_CONSOL_DOCUMENT],
              element: <ConsolDocument />
            }
          ]
        }
      ]
    },
    {
      parentKey: 'air-freight',
      name: 'Shipment',
      code: [GroupAirFreight.AIR_SHIPMENT_DETAIL],
      path: 'shipment',
      icon: 'list_alt',
      children: [
        {
          index: true,
          element: <ShipmentNoConsol />,
          code: [GroupAirFreight.AIR_SHIPMENT_DETAIL]
        },
        {
          path: ':shipmentId',
          element: <ShipmentNoConsolLayout key=":shipmentId" />,
          code: [GroupAirFreight.AIR_SHIPMENT_DETAIL],
          children: [
            {
              index: true,
              element: <ShipmentNoConsolDetails />,
              code: [GroupAirFreight.AIR_SHIPMENT_DETAIL]
            },
            {
              path: 'routing',
              element: <ShipmentRouting />,
              code: [GroupAirFreight.AIR_SHIPMENT_ROUTING]
            },
            {
              path: 'service',
              element: <ShipmentService />
            },
            {
              path: 'wip',
              element: <ShipmentNoConsolWip />,
              code: [GroupAirFreight.AIR_SHIPMENT_WIP]
            },
            {
              path: 'alert-note',
              element: <ShipmentNoConsolAlertNote />,
              code: [GroupAirFreight.AIR_SHIPMENT_ALERT_NOTE]
            },
            {
              path: 'containers-packing',
              code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER],
              children: [
                {
                  index: true,
                  element: <ShipmentContainer />,
                  code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER]
                },
                {
                  path: 'new',
                  code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER],
                  element: <ShipmentContainerDetails key="new" />
                },
                {
                  path: ':containerId',
                  code: [GroupAirFreight.AIR_SHIPMENT_CONTAINER],
                  element: <ShipmentContainerDetails key=":containerId" />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default airFreightRoutes;
