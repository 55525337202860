/* eslint-disable max-lines */
import { generatePath } from 'react-router-dom';

import {
  InvoiceCategory,
  ENDPOINTS,
  InvoiceType,
  QUERY_KEYS,
  WebTable,
  WipServiceType
} from '@constants';
import APIManager, { END_POINTS } from '@services';

import {
  useCUDMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchBillingApInvoiceDetails = (
  consolId?: number | string | null,
  shipmentId?: number | string,
  apInvoiceId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.billingApInvoice,
      apInvoiceId ? parseInt(apInvoiceId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.apInvoice(InvoiceCategory.Billing, ':apInvoiceId', {
          apInvoiceId
        }),
        body: {
          consolId,
          shipmentId,
          includeFields: [
            'apInvoiceStatus',
            'paymentCurrency',
            'accountPayable',
            'accountPayableBank',
            'paymentMode',
            'payableAdvice'
          ]
        }
      });
      return res?.data;
    },
    enabled: !!shipmentId && !!apInvoiceId
  });
};

export const useCUDBillingApInvoice = (
  consolId?: number | string | null,
  shipmentId?: number | string
) => {
  const query = {
    consolId,
    shipmentId
  };
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.apInvoice(InvoiceCategory.Billing, '', query),
    webTable: WebTable.BILLING_AP_INVOICE,
    queryKey: QUERY_KEYS.billingApInvoice,
    dependentWebTable: [WebTable.BILLING_AP_CHARGE_CODE_INVOICE]
  });
};

export const useCUDBillingAPChargeCode = (
  apInvoiceId?: number | string,
  mutationKey?: string
) => {
  const queryKeyMutation = mutationKey || QUERY_KEYS.billingApInvoice;
  return useCUDMutationEnhancer<any>({
    mutationKeys: [[queryKeyMutation]],
    endPoint: generatePath(END_POINTS.CONSOL.AP_CHARGE_CODE, {
      billingCategory: InvoiceCategory.Billing,
      apInvoiceId: apInvoiceId || ''
    }),
    webTable: WebTable.BILLING_AP_CHARGE_CODE_INVOICE
  });
};

export const useFetchBillingArInvoiceDetails = (
  consolId?: number | string | null,
  shipmentId?: number | string,
  arInvoiceId?: number | string
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.billingArInvoice,
      arInvoiceId ? parseInt(arInvoiceId as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(InvoiceCategory.Billing, ':arInvoiceId', {
          arInvoiceId
        }),
        body: {
          consolId,
          shipmentId,
          includeFields: [
            'arInvoiceStatus',
            'paymentCurrency',
            'accountReceivable',
            'arBank',
            'paymentMode',
            'companyBank'
          ]
        }
      });
      return res?.data;
    },
    enabled: !!shipmentId && !!arInvoiceId
  });
};

export const useCUDBillingArInvoice = (
  consolId?: number | string | null,
  shipmentId?: number | string
) => {
  const query = {
    consolId,
    shipmentId
  };
  return usePageCUDMutationEnhancer<any>({
    mutationKeys: [[QUERY_KEYS.arInvoice, QUERY_KEYS.accountingStatistics]],
    endPoint: ENDPOINTS.arInvoice(InvoiceCategory.Billing, '', query),
    webTable: WebTable.SHIPMENT_AR_INVOICES,
    // queryKey: QUERY_KEYS.arInvoice,
    dependentWebTable: [
      WebTable.BILLING_AR_CHARGE_CODE,
      WebTable.AR_CHARGE_CODE_SUMMARY
    ]
  });
};

export const useCUDBillingARChargeCode = (
  arInvoiceId?: number | string,
  mutationKey?: string
) => {
  return useCUDMutationEnhancer<any>({
    mutationKeys: [[mutationKey], [QUERY_KEYS.billingArInvoice]],
    endPoint: generatePath(END_POINTS.CONSOL.AR_CHARGE_CODE, {
      billingCategory: InvoiceCategory.Billing,
      arInvoiceId: arInvoiceId || ''
    }),
    webTable: WebTable.BILLING_AR_CHARGE_CODE,
    dependentWebTable: [WebTable.AR_CHARGE_CODE_SUMMARY]
  });
};

export const useFetchBillingCalculateWipCost = async (
  consolId?: string | number | null,
  shipmentId?: string | number,
  // accountPayableId?: string | number,
  chargeCodeId?: string | number,
  arChargeCodeFromId?: string | number,
  arChargeCodeToId?: string | number,
  containerTypeSizeId?: string | number,
  serviceType?: WipServiceType
) => {
  const isCallRequest = !!shipmentId && !!chargeCodeId && !!serviceType;

  if (isCallRequest) {
    const res = await APIManager.request({
      url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_WIP_COST, {
        billingCategory: InvoiceCategory.Billing
      }),
      body: {
        consolId,
        shipmentId,
        chargeCodeId,
        arChargeCodeFromId,
        arChargeCodeToId,
        containerTypeSizeId,
        serviceType
      }
    });
    return res.data;
  }
  return null;
};

export const useFetchBillingCalculateQuantity = async (
  chargeCodeUnitId: any,
  consolId: any,
  shipmentId: any,
  chargeCodeFromId: any,
  chargeCodeToId: any,
  containerTypeSizeId: any
) => {
  const res = await APIManager.request({
    url: ENDPOINTS.wip(InvoiceCategory.Billing, 'calculate-quantity'),
    body: {
      chargeCodeUnitId,
      consolId,
      shipmentId,
      chargeCodeFromId,
      chargeCodeToId,
      containerTypeSizeId
    }
  });

  return res?.data?.quantity;
};

export const useCUDBillingARChargeCodeCreditNote = (
  arInvoiceId?: number | string,
  mutationKey?: string
) => {
  return useCUDMutationEnhancer<any>({
    mutationKeys: [[mutationKey], [QUERY_KEYS.billingArInvoice]],
    endPoint: generatePath(END_POINTS.CONSOL.AR_CHARGE_CODE_CREDIT_NOTE, {
      billingCategory: InvoiceCategory.Billing,
      arInvoiceId: arInvoiceId || ''
    }),
    webTable: WebTable.BILLING_AR_CHARGE_CODE,
    dependentWebTable: [WebTable.AR_CHARGE_CODE_SUMMARY]
  });
};

export const useFetchCalculateDueDateBillingAp = async (
  accountPayableId?: number | string,
  invoiceType?: InvoiceType,
  consolId?: number | string | null,
  shipmentId?: number | string,
  serviceId?: number | string
) => {
  if (!!accountPayableId && !!invoiceType) {
    let res;
    if (!!shipmentId) {
      res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AP, {
          billingCategory: InvoiceCategory.Billing
        }),
        body: {
          accountPayableId,
          invoiceType,
          consolId,
          shipmentId
        }
      });
    }

    if (!!serviceId) {
      res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AP, {
          billingCategory: InvoiceCategory.Service
        }),
        body: {
          accountPayableId,
          invoiceType,
          serviceId
        }
      });
    }

    return res?.data;
  }
  return undefined;
};

export const useFetchDefaultCalculateDueDateBillingAp = (
  consolId?: string | number | null,
  shipmentId?: string | number,
  apInvoiceId?: string | number
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.billingCalculateDueDateAp,
      consolId,
      shipmentId,
      apInvoiceId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AP, {
          billingCategory: InvoiceCategory.Billing
        }),
        body: {
          consolId,
          shipmentId,
          apInvoiceId
        }
      });

      return res?.data ?? null;
    },
    enabled: !!apInvoiceId && !!shipmentId
  });
};

export const useFetchCalculateDueDateBillingAr = async (
  accountReceivableId?: number | string,
  invoiceType?: InvoiceType,
  consolId?: number | string | null,
  shipmentId?: string | number,
  serviceId?: string | number
) => {
  if (!!accountReceivableId && !!invoiceType) {
    let res;
    if (!!shipmentId) {
      res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AR, {
          billingCategory: InvoiceCategory.Billing
        }),
        body: {
          accountReceivableId,
          invoiceType,
          consolId,
          shipmentId
        }
      });
    }

    if (!!serviceId) {
      res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AR, {
          billingCategory: InvoiceCategory.Service
        }),
        body: {
          accountReceivableId,
          invoiceType,
          serviceId
        }
      });
    }
    return res?.data ?? null;
  }
  return undefined;
};

export const useFetchDefaultCalculateDueDateBillingAr = (
  consolId?: string | number | null,
  shipmentId?: string | number,
  arInvoiceId?: string | number
) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.billingCalculateDueDateAr,
      consolId,
      shipmentId,
      arInvoiceId
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AR, {
          billingCategory: InvoiceCategory.Billing
        }),
        body: {
          arInvoiceId,
          consolId,
          shipmentId
        }
      });
      return res?.data ?? null;
    },
    enabled: !!arInvoiceId || !!shipmentId || !!consolId
  });
};
