import { AirOceanType, GroupAirFreight, GroupOceanFreight } from './enum';

export const AR_INCLUDE_FIELDS = [
  'arInvoiceStatus',
  'accountReceivable',
  'chartOfAccountReceivable'
];

export const AP_INCLUDE_FIELDS = [
  'apInvoiceStatus',
  'accountPayable',
  'chartOfAccountPayable'
];

export const INVOICE_INCLUDE_FIELDS = Array.from(
  new Set([
    ...AR_INCLUDE_FIELDS,
    ...AP_INCLUDE_FIELDS,
    'paymentCurrency',
    'onBehalfClient',
    'syncBy'
  ])
);

export const WIP_INCLUDE_FIELDS = [
  'apClient',
  'arClient',
  'addrFrom',
  'addrTo',
  'containerTypeSize',
  'chargeCodeUnit',
  'chargeCode',
  'currency',
  'onBehalfClient',
  'taxType',
  'wipPrices',
  'wipSubChargeCodeList',
  'wipSubChargeCodes'
];

export const CHARGE_CODE_INCLUDE_FIELDS = [
  'chargeCode',
  'chargeCodeFrom',
  'chargeCodeTo',
  'containerTypeSize',
  'chargeCodeUnit'
];

export const AR_MAPPED_FIELDS = {
  'accountReceivable.name': 'accountReceivableName',
  'accountReceivable.code': 'accountReceivableCode',
  'arInvoiceStatus.name': 'arInvoiceStatus',
  'arInvoiceStatus.code': 'arInvoiceStatus'
};

export const AP_MAPPED_FIELDS = {
  'accountPayable.name': 'accountPayableCode',
  'accountPayable.code': 'accountPayableName',
  'apInvoiceStatus.name': 'apInvoiceStatus',
  'apInvoiceStatus.code': 'apInvoiceStatus'
};

export const INVOICE_MAPPED_FIELDS = {
  ...AR_MAPPED_FIELDS,
  ...AP_MAPPED_FIELDS,
  'paymentCurrency.code': 'paymentCurrency',
  'paymentMode.code': 'paymentModeCode',
  'syncBy.displayName': 'syncBy'
};

export const WIP_MAPPED_FIELDS = {
  'chargeCode.description': 'description',
  'chargeCode.chargeCodeSubCategory.chargeCodeCategory.name':
    'chargeCodeCategory',
  'chargeCode.chargeCodeSubCategory.name': 'chargeCodeSubCategory',
  'addrFrom.code': 'addrFrom',
  'addrTo.code': 'addrTo',
  'containerTypeSize.name': 'containerTypeSize',
  'chargeCode.levelType': 'levelType',
  'currency.code': 'currency',
  'chargeCodeUnit.name': 'chargeCodeUnit',
  'taxType.defaultRate': 'taxType',
  'apClient.code': 'apClientCode',
  'apClient.name': 'apClientName',
  estSubTotalValue: 'estSubTotal',
  taxAmountValue: 'taxAmount',
  estTotalValue: 'estTotal'
};

export const CHARGE_CODE_MAPPED_FIELDS = {
  'chargeCode.code': 'chargeCode',
  'chargeCode.description': 'description',
  'chargeCode.chargeCodeSubCategory.chargeCodeCategory.name':
    'chargeCodeCategory',
  'chargeCode.chargeCodeSubCategory.name': 'chargeCodeSubCategory',
  'containerTypeSize.name': 'containerTypeSize',
  'chargeCodeFrom.code': 'chargeCodeFrom',
  'chargeCodeTo.code': 'chargeCodeTo'
};

export const KeyPermissionBilling = () => {
  const oceanCsAccounting = {
    summaryKey: GroupOceanFreight.OCEAN_CONSOL_ACCOUNTING_SUMMARY,
    chargeCodeKey: GroupOceanFreight.OCEAN_CONSOL_CHARGE_CODE,
    arKey: GroupOceanFreight.OCEAN_CONSOL_AR_INVOICE,
    apKey: GroupOceanFreight.OCEAN_CONSOL_AP_INVOICE,
    wipKey: GroupOceanFreight.OCEAN_CONSOL_WIP
  };
  const oceanSmBilling = {
    summaryKey: GroupOceanFreight.OCEAN_SHIPMENT_BILLING_SUMMARY,
    chargeCodeKey: GroupOceanFreight.OCEAN_SHIPMENT_CHARGE_CODE,
    arKey: GroupOceanFreight.OCEAN_SHIPMENT_AR_INVOICE,
    apKey: GroupOceanFreight.OCEAN_SHIPMENT_AP_INVOICE,
    wipKey: GroupOceanFreight.OCEAN_SHIPMENT_WIP
  };
  const airCsAccounting = {
    summaryKey: GroupAirFreight.AIR_CONSOL_ACCOUNTING_SUMMARY,
    chargeCodeKey: GroupAirFreight.AIR_CONSOL_CHARGE_CODE,
    arKey: GroupAirFreight.AIR_CONSOL_AR_INVOICE,
    apKey: GroupAirFreight.AIR_CONSOL_AP_INVOICE,
    wipKey: GroupAirFreight.AIR_CONSOL_WIP
  };
  const airSmBilling = {
    summaryKey: GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
    chargeCodeKey: GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
    arKey: GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
    apKey: GroupAirFreight.AIR_SHIPMENT_AP_INVOICE,
    wipKey: GroupAirFreight.AIR_SHIPMENT_WIP
  };
  const serviceBilling = {
    summaryKey: GroupAirFreight.AIR_SHIPMENT_BILLING_SUMMARY,
    chargeCodeKey: GroupAirFreight.AIR_SHIPMENT_CHARGE_CODE,
    arKey: GroupAirFreight.AIR_SHIPMENT_AR_INVOICE,
    apKey: GroupAirFreight.AIR_SHIPMENT_AP_INVOICE,
    wipKey: GroupAirFreight.AIR_SHIPMENT_WIP
  };

  return {
    oceanCsAccounting,
    oceanSmBilling,
    airCsAccounting,
    airSmBilling,
    serviceBilling
  };
};

export const BILL_OF_LADING_LABELS = {
  [AirOceanType.Air]: {
    OBL_NO: 'mawb_no',
    OBL_RELEASED_TYPE: 'mawb_released_type',
    OBL_RELEASED_DATE: 'mawb_released_date',
    PBL_NO: 'pawb_no',
    PBL_RELEASED_TYPE: 'pawb_released_type',
    PBL_RELEASED_DATE: 'pawb_released_date',
    HBL_NO: 'hawb_no',
    HBL_RELEASED_TYPE: 'hawb_released_type',
    HBL_RELEASED_DATE: 'hawb_released_date'
  },
  [AirOceanType.Ocean]: {
    OBL_NO: 'obl_no',
    OBL_RELEASED_TYPE: 'obl_released_type',
    OBL_RELEASED_DATE: 'obl_released_date',
    PBL_NO: 'pbl_no',
    PBL_RELEASED_TYPE: 'pbl_released_type',
    PBL_RELEASED_DATE: 'pbl_released_date',
    HBL_NO: 'hbl_no',
    HBL_RELEASED_TYPE: 'hbl_released_type',
    HBL_RELEASED_DATE: 'hbl_released_date'
  }
};
