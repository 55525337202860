import { IMetadata } from '@dto';
import { IApChargeCode } from '@invoice';
import { IRowNode } from 'ag-grid-community';
import React, { memo, useCallback, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useWatch
} from 'react-hook-form';
import {
  KButton,
  KColors,
  KContainer,
  KForm,
  KGrid,
  KImage,
  KInput
} from 'uikit';
import * as yup from 'yup';

import { BottomActions } from 'components';
import { formatToFixed } from 'containers/FromWip/helpers';
import { useFetchChargeCodeDetails, useResolverForm } from 'hooks';
import trans from 'translation';
import { ValidationUtils, useDefaultValue } from 'utils';

import FormInfoChargeCode from '../../../Form.InfoChargeCode';
import { amountComposite } from '../../../helpers';

export interface ApSubChargeCodes {
  id?: number;
  subChargeCode?: any;
  subChargeCodeId?: number;
  currency?: IMetadata | null;
  currencyId?: number;
  subTotal?: number | null;
  ratio?: number;
  estTotal?: number;
}

interface IFormData {
  baseValue: number;
  apSubChargeCodes?: ApSubChargeCodes[];
  subTotal: number;
  paymentSubTotal: number;
}

interface IProps {
  node: IRowNode<IApChargeCode>;
  callBackNodeData: (v: any) => void;
  paymentCurrency: any;
  isCreditNote: boolean;
}

const FormSubChargeCode = ({
  node,
  callBackNodeData,
  paymentCurrency,
  isCreditNote
}: IProps) => {
  const { data: item } = node;
  const { itemSubChargeCodes } = useDefaultValue();

  const {
    chargeCode,
    apChargeCodeFrom,
    apChargeCodeTo,
    containerTypeSize,
    currency,
    exchangeRate,
    taxType
  } = item as IApChargeCode;

  const { data: chargeCodeDetails } = useFetchChargeCodeDetails(chargeCode?.id);

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({
      baseValue: ValidationUtils.nullableNum().min(0),
      arSubChargeCodes: yup.array().of(
        yup.object().shape({
          subChargeCode: ValidationUtils.requiredAny(),
          subTotal: ValidationUtils.requiredNum(),
          ratio: ValidationUtils.requiredNum()
        })
      )
    }),
    configs: {
      values: {
        baseValue: item?.baseValue ?? 0,
        subTotal: item?.subTotal ?? 0,
        paymentSubTotal: item?.paymentSubTotal ?? 0,
        apSubChargeCodes:
          (item?.apSubChargeCodes || []).length > 0
            ? item?.apSubChargeCodes
            : [itemSubChargeCodes]
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'apSubChargeCodes',
    keyName: 'formId'
  });

  const [apSubChargeCodesWatch, baseValueWatch] = useWatch({
    control: methods.control,
    name: ['apSubChargeCodes', 'baseValue']
  });

  const infoChargeCode = useMemo(() => {
    const list = [
      {
        label: 'charge_code',
        value: chargeCode?.code
      },
      {
        label: 'from',
        value: apChargeCodeFrom?.code
      },
      {
        label: 'to',
        value: apChargeCodeTo?.code
      },
      {
        label: 'container_size',
        value: containerTypeSize?.code
      },
      {
        label: 'ex_rate',
        value: exchangeRate,
        unit: paymentCurrency?.code || currency?.code
      }
    ];

    return list;
  }, [
    chargeCode?.code,
    apChargeCodeFrom?.code,
    apChargeCodeTo?.code,
    containerTypeSize?.code,
    exchangeRate,
    paymentCurrency?.code,
    currency?.code
  ]);

  const lastRow = useCallback(
    (index: number) => fields?.length === index,
    [fields?.length]
  );

  const onAppend = useCallback(async () => {
    const isValid = await methods.trigger('apSubChargeCodes');
    if (isValid) {
      append(itemSubChargeCodes, { shouldFocus: false });
    }
  }, [append, itemSubChargeCodes, methods]);

  const onFormValid = useCallback(
    ({ apSubChargeCodes, baseValue }: IFormData) => {
      const {
        newSubChargeCode,
        subTotal,
        paymentSubTotal,
        paymentTaxAmount,
        paymentTotalAmount
      } = amountComposite(
        isCreditNote,
        apSubChargeCodes,
        baseValue,
        exchangeRate,
        taxType
      );

      callBackNodeData({
        ...(item as IApChargeCode),
        subTotal: subTotal,
        baseValue,
        apSubChargeCodes: newSubChargeCode,
        paymentSubTotal: +formatToFixed(paymentSubTotal),
        paymentTaxAmount: +formatToFixed(paymentTaxAmount),
        paymentTotalAmount: +formatToFixed(paymentTotalAmount),
        balance: +formatToFixed(paymentTotalAmount),
        quantity: 1,
        uplift: 0
      });
    },
    [callBackNodeData, exchangeRate, isCreditNote, item, taxType]
  );

  const mapSubChargeCodeId = useMemo(() => {
    const mapVal = apSubChargeCodesWatch?.reduce((acc, cur) => {
      const id =
        cur?.subChargeCode?.subChargeCode?.id || cur?.subChargeCode?.id;
      if (id) {
        acc[id] = true;
      }

      return acc;
    }, {});

    return mapVal;
  }, [apSubChargeCodesWatch]);

  const newChargeCodeDetails = useMemo(() => {
    const res = chargeCodeDetails?.subChargeCodes?.filter((itemK: any) => {
      return !mapSubChargeCodeId?.[itemK?.subChargeCode?.id];
    });

    return res;
  }, [chargeCodeDetails?.subChargeCodes, mapSubChargeCodeId]);

  return (
    <KContainer.View>
      <KContainer.View dp="flex" row justifyContent="space-between">
        <FormInfoChargeCode infoChargeCode={infoChargeCode} />
      </KContainer.View>

      <KContainer.View marginT={'2.5rem'}>
        <FormProvider {...methods}>
          <KForm onSubmit={methods.handleSubmit(onFormValid)}>
            <KGrid.Container>
              <KGrid.Item xs={4} mb={'0.75rem'}>
                <Controller
                  name="baseValue"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        required
                        label={trans('with_unit.base_value', {
                          unit: currency?.code
                        })}
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
            <KGrid.Container>
              <KGrid.Item xs={12}>
                <KContainer.Fieldset
                  title={trans('sub_charge_code_list')}
                  required
                  marginT="0.5rem"
                  paddingT="1rem"
                >
                  <KGrid.Container>
                    {fields.map((itemV: any, index: any) => {
                      return (
                        <React.Fragment key={itemV?.formId}>
                          <KGrid.Item xs={3.6}>
                            <Controller
                              name={`apSubChargeCodes.${index}.subChargeCode`}
                              control={methods.control}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <KInput.Autocomplete
                                    {...field}
                                    label={trans('sub_charge_code')}
                                    options={newChargeCodeDetails ?? []}
                                    onChange={(v: any) => {
                                      methods.setValue(
                                        `apSubChargeCodes.${index}.ratio`,
                                        v?.ratio
                                      );
                                      field.onChange(v);
                                    }}
                                    inputProps={{
                                      message: error?.message,
                                      required: true
                                    }}
                                    isOptionEqualToValue={(
                                      o?: any,
                                      v?: any
                                    ) => {
                                      return o?.chargeCodeId === v?.id;
                                    }}
                                    getOptionLabel={(o: any) => {
                                      return (
                                        o?.subChargeCode?.code ?? o?.code ?? ''
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </KGrid.Item>

                          <KGrid.Item xs={3.8}>
                            <Controller
                              name={`apSubChargeCodes.${index}.subTotal`}
                              control={methods.control}
                              render={({ field, fieldState: { error } }) => (
                                <KInput.TextField
                                  {...field}
                                  label={trans('with_unit.value', {
                                    unit: currency?.code
                                  })}
                                  required
                                  message={error?.message}
                                  onChange={v => {
                                    const valRatio = methods.getValues(
                                      `apSubChargeCodes.${index}.ratio`
                                    );
                                    const estTotalVal =
                                      ((Number(v.target.value) || 0) *
                                        (Number(valRatio) || 0)) /
                                      100;

                                    methods.setValue(
                                      `apSubChargeCodes.${index}.estTotal`,
                                      estTotalVal
                                    );
                                    field.onChange(v);
                                  }}
                                />
                              )}
                            />
                          </KGrid.Item>

                          <KGrid.Item xs={3.8}>
                            <Controller
                              name={`apSubChargeCodes.${index}.ratio`}
                              control={methods.control}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <KInput.TextField
                                    {...field}
                                    label={trans('with_unit.ratio')}
                                    onChange={v => {
                                      const valSubTotal = methods.getValues(
                                        `apSubChargeCodes.${index}.ratio`
                                      );
                                      const estTotalVal =
                                        ((Number(v.target.value) || 0) *
                                          (Number(valSubTotal) || 0)) /
                                        100;

                                      methods.setValue(
                                        `apSubChargeCodes.${index}.estTotal`,
                                        estTotalVal
                                      );
                                      field.onChange(v);
                                    }}
                                    message={error?.message}
                                    required
                                  />
                                );
                              }}
                            />
                          </KGrid.Item>

                          <KGrid.Item xs={0.8}>
                            <KContainer.View row alignItems marginT="0.75rem">
                              {fields.length > 1 && (
                                <KImage.MuiIcon
                                  icon="CancelOutlined"
                                  color={KColors.secondary.normal}
                                  onPress={() => remove(index)}
                                />
                              )}

                              {lastRow(index + 1) && fields.length < 5 && (
                                <KImage.MuiIcon
                                  icon="AddCircleOutline"
                                  color={KColors.primary.normal}
                                  marginL="0.25rem"
                                  onPress={onAppend}
                                />
                              )}
                            </KContainer.View>
                          </KGrid.Item>
                        </React.Fragment>
                      );
                    })}
                  </KGrid.Container>
                </KContainer.Fieldset>
              </KGrid.Item>
            </KGrid.Container>

            <KGrid.Container>
              <KGrid.Item xs={1.3} mt={'1.5rem'}>
                <KButton.Solid
                  title={trans('calculate')}
                  background={KColors.primary.normal}
                  hoverColor={KColors.primary.normal}
                  onPress={() => {
                    const { subTotal, paymentSubTotal } = amountComposite(
                      isCreditNote,
                      apSubChargeCodesWatch,
                      baseValueWatch,
                      exchangeRate,
                      taxType
                    );
                    methods.setValue('subTotal', subTotal ?? 0);
                    methods.setValue('paymentSubTotal', paymentSubTotal ?? 0);
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={2} mt={'0.75rem'}>
                <Controller
                  name="subTotal"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        required
                        label={trans('with_unit.sub_total', {
                          unit: chargeCode?.currency?.code
                        })}
                        disabled
                      />
                    );
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={2} mt={'0.75rem'}>
                <Controller
                  name="paymentSubTotal"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        required
                        label={trans('with_unit.payment_sub_total', {
                          unit: paymentCurrency?.code || currency?.code
                        })}
                        disabled
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>

            <BottomActions btnProps={[{}]} />
          </KForm>
        </FormProvider>
      </KContainer.View>
    </KContainer.View>
  );
};

export default memo(FormSubChargeCode);
