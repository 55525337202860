import { omit } from 'lodash';

import { Prototype } from 'core';

export default class RequestUtils {
  static readonly normalizeData = <T extends {}>(options: {
    data: T;
    idFields?: string[];
    dateFields?: string[];
    dateTimeFields?: string[];
    timeFields?: string[];
  }) => {
    const {
      data,
      idFields = [],
      dateFields = [],
      dateTimeFields = [],
      timeFields = []
    } = options;

    const idParams = idFields.reduce((acc, cur) => {
      return { ...acc, [`${cur}Id`]: data[cur]?.id };
    }, {});

    const dateParams = dateFields.reduce(
      (acc, cur) => ({ ...acc, [cur]: Prototype.date.formatDB(data[cur]) }),
      {}
    );

    const dateTimeParams = dateTimeFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur] ? Prototype.date.toMoment(data[cur]) : null
      }),
      {}
    );

    const timeParams = timeFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur] ? Prototype.date.formatT(data[cur]) : null
      }),
      {}
    );

    const mParams = {
      ...omit(data, [
        ...idFields,
        ...dateFields,
        ...dateTimeFields,
        ...timeFields
      ]),
      ...idParams,
      ...dateParams,
      ...dateTimeParams,
      ...timeParams
    };

    return mParams;
  };
}
