import React, { memo } from 'react';
import { KButton, KButtonProps, KContainer, KViewProps } from 'uikit';

import trans from 'translation';

interface IBottomActionsProps extends KViewProps {
  showDelete?: boolean;
  btnProps?: KButtonProps[];
}

const BottomActions = (props: IBottomActionsProps) => {
  const { showDelete, btnProps = [], ...rest } = props;

  if (btnProps.length === 2) {
    const deleteProps = btnProps[0];
    const saveProps = btnProps[1];

    return (
      <KContainer.View
        row
        alignItems
        justifyContent="flex-end"
        marginT="0.75rem"
        {...rest}
      >
        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={showDelete}>
            <KButton.Solid
              icon="DeleteOutlined"
              kind="secondary"
              marginR="0.5rem"
              title={trans('delete')}
              {...deleteProps}
              disabled={deleteProps?.disabled || saveProps?.isLoading}
            />
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>

        <KButton.Solid
          icon="Save"
          type="submit"
          title={trans('save')}
          {...saveProps}
          disabled={saveProps?.disabled || deleteProps?.isLoading}
        />
      </KContainer.View>
    );
  }

  if (btnProps.length === 1) {
    return (
      <KContainer.View
        row
        alignItems
        justifyContent="flex-end"
        marginT="0.75rem"
        {...rest}
      >
        <KButton.Solid
          icon="Save"
          type="submit"
          title={trans('save')}
          {...btnProps?.[0]}
        />
      </KContainer.View>
    );
  }

  return null;
};

export default memo(BottomActions);
