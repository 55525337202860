export enum WebTable {
  OTHER = 'OTHER', // Dùng cho trường hợp không có table cần refetch

  ECUS_DATA = 'ECUS_DATA',

  // LOCAL
  LOCAL_PAYMENT_ADVICE_INVOICE = 'LOCAL_PAYMENT_ADVICE_INVOICE',
  LOCAL_RECEIVABLE_ADVICE_INVOICE = 'LOCAL_RECEIVABLE_ADVICE_INVOICE',

  LOCAL_CONSOL_SELLING_WIP = 'LOCAL_CONSOL_SELLING_WIP',
  LOCAL_SHIPMENT_CONSOL_SELLING_WIP = 'LOCAL_SHIPMENT_CONSOL_SELLING_WIP',
  LOCAL_SHIPMENT_SELLING_WIP = 'LOCAL_SHIPMENT_SELLING_WIP',
  LOCAL_SERVICE_SELLING_WIP = 'LOCAL_SERVICE_SELLING_WIP',

  LOCAL_CONSOL_COSTING_WIP = 'LOCAL_CONSOL_COSTING_WIP',
  LOCAL_SHIPMENT_CONSOL_COSTING_WIP = 'LOCAL_SHIPMENT_CONSOL_COSTING_WIP',
  LOCAL_SHIPMENT_COSTING_WIP = 'LOCAL_SHIPMENT_COSTING_WIP',
  LOCAL_SERVICE_COSTING_WIP = 'LOCAL_SERVICE_COSTING_WIP',

  LOCAL_SALES_COSTING_RATE = 'LOCAL_SALES_COSTING_RATE',
  LOCAL_SALES_SELLING_RATE = 'LOCAL_SALES_SELLING_RATE',

  PROFILE_BRANCH = 'MyProfile-Branch',

  ROLE_PERMISSION = 'Administration-RolePermission',

  STAFF = 'Administration-Staff',
  STAFF_BRANCH = 'Administration-Staff-Branch',
  STAFF_ROLE = 'Administration-Staff-RolePermission-Role',
  STAFF_PERMISSION = 'Administration-Staff-RolePermission-Permission',
  STAFF_ALERT = 'Administration-Staff-AlertNote-Alert',
  STAFF_NOTE = 'Administration-Staff-AlertNote-Note',
  STAFF_EDOC = 'Administration-Staff-Document-Edoc',

  CLIENT = 'Client',
  CLIENT_ADDRESS = 'Client-Address',
  CLIENT_ADDRESS_CONTACT = 'Client-Address-Contact',
  CLIENT_AR_BANK_ACCOUNT = 'Client-AR-BankAccount',
  CLIENT_AP_BANK_ACCOUNT = 'Client-AP-BankAccount',
  CLIENT_AR_CURRENCY_UPLIFT = 'Client-AR-CurrencyUplift',
  CLIENT_ALERT = 'Client-AlertNote-Alert',
  CLIENT_NOTE = 'Client-AlertNote-Note',
  CLIENT_EMAIL = 'Client-Document-Email',
  CLIENT_EDOC = 'Client-Document-Edoc',
  CLIENT_SALE_PERSON = 'Client-SalePerson',
  CLIENT_STAFF_BRANCH = 'Client-SalePerson-StaffBranch',
  CLIENT_SALE_COMMISSION = 'Client-SalePerson-SaleCommission',
  CLIENT_RELATED_PARTIES = 'Client-RelatedParties',

  CLIENT_TYPE = 'SystemSettings-Library-Client-Type',
  CLIENT_STAGE = 'SystemSettings-Library-Client-Stage',
  CLIENT_CATEGORY = 'SystemSettings-Library-Client-Category',

  VESSEL = 'SystemSettings-Library-Freight-Vessel',
  AIRLINE = 'SystemSettings-Library-Freight-Airline',
  FLIGHT = 'SystemSettings-Library-Freight-Flight',

  INCOTERM = 'SystemSettings-Library-Freight-Incoterm',

  HANDlING_TYPE = 'SystemSettings-Library-Service-Handling-Type',
  DELIVERY_MODE = 'SystemSettings-Library-Service-Delivery-Mode',

  CONTAINER_TYPE_SIZE = 'SystemSettings-Library-Freight-ContainerTypeSize',
  CONTAINER_DANGEROUS_PACKING_GROUPS = 'SystemSettings-Library-Freight-ContainerDangerousPackingGroups',
  CONTAINER_DANGEROUS_CLASS = 'SystemSettings-Library-Freight-ContainerDangerousClass',

  PACKAGE_TYPE = 'SystemSettings-Library-Freight-PackageType',

  PAYMENT_MODE = 'SystemSettings-Library-Freight-Other-PaymentMode',
  FREIGHT_PAYMENT = 'SystemSettings-Library-Freight-Other-FreightPayment',
  BILL_LADING_RELEASED_TYPE = 'SystemSettings-Library-Freight-Other-BillLadingReleased',
  LEG_ROUTING_TYPE = 'SystemSettings-Library-Freight-Other-LegRouting',
  FREIGHT_OTHER_PENALTY = 'SystemSettings-Library-Freight-Other-Penalty',
  CHARGE_CODE_UNIT = 'SystemSettings-Library-Freight-Unit-ChargeCodeUnit',

  SHIPMENT_TYPE = 'SystemSettings-Library-Freight-ShipmentType',
  SERVICE_LEVEL = 'SystemSettings-Library-Freight-ServiceLevel',

  CARRIER_POLICY = 'SystemSettings-Library-Freight-CarrierPolicy',

  JOB_TITLE = 'SystemSettings-Library-Administration-JobTitle',
  DEPARTMENT = 'SystemSettings-Library-Administration-Department',
  ADDRESS_CAPABILITY = 'SystemSettings-Library-Administration-AddressCapability',

  AP_INVOICE_STATUS = 'SystemSettings-Library-Accounting-APInvoiceStatus',
  AR_INVOICE_STATUS = 'SystemSettings-Library-Accounting-ARInvoiceStatus',
  INVOICE_PAYMENT_MODE = 'SystemSettings-Library-Accounting-InvoicePaymentMode',
  AP_INVOICE = 'SystemSettings-Library-Accounting-ApInvoice',
  AR_INVOICE = 'SystemSettings-Library-Accounting-Receivable',

  TAX_TYPE = 'SystemSettings-Library-Accounting-TaxType',
  TAX_EXEMPT_CODE = 'SystemSettings-Library-Accounting-TaxExemptCode',

  CHARGE_CODE_CATEGORY = 'SystemSettings-Library-Accounting-ChargeCodeCategory',
  CHARGE_CODE_SUB_CATEGORY = 'SystemSettings-Library-Accounting-ChargeCodeSubCategory',
  CHARGE_CODE = 'SystemSettings-Library-Accounting-ChargeCode',

  NOTE_TYPE = 'SystemSettings-Library-Other-NoteType',
  EDOC_TYPE = 'SystemSettings-Library-Other-EdocType',
  TERMS_CATEGORY = 'SystemSettings-Library-Other-Terms-Category',
  TERMS_OF_CONDITION = 'SystemSettings-Setting-Terms-Of-Condition',

  ECUS_CLIENT_TYPE = 'SystemSettings-Library-Ecus-ClientType',

  QUOTATION_TEMPLATE = 'SystemSettings-Library-Sales-Quotation-Template',

  INVOICE_AR_TEMPLATE = 'SystemSettings-Library-Invoice-Ar-Template',
  INVOICE_AP_TEMPLATE = 'SystemSettings-Library-Invoice-Ap-Template',

  CONSOL = 'OceanFreight-Consolidation',
  CONSOL_SHIPMENT = 'OceanFreight-Consolidation-Shipment',
  CONSOL_ROUTING = 'OceanFreight-Consolidation-Routing',
  CONSOL_CONTAINER = 'OceanFreight-Consolidation-Container',
  CONSOL_CONTAINER_WITHOUT_NUMBER = 'OceanFreight-Consolidation-ContainerWithoutNumber',
  CONTAINER_IN_SHIPMENT = 'OceanFreight-Consolidation-ContainerInShipment',
  CONSOL_ALERT = 'OceanFreight-Consolidation-AlertNote-Alert',
  CONSOL_NOTE = 'OceanFreight-Consolidation-AlertNote-Note',
  CONSOL_SENDING_AGENT_NOTE = 'OceanFreight-Consolidation-AlertNote-SendingAgentNote',
  CONSOL_RECEIVING_AGENT_NOTE = 'OceanFreight-Consolidation-AlertNote-ReceivingAgentNote',
  CONSOL_SHIPPING_LINE_NOTE = 'OceanFreight-Consolidation-AlertNote-ShippingLineNote',
  CONSOL_CO_LOADER_NOTE = 'OceanFreight-Consolidation-AlertNote-CoLoaderNote',
  CONSOL_EMAIL = 'OceanFreight-Consolidation-Document-Email',
  CONSOL_EDOC = 'OceanFreight-Consolidation-Document-Edoc',

  SHIPMENT_ROUTING = 'OceanFreight-Consolidation-Shipment-Routing',
  ATTACH_TO_CONSOLIDATION = 'OceanFreight-Consolidation-AttachToConsolidation',

  SHIPMENT_CONTAINER = 'OceanFreight-Consolidation-Shipment-Container',
  SHIPMENT_ALERT = 'OceanFreight-Consolidation-Shipment-AlertNote-Alert',
  SHIPMENT_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-Note',
  SHIPMENT_BOOKING_CLIENT_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-BookingClientNote',
  SHIPMENT_CONSIGNOR_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-ConsignorNote',
  SHIPMENT_CONSIGNEE_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-ConsigneeNote',
  SHIPMENT_ACCOUNT_RECEIVABLE_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-AccountReceivableNote',
  SHIPMENT_RELEASED_AGENT_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-ReleasedAgentNote',
  SHIPMENT_CUSTOMS_BROKER_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-CustomsBrokerNote',
  SHIPMENT_NOTIFY_PARTY_NOTE = 'OceanFreight-Consolidation-Shipment-AlertNote-NotifyPartyNote',
  SHIPMENT_EMAIL = 'OceanFreight-Consolidation-Shipment-Document-Email',
  SHIPMENT_EDOC = 'OceanFreight-Consolidation-Shipment-Document-Edoc',

  CONTAINER_PACKAGE_LIST = 'OceanFreight-Consolidation-Container-Package-List',
  CONTAINER_PENALTY_LIST = 'OceanFreight-Consolidation-Container-Penalty-List',

  EXCHANGE_RATE = 'Exchange-Rate',
  COMPANY_BRANCH = 'Company-Branch',
  BRANCH_CONTACT = 'Branch-Contact',
  EXCHANGE_RATE_IMPORT = 'Library-ExchangeRate-Import',
  COMPANY_BANK_ACCOUNT = 'Company-Bank-Account',

  AP_CHARGE_CODE_LIST = 'AP_Charge-Code-List',
  AR_CHARGE_CODE_LIST = 'AR_Charge-Code-List',
  WIP = 'OceanFreight-Consolidation-Wip',
  WIP_IMPORT = 'Wip-Import',
  CHARGE_CODE_LIST = 'Charge-Code-List',
  ADD_AP_CHARGE_CODE = 'Add-Ap-Charge-Code',

  BILLING_AP_INVOICE = 'Shipment-Billing-AP',
  BILLING_AR_INVOICE = 'Shipment-Billing-AR',
  BILLING_AR_CHARGE_CODE = 'Shipment-Billing-Ar-Charge-Code',
  BILLING_WIP = 'Shipment-Billing-Wip',
  BILLING_WIP_IMPORT = 'Billing-Wip-Import',
  BILLING_AP_CHARGE_CODE_INVOICE = 'Shipment-Billing-Ap-Charge-Code-Invoice',
  BILLING_ADD_AP_CHARGE_CODE = 'Shipment-Billing-Add-Ap-Charge-Code',
  BILLING_ADD_AR_CHARGE_CODE = 'Shipment-Billing-Add-Ar-Charge-Code',
  BILLING_CHARGE_CODE_LIST = 'Billing-Charge-Code-List',

  // BILLING_AP_INVOICE_NO_CONSOL = 'Shipment-Billing-AP-With-Out-Consol',
  // BILLING_AR_INVOICE_NO_CONSOL = 'Shipment-Billing-AR-With-Out-Consol',
  // BILLING_AR_CHARGE_CODE_NO_CONSOL = 'Shipment-Billing-Ar-Charge-Code-With-Out-Consol',
  // BILLING_WIP_NO_CONSOL = 'Shipment-Billing-Wip-With-Out-Consol',
  // BILLING_WIP_IMPORT_NO_CONSOL = 'Billing-Wip-Import-No-Consol',
  // BILLING_AP_CHARGE_CODE_NO_CONSOL = 'Shipment-Billing-Ap-Charge-Code-Invoice-With-Out-Consol',
  // BILLING_ADD_AP_CHARGE_CODE_NO_CONSOL = 'Shipment-Billing-Add-Ap-Charge-Code-With-Out-Consol',
  // BILLING_CHARGE_CODE_LIST_NO_CONSOL = 'Billing-Charge-Code-List-With-Out-Consol',

  SERVICE_TRANSPORT_CONTAINER = 'Service-Transport-Container',

  ORIGIN_TRANSPORT = 'OceanFreight-Consolidation-Transport-Origin',
  DESTINATION_TRANSPORT = 'OceanFreight-Consolidation-Transport-Transport',

  SERVICE = 'Service',
  SERVICE_TRANSPORT = 'Service-Transport',
  SERVICE_TRANSPORT_SERVICE = 'Service-Transport-Service',

  SERVICE_CONTAINER = 'Service-Container',
  SERVICE_CONTAINER_PACKAGE = 'Service-Container-Package',
  SERVICE_CONTAINER_PENALTY = 'Service-Container-Penalty',

  SERVICE_HANDLING = 'Service-Handling',
  SERVICE_HANDLING_SERVICE = 'Service-Handling-Service',

  SERVICE_CUSTOMS = 'Service-Customs',
  SERVICE_CUSTOMS_COMMERCIAL_INVOICE = 'Service-Customs-Commercial-Invoice',
  SERVICE_CUSTOMS_COMMERCIAL_INVOICE_LINES = 'Service-Customs-Commercial-Invoice-Lines',
  SERVICE_NOTE = 'OceanFreight-Consolidation-Service-AlertNote-Note',
  SERVICE_CUSTOMS_DECLARATION_SHEET = 'Service-Customs-Declaration-Sheet',

  AR_CHARGE_CODE_SUMMARY = 'Ar-ChargeCode-Summary',

  BANKING_BANK_ACCOUNT = 'Banking-BankAccount',
  BANKING_BANK_TRANSACTION = 'Banking-Bank-Transaction',
  BANKING_BANK_MATCHED = 'Banking-Bank-Matched',
  BANKING_BANK_MATCHED_RECONCILE = 'Banking-Bank-Matched-Reconcile',
  BANKING_SELECTED_ADD = 'Accounting-Banking-Bank-Selected-Add',
  ACCOUNTING_INVOICE = 'Accounting-Invoice',
  ACCOUNTING_ADD_INVOICE = 'Accounting-Add_Invoice',
  ACCOUNTING_INVOICE_CLIENT = 'Accounting-Invoice_Client',
  ACCOUNTING_BANKING_RECONCILE = 'Accounting-Banking-Reconcile',
  ACCOUNTING_STATEMENT_PAYMENT = 'Accounting-Statement-Payment',
  BANK_TRANSACTION = 'Bank-Transaction',
  // Accounting
  ACCOUNTING_RECEIVABLE_ADVICE = 'Accounting-Receivable-Advice',
  BANKING_SELECTED_TRANSACTION = 'Banking-Selected-Transaction',
  ACCOUNTING_PAYMENT_ADVICE = 'Accounting-Payment-Advice',
  CHART_OF_ACCOUNTS = 'More-Chart-Of-Account',
  CLASSIFICATION = 'More-Classification',

  NOTES_TO_CLIENT = 'Notes-To-Client',

  CONSOL_AR_INVOICES = 'Consol-AR-Invoices',
  CONSOL_AP_INVOICES = 'Consol-AP-Invoices',

  SHIPMENT_AR_INVOICES = 'Shipment-AR-Invoices',
  SHIPMENT_AP_INVOICES = 'Shipment-AP-Invoices',

  SERVICE_AR_INVOICES = 'Service-AR-Invoices',
  SERVICE_AP_INVOICES = 'Service-AP-Invoices',

  ACCOUNTING_AR_INVOICES = 'Accounting-AR-Invoices',
  ACCOUNTING_AP_INVOICES = 'Accounting-AP-Invoices',

  CONSOL_WIP = 'Consol-WIP',
  SHIPMENT_WIP = 'Shipment-WIP',
  SERVICE_WIP = 'Service-WIP',

  CONSOL_CHARGE_CODE_LIST = 'CONSOL_CHARGE_CODE_LIST',
  SHIPMENT_CHARGE_CODE_LIST = 'SHIPMENT_CHARGE_CODE_LIST',
  SERVICE_CHARGE_CODE_LIST = 'SERVICE_CHARGE_CODE_LIST',

  STATEMENT_OF_ACCOUNT = 'STATEMENT_OF_ACCOUNT',
  STATEMENT_INVOICE_CHARGE_CODE = 'STATEMENT_INVOICE_CHARGE_CODE',
  STATEMENT_PAYMENT = 'STATEMENT_PAYMENT',
  SPEND_MONEY = 'SPEND_MONEY',
  RECEIVE_MONEY = 'RECEIVE_MONEY',

  // help and support
  ISSUE = 'ISSUE',
  FAQ = 'FAQ',

  BANKING_PAYMENT = 'Banking-Payment',
  JOB_ORDER = 'Job-Order'
}

export enum TableName {
  CLIENT = 'client',
  CLIENT_ADDRESS = 'client_address',
  CLIENT_ADDRESS_CONTACT = 'client_address_contact',
  BANK_ACCOUNT = 'bank_account',
  CURRENCY_UPLIFT = 'currency_uplift',

  NOTE = 'note',
  EDOC = 'edoc',
  ALERT = 'alert',
  EMAIL = 'email',

  ROLE = 'role',

  STAFF_BRANCHES = 'staff_in_branch',
  STAFF_ROLE = 'staff_has_role_in_branch',
  STAFF_PERMISSION = 'staff_has_permission_in_branch',
  STAFF = 'staff',

  CLIENT_TYPE = 'client_type',
  CLIENT_CATEGORY = 'client_category',
  CLIENT_STAGE = 'client_stage',

  VESSEL = 'vessel',
  AIRLINE = 'airline',
  FLIGHT = 'flight',

  INCOTERM = 'incoterm',

  HANDLING_TYPE = 'handling_type',
  DELIVERY_MODE = 'delivery_mode',

  CONTAINER_TYPE_SIZE = 'container_type_size',
  CONTAINER_DANGEROUS_PACKING_GROUPS = 'container_dangerous_packing_groups',
  CONTAINER_DANGEROUS_CLASS = 'container_dangerous_class',

  PACKAGE_TYPE = 'package_type',

  PAYMENT_MODE = 'payment_mode',
  FREIGHT_PAYMENT = 'freight_payment',
  BILL_LADING_RELEASED_TYPE = 'bl_released_type',
  LEG_ROUTING_TYPE = 'leg_routing_type',
  PENALTY_TYPE = 'penalty_type',
  CHARGE_CODE_UNIT = 'charge_code_unit',

  SHIPMENT_TYPE = 'shipment_type',
  SERVICE_LEVEL = 'service_level',

  CARRIER_POLICY = 'carrier_policy',

  JOB_TITLE = 'job_title',
  DEPARTMENT = 'department',
  ADDRESS_CAPABILITY = 'branch_address_capability',

  AP_INVOICE_STATUS = 'ap_invoice_status',
  AR_INVOICE_STATUS = 'ar_invoice_status',
  INVOICE_PAYMENT_MODE = 'invoice_payment_mode',
  AP_INVOICE = 'ap_invoice',
  AR_INVOICE = 'ar_invoice',
  INVOICE_TEMPLATE = 'invoice_template',

  TAX_TYPE = 'tax_type',
  TAX_EXEMPT_CODE = 'tax_exempt_code',

  CHARGE_CODE_CATEGORY = 'charge_code_category',
  CHARGE_CODE_SUB_CATEGORY = 'charge_code_sub_category',
  CHARGE_CODE = 'charge_code',

  NOTE_TYPE = 'note_type',
  EDOC_TYPE = 'edoc_type',
  TERMS_CATEGORY = 'terms_category',
  TERMS_OF_CONDITION = 'terms_of_conditions',

  ECUS_CLIENT_TYPE = 'ecus_client_type',

  QUOTATION_TEMPLATE = 'quotation_template',

  CLIENT_SALE_PERSON = 'client_sale_person',
  CLIENT_SALE_COMMISSION = 'client_sale_commission',

  RELATED_PARTY_CLIENT = 'related_party_client',

  CONSOL = 'consol',
  CONSOL_ROUTING = 'consol_routing',
  SHIPMENT = 'shipment',
  SHIPMENT_ROUTING = 'shipment_routing',
  ATTACH_TO_CONSOLIDATION = 'attach_to_consolidation',

  CONTAINER = 'container',
  CONTAINER_PACKAGE = 'container_package',
  CONTAINER_PENALTY = 'container_penalty',

  EXCHANGE_RATE = 'exchange_rate',

  BRANCH = 'branch',
  BRANCH_CONTACT = 'branch_contact',
  BRANCH_ACCOUNT = 'branch_account',
  COMPANY_BANK_ACCOUNT = 'company_bank_account',

  AP_CHARGE_CODE_LIST = 'ap_charge_code',
  AR_CHARGE_CODE_LIST = 'ar_charge_code',
  WIP = 'wip',
  CONSOL_CHARGE_CODE = 'consol_charge_code',
  BILLING_AP_CHARGE_CODE_INVOICE = 'billing_ap_charge_code_invoice',
  BILLING_AP_CHARGE_CODE_LIST = 'billing_ap_charge_code',

  CONSOL_TRANSPORT = 'consol_transport',
  SERVICE = 'service',
  SERVICE_TRANSPORT = 'service_transport',
  SERVICE_TRANSPORT_SERVICE = 'transport_service',
  SERVICE_TRANSPORT_CONTAINER = 'service_transport_container',
  SERVICE_CUSTOMS_COMMERCIAL_INVOICE = 'commercial_invoice',

  SERVICE_CONTAINER = 'service_container',
  SERVICE_CONTAINER_PACKAGE = 'container_service_package',
  SERVICE_CONTAINER_DATE = 'service_container_date',
  SERVICE_CONTAINER_PENALTY = 'service_container_penalty',
  SERVICE_CUSTOMS_DECLARATION_SHEET = 'custom_declaration_sheet',
  HANDLING_SERVICE = 'handling_service',

  AR_CHARGE_CODE_SUMMARY = 'ar_charge_code_summary',
  // CONTAINER_PENALTY = 'container_penalty',

  BANKING_BANK_ACCOUNT = 'accounting_company_bank_account',
  BANK_TRANSACTION = 'bank_transaction',
  BANK_RECONCILE = 'bank_reconcile',
  ACCOUNTING_INVOICE_AR = 'ar_invoice_global',
  ACCOUNTING_INVOICE_AP = 'ap_invoice_global',
  ACCOUNTING_ADD_INVOICE = 'Accounting-Add_Invoice',
  ACCOUNTING_INVOICE_CLIENT_RECEIVABLE = 'ar_invoice_account_receivable',
  ACCOUNTING_INVOICE_CLIENT_PAYABLE = 'ap_invoice_account_payable',
  ACCOUNTING_BANKING_RECONCILE = 'accounting_banking_reconcile',
  ACCOUNTING_RECEIVABLE_ADVICE = 'receivable_advice',
  ACCOUNTING_RECEIVABLE_ADVICE_DETAIL = 'receivable_advice_detail',
  ACCOUNTING_PAYMENT_ADVICE = 'payment_advice',
  ACCOUNTING_PAYMENT_ADVICE_DETAIL = 'payment_advice_detail',
  ACCOUNTING_STATEMENT_PAYMENT = 'statement_payment',
  STATEMENT_INVOICE = 'statement_invoice',
  CHART_OF_ACCOUNTS = 'chart_of_account',
  CLASSIFICATION = 'chart_of_account_classification',
  STATEMENT_OF_ACCOUNT = 'statement_of_account',

  //help and support
  ISSUE = '',
  FAQ = '',
  BANKING_PAYMENT_SPEND = 'banking_payment_spend',
  BANKING_PAYMENT_RECEIVE = 'banking_payment_receive',

  JOB_ORDER = 'job_order'
}

export const OperandTypes = {
  STRING: 'STRING',
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  BOOLEAN: 'BOOLEAN',
  REF_ID: 'REF_ID'
};

export const Operators = {
  CONTAIN: 'CONTAIN',
  START_WITH: 'START_WITH',
  END_WITH: 'END_WITH',
  EQUAL: 'EQUAL',
  GREATER_EQUAL: 'GREATER_EQUAL',
  LESS_EQUAL: 'LESS_EQUAL',
  BETWEEN: 'BETWEEN',
  IN: 'IN'
};

export const StringOperators = [
  {
    id: Operators.CONTAIN,
    name: 'advanced_search.includes'
  },
  {
    id: Operators.START_WITH,
    name: 'advanced_search.start_with'
  },
  {
    id: Operators.END_WITH,
    name: 'advanced_search.end_with'
  }
];

export const NumberOperators = [
  {
    id: Operators.EQUAL,
    name: 'advanced_search.equals'
  },
  {
    id: Operators.GREATER_EQUAL,
    name: 'advanced_search.greater_equal'
  },
  {
    id: Operators.LESS_EQUAL,
    name: 'advanced_search.less_equal'
  },
  {
    id: Operators.BETWEEN,
    name: 'advanced_search.between'
  }
];

export const DateOperators = [
  {
    id: Operators.EQUAL,
    name: 'advanced_search.equals'
  },
  {
    id: Operators.GREATER_EQUAL,
    name: 'advanced_search.greater_equal'
  },
  {
    id: Operators.LESS_EQUAL,
    name: 'advanced_search.less_equal'
  },
  {
    id: Operators.BETWEEN,
    name: 'advanced_search.between'
  }
];

export const BooleanOperators = [
  {
    id: Operators.EQUAL,
    name: 'advanced_search.equals'
  }
];

export const RefOperators = [
  {
    id: Operators.IN,
    name: 'advanced_search.in'
  }
];

export const OperatorData = {
  [OperandTypes.STRING]: StringOperators,
  [OperandTypes.INTEGER]: NumberOperators,
  [OperandTypes.DECIMAL]: NumberOperators,
  [OperandTypes.DATE]: DateOperators,
  [OperandTypes.TIME]: DateOperators,
  [OperandTypes.DATETIME]: DateOperators,
  [OperandTypes.BOOLEAN]: BooleanOperators,
  [OperandTypes.REF_ID]: RefOperators
};
