import { IUser } from '@dto';
import { createModel } from '@rematch/core';

import { RootModel } from '.';

interface UserState {
  user?: IUser;
}

const init: UserState = {
  user: undefined
};

export const user = createModel<RootModel>()({
  state: init, // initial state
  reducers: {
    setUser: (state: UserState, data?: IUser) => {
      return {
        ...state,
        user: data
      };
    }
  },
  effects: dispatch => ({
    // eslint-disable-next-line require-await
    async getProfile(payload: any): Promise<boolean> {
      console.log(payload);
      dispatch.user.setUser(undefined);
      return true;
    }
  })
});
