import {
  ArApType,
  ENDPOINTS,
  InvoiceCategory,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchApInvoice2 = (
  category: InvoiceCategory,
  id?: number | string,
  options?: {
    consolId?: number | string;
    shipmentId?: number | string;
    serviceId?: number | string;
  }
) => {
  const { consolId, shipmentId, serviceId } = options || {};

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.apInvoice(category, ':id', {
          id
        }),
        body: options
      });

      return res.data;
    },
    enabled: (!!consolId || !!shipmentId || !!serviceId) && !!id
  });
};

export const useFetchArInvoice2 = (
  category: InvoiceCategory,
  id?: number | string,
  options?: {
    consolId?: number | string;
    shipmentId?: number | string;
    serviceId?: number | string;
  }
) => {
  const { consolId, shipmentId, serviceId } = options || {};

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.arInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(category, ':id', {
          id
        }),
        body: options
      });

      return res.data;
    },
    enabled: (!!consolId || !!shipmentId || !!serviceId) && !!id
  });
};

export const useCUDArInvoice2 = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ar
    }),
    webTable: WebTable.CONSOL_AR_INVOICES,
    queryKey: QUERY_KEYS.arInvoice,
    dependentWebTable: [
      WebTable.SHIPMENT_AR_INVOICES,
      WebTable.SERVICE_AR_INVOICES
    ]
  });
};

export const useCUDApInvoice2 = (category: InvoiceCategory) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accounting({
      category,
      type: ArApType.Ap
    }),
    webTable: WebTable.CONSOL_AP_INVOICES,
    queryKey: QUERY_KEYS.apInvoice,
    dependentWebTable: [
      WebTable.SHIPMENT_AP_INVOICES,
      WebTable.SERVICE_AP_INVOICES
    ]
  });
};
