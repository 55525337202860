import './App.css';
import loadable from '@loadable/component';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { compact } from 'lodash';
import pMinDelay from 'p-min-delay';
import React, { Suspense, memo, useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { EventName, alertRef, popperRef, popupRef } from '@constants';
import theme from 'assets/theme';
import Portal from 'containers/Portal';
import OceanFreightProvider from 'context/OceanFreight';
import AppEvent from 'core/event';
import { useSignOut, useUser } from 'hooks';
import { useAdminRouter } from 'hooks/core/helpers';
import Admin from 'layouts/Admin';
import NotificationManager from 'notification';
import adminRoutes from 'routes';
import { useMount } from 'uikit';
import { GlobalDebug, overrideConsole } from 'utils/removeConsoles';

const SignIn = loadable(() => pMinDelay(import('pages/Auth/SignIn'), 200));
const Profile = loadable(() => pMinDelay(import('pages/Profile'), 200));
const Company = loadable(() => pMinDelay(import('pages/Company'), 200));
// const NotFound = loadable(() => pMinDelay(import('pages/404'), 200));

const App = () => {
  const signOut = useSignOut();
  const { convertAdminRouter } = useAdminRouter();

  const { isAdmin } = useUser() as any;
  const { user } = useUser();

  useMount(() => {
    // Init OneSignal
    const notification = new NotificationManager(user);
    notification.initialize();

    overrideConsole();

    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'staging'
    ) {
      GlobalDebug(false);
    }

    AppEvent.subscribe(EventName.LOGOUT, () => {
      signOut.mutate();
    });
  });

  const newAdminRouter = useMemo(() => {
    return convertAdminRouter(adminRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertAdminRouter(adminRoutes)?.toString()]);

  const routes = useRoutes([
    {
      path: 'admin',
      element: <Admin />,
      children: compact([
        ...((isAdmin
          ? newAdminRouter
          : newAdminRouter.filter(
              i => i.name !== 'Administration' && i.name !== 'System Settings'
            )) as RouteObject[]),
        { path: 'profile', element: <Profile /> },
        isAdmin ? { path: 'company', element: <Company /> } : undefined
      ])
    },
    { path: '/', element: <Navigate to="admin" /> },
    {
      path: 'auth',
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        }
        // {
        //   path: 'sign-up',
        //   element: <Dashboard />
        // }
      ]
    },
    { path: '*', element: <Navigate to="admin" /> }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <OceanFreightProvider>
        <Suspense fallback={null}>{routes}</Suspense>

        <Portal.Alert ref={alertRef} />
        <Portal.Popper ref={popperRef} />
        <Portal.Popup ref={popupRef} />
      </OceanFreightProvider>
    </ThemeProvider>
  );
};

export default memo(App);
