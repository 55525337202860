import { IUseAgTableResult, UseDataTableResult } from '@hooks-dto';
import { set, unset } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { agTableRef, tableRef } from '@constants';
import { useMount } from 'uikit';

export const useGlobalTable = (
  table: UseDataTableResult,
  unmount: boolean = true
) => {
  useMount(() => {
    return () => {
      if (unmount) {
        unset(tableRef, table.name);
      }
    };
  });

  useEffect(() => {
    set(tableRef, table.name, table);
  }, [table, unmount]);
};

export const useFirstNavigate = (
  firstTab: { path: string },
  isFirstNavigate: boolean
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (firstTab && isFirstNavigate) {
      navigate(firstTab.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTab.path, isFirstNavigate]);
};

export const useAgGlobalTable = (
  table: IUseAgTableResult<any>,
  unmount: boolean = true
) => {
  useMount(() => {
    return () => {
      if (unmount) {
        unset(agTableRef, table.name);
      }
    };
  });

  useEffect(() => {
    set(agTableRef, table.name, table);
  }, [table, unmount]);
};
