import moment from 'moment-timezone';
import * as yup from 'yup';

import {
  DATE_FORMAT_SERVER,
  DATE_FORMAT_SHORT_TIMEZONE,
  DATE_FORMAT_TIMEZONE
} from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

export default class ValidationUtils {
  static readonly requiredAny = () => yup.mixed().nullable().required();
  static readonly required = () => yup.string().nullable().required();
  static readonly requiredDate = () => yup.date().nullable().required();
  static readonly requiredNum = () => yup.number().nullable().required();
  static readonly requiredArray = () => yup.array().required().min(1);

  static readonly nullableAny = () => yup.mixed().nullable();
  static readonly nullableDate = () =>
    yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr));
  static readonly nullableNum = () =>
    yup
      .number()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr));

  static readonly requiredMinDate = (minDate = moment()) =>
    this.requiredDate()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('invalidDate', trans('validation.invalid_date'), function (v) {
        if (v) {
          return Prototype.date.toMoment(v as any)?.isValid();
        }
        return true;
      })
      .min(moment(minDate).format(DATE_FORMAT_SERVER));

  static readonly requiredMinDateTz = (tzRef: string, minDate = moment()) =>
    this.requiredDate()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('invalidDate', trans('validation.invalid_date'), function (v) {
        if (v) {
          return Prototype.date.toMoment(v as any)?.isValid();
        }
        return true;
      })
      .test(
        'minDate',
        trans('validation.min_date', {
          date: moment(minDate).format(DATE_FORMAT_SERVER)
        }),
        function (v) {
          if (v) {
            const offset =
              this.parent[tzRef]?.timezone?.offset?.slice(3) ?? '+00:00';
            const curDateTz = moment(v).format(
              `${DATE_FORMAT_TIMEZONE}:00${offset}`
            );
            const minDateTz = moment(minDate).format(
              `${DATE_FORMAT_SHORT_TIMEZONE}00:00:00${offset}`
            );
            return moment(curDateTz).isSameOrAfter(minDateTz, 'days');
          }
          return true;
        }
      );

  static readonly minDate = (minDate = moment()) =>
    this.nullableDate()
      .test('invalidDate', trans('validation.invalid_date'), function (v) {
        if (v) {
          return Prototype.date.toMoment(v as any)?.isValid();
        }
        return true;
      })
      .min(moment(minDate).format(DATE_FORMAT_SERVER));

  static readonly requiredMinNumber = (min?: number) =>
    this.requiredNum().min(min ?? 0);

  static readonly requiredDisplayPosition = () => {
    return this.requiredMinNumber().test(
      'isEdit',
      trans('validation.min_number', { min: 1 }),
      (v = 0, e) => {
        return e.parent.isEdit ? v > 0 : v >= 0;
      }
    );
  };

  static readonly requiredMinMaxNumber = (min?: number, max?: number) =>
    this.requiredNum()
      .min(min ?? 0)
      .max(max ?? 100);

  static readonly requiredEmail = () => yup.string().required().email();

  static readonly requiredNumberNullAble = (minNumber?: number) =>
    this.requiredMinNumber(minNumber).transform(v => v ?? null);

  static readonly requiredNumberNonNullAble = (minNumber?: number) =>
    this.requiredMinNumber(minNumber)
      .transform(v => v ?? null)
      .nonNullable();

  static readonly requiredCheckBoxAirOcean = (message?: string) =>
    yup
      .boolean()
      .test('isAir', trans(`${message || 'choose_air_ocean'}`), function () {
        return !(this.parent.isAir === false && this.parent.isOcean === false);
      });

  static readonly requiredCheckBoxHouseMaster = (message?: string) =>
    yup
      .boolean()
      .test(
        'isHouse',
        trans(`${message || 'choose_house_master'}`),
        function () {
          return !(
            this.parent.isHouse === false && this.parent.isMaster === false
          );
        }
      );

  static readonly email = () => yup.string().nullable().email();

  static readonly date = (message?: string) =>
    yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test(
        'invalidDate',
        message || trans('validation.invalid_date'),
        function (v) {
          if (v) {
            return Prototype.date.toMoment(v as any)?.isValid();
          }
          return true;
        }
      );
}
