import {
  ENDPOINTS,
  QUERY_KEYS,
  StatementInvoiceType,
  WebTable
} from '@constants';
import APIManager from '@services';
import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from 'hooks/core';

interface IGenerateProps {
  statementOfAccountId: number | string;
  currencyId: number | string;
  clientId: number | string;
  invoiceType: StatementInvoiceType;
  fromIssueDate: any;
  toIssueDate: any;
}

export const useGenerateStatementOfAccount = () => {
  return useMutationEnhancer<any, IGenerateProps>({
    mutationFn: async body => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementOfAccount('generate'),
        body,
        method: 'GET'
      });
      return (res?.data || []).map((o: any) => ({ ...o, checked: false }));
    }
  });
};

export const useCUDStatementOfAccount = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.statementOfAccount(),
    webTable: WebTable.OTHER
  });
};

export const useCUDStatementInvoice = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.statementInvoice(),
    webTable: WebTable.OTHER
  });
};

export const useFetchStatementInvoice = (siId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementInvoice, siId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementInvoice(':siId', { siId })
      });

      return res.data;
    },
    enabled: !!siId
  });
};
