/* eslint-disable max-lines */
import {
  ConsolStatus,
  ContainerStatus,
  EdocStatus,
  ARInvoiceStatus,
  APInvoiceStatus,
  LegRoutingStatus,
  PaymentStatus,
  ShipmentStatus,
  TransportRoutingStatus,
  TransportStatus,
  ReconcileStatus,
  ReconcileAdviceStatus,
  AdviceStatus,
  StatementOfAccountStatusEnum,
  StatementInvoiceStatus,
  SyncStatus,
  SpendReceiveStatus,
  JobOrderStatus,
  PicStatus
} from '@constants';
import trans from 'translation';
import { KColors } from 'uikit';

export const mappedConsolStatusData = () => ({
  [ConsolStatus.Active]: {
    color: '#000',
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.Active.toLowerCase()}`)
  },
  [ConsolStatus.OnBoard]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.OnBoard.toLowerCase()}`)
  },
  [ConsolStatus.Complete]: {
    color: '#105404',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.Complete.toLowerCase()}`)
  },
  [ConsolStatus.Draft]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.Draft.toLowerCase()}`)
  },
  [ConsolStatus.Close]: {
    color: KColors.palette.warning.w200,
    background: KColors.palette.warning.w800,
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.Close.toLowerCase()}`)
  },
  [ConsolStatus.PortOfDischarge]: {
    color: KColors.palette.warning.w700,
    background: KColors.palette.warning.w200,
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.PortOfDischarge.toLowerCase()}`)
  },
  [ConsolStatus.PortOfLoading]: {
    color: KColors.palette.blue.w800,
    background: KColors.palette.blue.w300,
    brC: KColors.transparent,
    label: trans(`option.${ConsolStatus.PortOfLoading.toLowerCase()}`)
  }
});

export const mappedShipmentStatusData = () => ({
  [ShipmentStatus.Active]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.Active.toLowerCase()}`)
  },
  [ShipmentStatus.OriginInTransit]: {
    color: KColors.palette.blue.w500,
    background: KColors.palette.blue.w100,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.OriginInTransit.toLowerCase()}`)
  },
  [ShipmentStatus.InTransit]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.InTransit.toLowerCase()}`)
  },
  [ShipmentStatus.Complete]: {
    color: '#105404',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.Complete.toLowerCase()}`)
  },
  [ShipmentStatus.Draft]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.Draft.toLowerCase()}`)
  },
  [ShipmentStatus.Close]: {
    color: KColors.palette.warning.w200,
    background: KColors.palette.warning.w800,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.Close.toLowerCase()}`)
  },
  [ShipmentStatus.PortOfDischarge]: {
    color: KColors.palette.warning.w700,
    background: KColors.palette.warning.w200,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.PortOfDischarge.toLowerCase()}`)
  },
  [ShipmentStatus.DestinationInTransit]: {
    color: KColors.palette.warning.w800,
    background: KColors.palette.warning.w400,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.DestinationInTransit.toLowerCase()}`)
  },
  [ShipmentStatus.PortOfLoading]: {
    color: KColors.palette.blue.w800,
    background: KColors.palette.blue.w300,
    brC: KColors.transparent,
    label: trans(`option.${ShipmentStatus.PortOfLoading.toLowerCase()}`)
  }
});

export const mappedContainerStatusData = () => ({
  [ContainerStatus.Active]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.Active.toLowerCase()}`)
  },
  [ContainerStatus.OnBoard]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.OnBoard.toLowerCase()}`)
  },
  [ContainerStatus.Complete]: {
    color: '#105404',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.Complete.toLowerCase()}`)
  },
  [ContainerStatus.Draft]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.Draft.toLowerCase()}`)
  },
  [ContainerStatus.Discharge]: {
    color: KColors.palette.warning.w700,
    background: KColors.palette.warning.w200,
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.Discharge.toLowerCase()}`)
  },
  [ContainerStatus.Loading]: {
    color: KColors.palette.blue.w800,
    background: KColors.palette.blue.w300,
    brC: KColors.transparent,
    label: trans(`option.${ContainerStatus.Loading.toLowerCase()}`)
  }
});

export const mappedRoutingStatusData = () => ({
  [LegRoutingStatus.InProgress]: {
    color: KColors.palette.warning.w700,
    background: KColors.palette.warning.w200,
    brC: KColors.transparent,
    label: trans(`option.${LegRoutingStatus.InProgress.toLowerCase()}`)
  },
  [LegRoutingStatus.Pending]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${LegRoutingStatus.Pending.toLowerCase()}`)
  },
  [LegRoutingStatus.Complete]: {
    color: '#105404',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${LegRoutingStatus.Complete.toLowerCase()}`)
  }
});

export const mappedEdocStatusData = () => ({
  [EdocStatus.Active]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Active.toLowerCase()}`)
  },
  [EdocStatus.Inactive]: {
    color: KColors.palette.warning.w700,
    background: KColors.palette.warning.w200,
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Inactive.toLowerCase()}`)
  },
  [EdocStatus.Rejected]: {
    color: KColors.palette.secondary.w800,
    background: KColors.palette.secondary.w200,
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Rejected.toLowerCase()}`)
  },
  [EdocStatus.Confirmed]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Confirmed.toLowerCase()}`)
  },
  [EdocStatus.Draft]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Draft.toLowerCase()}`)
  },
  [EdocStatus.Approved]: {
    color: '#105404',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${EdocStatus.Approved.toLowerCase()}`)
  }
});

export const mappedPaymentStatusData = () => ({
  [PaymentStatus.UNPAID]: {
    color: KColors.black,
    background: '#E8C7B9',
    brC: KColors.transparent,
    label: trans(`option.${PaymentStatus.UNPAID.toLowerCase()}`)
  },
  [PaymentStatus.PARTLY_PAID]: {
    color: KColors.black,
    background: '#5A99F0',
    brC: KColors.transparent,
    label: trans(`option.${PaymentStatus.PARTLY_PAID.toLowerCase()}`)
  },
  [PaymentStatus.PAID]: {
    color: KColors.black,
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${PaymentStatus.PAID.toLowerCase()}`)
  },
  ['OVERDUE']: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans('OVERDUE'.toLowerCase())
  }
});

export const mappedSyncStatus = () => ({
  [SyncStatus.ERROR]: {
    color: KColors.black,
    background: '#E8C7B9',
    brC: KColors.transparent,
    label: trans(`option.${SyncStatus.ERROR.toLowerCase()}`)
  },
  [SyncStatus.SUCCESS]: {
    color: KColors.black,
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${SyncStatus.SUCCESS.toLowerCase()}`)
  }
});

export const mappedInvoiceStatus = () => ({
  [APInvoiceStatus.COMPLETE]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${APInvoiceStatus.COMPLETE.toLowerCase()}`)
  },
  [APInvoiceStatus.PAYMENT_REQUEST]: {
    color: KColors.primary.dark,
    background: KColors.primary.light,
    brC: KColors.transparent,
    label: trans(`option.${APInvoiceStatus.PAYMENT_REQUEST.toLowerCase()}`)
  },
  [ARInvoiceStatus.CONFIRM_REQUEST]: {
    color: KColors.primary.dark,
    background: KColors.primary.light,
    brC: KColors.transparent,
    label: trans(`option.${ARInvoiceStatus.CONFIRM_REQUEST.toLowerCase()}`)
  },
  [ARInvoiceStatus.CONFIRMED]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${ARInvoiceStatus.CONFIRMED.toLowerCase()}`)
  },
  [ARInvoiceStatus.INVOICE_REQUEST]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${ARInvoiceStatus.INVOICE_REQUEST.toLowerCase()}`)
  },
  [ARInvoiceStatus.ISSUED]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${ARInvoiceStatus.ISSUED.toLowerCase()}`)
  },
  [APInvoiceStatus.VERIFIED]: {
    color: KColors.primary.dark,
    background: KColors.primary.light,
    brC: KColors.transparent,
    label: trans(`option.${APInvoiceStatus.VERIFIED.toLowerCase()}`)
  },
  [APInvoiceStatus.APPROVED]: {
    color: KColors.palette.warning.w600,
    background: KColors.palette.warning.w50,
    brC: KColors.palette.warning.w600,
    label: trans(`option.${APInvoiceStatus.APPROVED.toLowerCase()}`)
  },
  [APInvoiceStatus.DRAFT]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${APInvoiceStatus.DRAFT.toLowerCase()}`)
  },
  [APInvoiceStatus.CANCEL]: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans(`option.${APInvoiceStatus.CANCEL.toLowerCase()}`)
  },
  ['OVERDUE']: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans('OVERDUE'.toLowerCase())
  }
});

export const mappedServiceStatus = () => ({
  [TransportStatus.Draft]: {
    color: '#000',
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${TransportStatus.Draft.toLowerCase()}`)
  },
  [TransportStatus.Active]: {
    color: '#000',
    background: '#13A89E',
    brC: KColors.transparent,
    label: trans(`option.${TransportStatus.Active.toLowerCase()}`)
  },
  [TransportStatus.Complete]: {
    color: '#000',
    background: '#61AAD1',
    brC: KColors.transparent,
    label: trans(`option.${TransportStatus.Complete.toLowerCase()}`)
  },
  [TransportStatus.Close]: {
    color: '#000',
    background: '#E08700',
    brC: KColors.transparent,
    label: trans(`option.${TransportStatus.Close.toLowerCase()}`)
  }
});

export const mappedTransportRoutingStatus = () => ({
  [TransportRoutingStatus.PEN]: {
    color: '#000',
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${TransportRoutingStatus.PEN.toLowerCase()}`)
  },
  [TransportRoutingStatus.COM]: {
    color: '#000',
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${TransportRoutingStatus.COM.toLowerCase()}`)
  },
  [TransportRoutingStatus.PRO]: {
    color: '#000',
    background: '#2889FC',
    brC: KColors.transparent,
    label: trans(`option.${TransportRoutingStatus.PRO.toLowerCase()}`)
  }
});

export const mappedBankingReconcileStatus = () => ({
  [ReconcileStatus.DONE]: {
    color: KColors.black,
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${ReconcileStatus.DONE.toLowerCase()}`)
  },
  [ReconcileStatus.UNDONE]: {
    color: KColors.black,
    background: '#E8C7B9',
    brC: KColors.transparent,
    label: trans(`option.${ReconcileStatus.UNDONE.toLowerCase()}`)
  }
});

export const mappedPaymentAdviceStatus = (isReconcileStatus: boolean) =>
  isReconcileStatus
    ? {
        color: ' #3995C5',
        background: '#D7EAF4',
        brC: KColors.transparent,
        label: trans(`option.${ReconcileAdviceStatus.RECONCILED.toLowerCase()}`)
      }
    : {
        color: '#F44336',
        background: '#FDDBD8',
        brC: KColors.transparent,
        label: trans(
          `option.${ReconcileAdviceStatus.UNRECONCILED.toLowerCase()}`
        )
      };

export const mappedAdviceStatus = () => ({
  [AdviceStatus.Pending]: {
    color: KColors.palette.gray.w700,
    background: KColors.palette.gray.w25,
    brC: KColors.transparent,
    label: trans(`option.${AdviceStatus.Pending.toLowerCase()}`)
  },
  [AdviceStatus.Approved]: {
    color: KColors.palette.warning.w600,
    background: KColors.palette.warning.w50,
    brC: KColors.transparent,
    label: trans(`option.${AdviceStatus.Approved.toLowerCase()}`)
  },
  [AdviceStatus.Complete]: {
    color: KColors.palette.primary.w500,
    background: KColors.palette.primary.w100,
    brC: KColors.transparent,
    label: trans(`option.${AdviceStatus.Complete.toLowerCase()}`)
  }
});

export const mappedStatementOfAccountStatus = () => ({
  [StatementOfAccountStatusEnum.DRAFT]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${StatementOfAccountStatusEnum.DRAFT.toLowerCase()}`)
  },
  [StatementOfAccountStatusEnum.ACTIVE]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${StatementOfAccountStatusEnum.ACTIVE.toLowerCase()}`)
  },
  [StatementOfAccountStatusEnum.PAID]: {
    color: KColors.black,
    background: '#67AC5B',
    brC: KColors.transparent,
    label: trans(`option.${StatementOfAccountStatusEnum.PAID.toLowerCase()}`)
  },
  [StatementOfAccountStatusEnum.PARTLY_PAID]: {
    color: KColors.black,
    background: '#5A99F0',
    brC: KColors.transparent,
    label: trans(
      `option.${StatementOfAccountStatusEnum.PARTLY_PAID.toLowerCase()}`
    )
  },
  [StatementOfAccountStatusEnum.CANCEL]: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans(`option.${StatementOfAccountStatusEnum.CANCEL.toLowerCase()}`)
  }
});

export const mappedStatementInvoiceStatus = () => ({
  [StatementInvoiceStatus.DRAFT]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${StatementInvoiceStatus.DRAFT.toLowerCase()}`)
  },
  [StatementInvoiceStatus.ISSUED]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${StatementInvoiceStatus.ISSUED.toLowerCase()}`)
  },
  [StatementInvoiceStatus.CANCEL]: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans(`option.${StatementInvoiceStatus.CANCEL.toLowerCase()}`)
  }
});

export const mappedSpendReceiveMoneyStatus = () => ({
  [SpendReceiveStatus.DRAFT]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${SpendReceiveStatus.DRAFT.toLowerCase()}`)
  },
  [SpendReceiveStatus.ACTIVE]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${SpendReceiveStatus.ACTIVE.toLowerCase()}`)
  },
  [SpendReceiveStatus.CANCEL]: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans(`option.${SpendReceiveStatus.CANCEL.toLowerCase()}`)
  }
});

export const mappedSOAInvoiceListStatus = () => ({
  [ARInvoiceStatus.ISSUED]: {
    color: KColors.primary.normal,
    background: '#DEFFFD',
    brC: KColors.primary.normal,
    label: trans(`option.${ARInvoiceStatus.ISSUED.toLowerCase()}`)
  },
  [APInvoiceStatus.APPROVED]: {
    color: KColors.palette.warning.w600,
    background: KColors.palette.warning.w50,
    brC: KColors.palette.warning.w600,
    label: trans(`option.${APInvoiceStatus.APPROVED.toLowerCase()}`)
  }
});

export const mappedQuotationStatus = () => ({
  [JobOrderStatus.DRAFT]: {
    color: KColors.black,
    background: '#DCDEDF',
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.DRAFT.toLowerCase()}`)
  },
  [JobOrderStatus.PENDING]: {
    color: KColors.palette.blue.w100,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.PENDING.toLowerCase()}`)
  },
  [JobOrderStatus.CANCEL]: {
    color: KColors.secondary.normal,
    background: KColors.hexToRgba(KColors.secondary.normal, 0.31),
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.CANCEL.toLowerCase()}`)
  },
  [JobOrderStatus.ACTIVE]: {
    color: KColors.palette.primary.w200,
    background: KColors.primary.normal,
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.ACTIVE.toLowerCase()}`)
  },
  [JobOrderStatus.DECLINED]: {
    color: KColors.secondary.normal,
    background: KColors.primary.light,
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.DECLINED.toLowerCase()}`)
  }
});

export const mappedPICStatus = () => ({
  [PicStatus.PENDING]: {
    color: KColors.palette.blue.w500,
    background: KColors.palette.blue.w600,
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.PENDING.toLowerCase()}`)
  },
  [PicStatus.APPROVED]: {
    color: KColors.palette.warning.w600,
    background: KColors.palette.warning.w50,
    brC: KColors.transparent,
    label: trans(`option.${PicStatus.APPROVED.toLowerCase()}`)
  },
  [JobOrderStatus.DECLINED]: {
    color: KColors.secondary.normal,
    background: KColors.primary.light,
    brC: KColors.transparent,
    label: trans(`option.${JobOrderStatus.DECLINED.toLowerCase()}`)
  }
});
