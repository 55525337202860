import React, { memo } from 'react';

import { ReadStatus } from '@constants';
import { Prototype } from 'core';
import { useCUDNotificationLog } from 'hooks/notification';
import { KChip, KColors, KContainer, KGrid, KLabel, KLoading } from 'uikit';

enum Source {
  'JOB_ORDER' = 'JOB_ORDER',
  'SYSTEM' = 'SYSTEM'
  // ...
}
const URL: {
  [key in string]: {
    url: string;
  };
} = {
  [Source.JOB_ORDER]: {
    url: '/admin/sales/job-order/'
  }
  // ...
};

const ItemNotification = ({ item }: any) => {
  const { mutate, isLoading } = useCUDNotificationLog();

  const timeAgo = Prototype.date.toLocal(item?.createdAt)?.fromNow();

  return (
    <KContainer.Touchable
      onPress={() => {
        // feature under development
        if (Source.JOB_ORDER === item.source) {
          window.open(URL[item.source].url + item.sourceId, '_self');
        }
        if (item.readStatus !== 'READ') {
          mutate({
            id: item?.id
          });
        }
      }}
    >
      {isLoading && <KLoading />}
      <KGrid.Container marginY={'0.5rem'}>
        <KGrid.Item xs={3}>
          <KChip
            size="md"
            brC={KColors.warning.normal}
            background={KColors.opacity.warning['16']}
            label={item?.source}
          />
        </KGrid.Item>

        <KGrid.Item xs={8.2}>
          <KLabel.Paragraph typo="TextLgMedium">
            {item?.subject}
          </KLabel.Paragraph>
          <KLabel.Paragraph
            marginV={'0.25rem'}
            dangerouslySetInnerHTML={{
              __html: item?.content.replace(/\n/g, '<br>')
            }}
          ></KLabel.Paragraph>
          <KLabel.Paragraph typo="TextXsNormal">
            {` sent by ${item?.sentUsername} at ${timeAgo}`}
          </KLabel.Paragraph>
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={item?.readStatus !== ReadStatus.READ}
          >
            <KGrid.Item xs={0.5}>
              <KContainer.View
                height={'0.8rem'}
                width={'0.8rem'}
                br={'2x'}
                background={KColors.primary.normal}
              ></KContainer.View>
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KGrid.Container>
    </KContainer.Touchable>
  );
};
export default memo(ItemNotification);
