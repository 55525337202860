import Alert from './Alert';
import Popper from './Popper';
import Popup from './Popup';

const Portal = {
  Alert,
  Popper,
  Popup
};

export default Portal;
