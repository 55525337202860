import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { Theme, CSSObject, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { drawerWidth, drawerWidthMini } from 'assets/theme/components/sidebar';
import Branch from 'containers/Branch';
import { usePreferences } from 'context';
import { useSignOut, useUser } from 'hooks';
import { objPermissions } from 'hooks/core/helpers';
import trans from 'translation';
import { KButton, KColors, KContainer, KLabel, KListItem } from 'uikit';
import { UIUtils } from 'utils';

import Sidebar from './Sidebar';

import PopperNotification from '../containers/Notifications';

interface IProps {
  count: number;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `${drawerWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  })
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard
  }),
  width: `calc(100% - ${drawerWidthMini}px)`,
  marginLeft: `${drawerWidthMini}px`
});

const key = 'nav';

const Navbar = ({ count }: IProps) => {
  const { user } = useUser();
  const { account, branch } = user || {};

  const theme = useTheme();
  const navigate = useNavigate();

  const prefs = usePreferences();
  const { state, toggleDrawer } = prefs;

  const signOut = useSignOut();

  const ref = React.createRef<HTMLButtonElement>();

  const { isAdmin } = objPermissions();

  const showPopupListBranch = () => {
    UIUtils.popup.open({
      title: trans(`${key}.switch_branch`),
      maxWidth: 'md',
      content: dismiss => <Branch.Grid dismiss={dismiss} />
    });
  };

  const renderNotifications = useCallback((dismiss: () => void) => {
    const onPressWrapper = (onPress?: () => void) => {
      onPress?.();
      dismiss();
    };

    return <PopperNotification />;
  }, []);

  const renderProfileContent = useCallback(
    (dismiss: () => void) => {
      const cIcon = {
        size: 36,
        color: '#707277'
      };

      const onPressWrapper = (onPress?: () => void) => {
        onPress?.();
        dismiss();
      };

      return (
        <>
          <KContainer.View paddingV="0.25rem" row alignItems>
            <KContainer.View dp="flex" center size={48}>
              <Avatar src={account?.avatar} sx={{ width: 36, height: 36 }} />
            </KContainer.View>

            <KContainer.View marginL="0.75rem">
              <KLabel.Text typo="TextMdNormal" textTransform="uppercase">
                {account?.displayName}
              </KLabel.Text>

              <KLabel.Paragraph marginV="0.25rem" typo="TextMdNormal" italic>
                {account?.email}
              </KLabel.Paragraph>

              <KLabel.Text typo="TextMdMedium" color={KColors.primary.normal}>
                {branch?.name}
              </KLabel.Text>
            </KContainer.View>
          </KContainer.View>

          <KListItem.Base
            data={[
              {
                paddingV: '0.25rem',
                title: trans(`${key}.my_profile`),
                icon: {
                  name: 'PersonOutlineOutlined',
                  ...cIcon
                },
                onPress: () => onPressWrapper(() => navigate('/admin/profile'))
              },
              isAdmin && {
                paddingV: '0.25rem',
                title: trans(`${key}.company_profile`),
                icon: {
                  name: 'CorporateFareOutlined',
                  ...cIcon
                },
                onPress: () => onPressWrapper(() => navigate('/admin/company'))
              },
              {
                paddingV: '0.25rem',
                title: trans(`${key}.switch_branch`),
                icon: {
                  name: 'ChangeCircleOutlined',
                  ...cIcon
                },
                onPress: () => onPressWrapper(showPopupListBranch)
              },
              {
                paddingV: '0.25rem',
                title: trans(`${key}.sign_out`),
                icon: {
                  name: 'LogoutOutlined',
                  ...cIcon
                },
                onPress: () => onPressWrapper(signOut.mutate)
              },
              {
                paddingV: '0.25rem',
                title: trans(`${key}.disable_account`),
                icon: {
                  name: 'NoAccountsOutlined',
                  ...cIcon
                },
                onPress: () => {}
              }
            ]}
          />
        </>
      );
    },
    [
      account?.avatar,
      account?.displayName,
      account?.email,
      branch?.name,
      isAdmin,
      navigate,
      signOut.mutate
    ]
  );

  const onPressNoti = useCallback(() => {
    UIUtils.popper.open({
      anchorEl: ref.current,
      placement: 'bottom-end',
      touchOutsideToDismiss: true,
      withMaxZIndex: true,
      cardProps: { marginT: '0.75rem', style: { right: 170 } },
      content: renderNotifications
    });
  }, [ref, renderNotifications]);

  return (
    <>
      <AppBar
        component="nav"
        position="fixed"
        elevation={1}
        sx={
          !state.miniDrawer
            ? {
                ...openedMixin(theme),
                '& .MuiAppBar-root': openedMixin(theme)
              }
            : {
                ...closedMixin(theme),
                '& .MuiAppBar-root': closedMixin(theme)
              }
        }
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <Icon>menu</Icon>
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: 'flex' }}>
            <KButton.Icon
              ref={ref}
              kind="normal"
              size="md"
              icon="Notifications"
              badge={count}
              onPress={onPressNoti}
            />

            <KButton.Transparent
              ref={ref}
              title={user?.account?.displayName}
              kind="normal"
              size="md"
              endIcon={
                <Avatar
                  src={account?.avatar}
                  sx={{ width: '32px', height: '32px' }}
                />
              }
              onPress={() => {
                UIUtils.popper.open({
                  anchorEl: ref.current,
                  placement: 'bottom-end',
                  touchOutsideToDismiss: true,
                  style: { zIndex: 1101 },
                  cardProps: { marginT: '1rem', minW: 220 },
                  content: renderProfileContent
                });
              }}
            />
          </Box>
        </Toolbar>

        <Sidebar />
      </AppBar>
    </>
  );
};

export default memo(Navbar);
