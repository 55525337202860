import { ColTypeDef, ValueFormatterParams } from 'ag-grid-community';
import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { DATE_FORMAT, DATE_FORMAT_SHORT } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

const dateTimeFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT);
};

const dateFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return Prototype.date.toLocal(v)?.format(DATE_FORMAT_SHORT);
};

const numberFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return isNumber(v)
    ? Prototype.number.formatNumber(v, { negative: true })
    : '';
};

const optionFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v ? trans(`option.${v.toLowerCase()}`).toUpperCase() : '';
};

const transFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v ? trans(v.toLowerCase()).toUpperCase() : '';
};

const codeFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.code ?? '';
};

const nameFormatter = (p: ValueFormatterParams<any>) => {
  const v = p.value;
  return v?.name ?? '';
};

export const useColTypes = <T>() => {
  return useMemo(() => {
    return {
      vnDate: {
        valueFormatter: dateFormatter
      },
      vnDateTime: {
        valueFormatter: dateTimeFormatter
      },
      trans: {
        valueFormatter: transFormatter
      },
      num: {
        valueFormatter: numberFormatter
      },
      name: {
        minWidth: 200,
        valueFormatter: nameFormatter
      },
      code: {
        valueFormatter: codeFormatter
      }
    } as { [key: string]: ColTypeDef<T> };
  }, []);
};
