/* eslint-disable max-lines */
import {
  ColDef,
  ICellRendererParams,
  EditableCallbackParams,
  IRowNode
} from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import React from 'react';
import { generatePath } from 'react-router-dom';

import {
  AirOceanType,
  ArApType,
  CalculatorType,
  ClientType,
  ENDPOINTS,
  InvoiceCategory,
  LevelMarkup,
  ServiceType,
  WipServiceType,
  generateOptions
} from '@constants';
import { END_POINTS } from '@services';
import { Prototype } from 'core';
import { onShowClient } from 'hooks';
import {
  onShowChargeCode,
  onShowContainerSize
} from 'pages/SystemSettings/Library/helpers';
import trans from 'translation';
import { KButton, KColors, KContainer, KInput, KLabel } from 'uikit';
import { AutocompleteUtils, UIUtils } from 'utils';

import FormSubChargeCode from './Form.SubChargeCode';
import FormUnitPriceQuantity from './Form.unitPriceQuantity';

export const AccountEditor = ({
  value,
  colDef,

  onValueChange
}: CustomCellEditorProps<any>) => {
  const { optionLabel, clientType } = colDef.cellEditorParams as {
    optionLabel: 'name' | 'code';
    clientType: ClientType;
  };
  return (
    <KInput.Autocomplete
      label={trans('')}
      apiURL={ENDPOINTS.client()}
      fullWidth
      apiParams={{
        includeFields: ['defaultAddress'],
        clientTypeCodes: clientType
      }}
      value={value}
      getOptionLabel={(o: any) => o?.[optionLabel]}
      onChange={(e: any) => {
        onValueChange(e);
      }}
      addNewKey={optionLabel}
      hasAddNew
      hasEdit
      onAddNew={onShowClient}
      onEdit={onShowClient}
    />
  );
};

export const CurrencyEditor = ({
  value,
  onValueChange
}: CustomCellEditorProps<any>) => {
  return (
    <KInput.Autocomplete
      inputProps={{
        marginT: '0rem'
      }}
      fullWidth
      value={value}
      name="currency"
      apiURL={ENDPOINTS.currency()}
      onChange={(v?: any) => {
        onValueChange(v);
      }}
      getOptionLabel={(o: any) => o?.code ?? ''}
      label={' '}
    />
  );
};

export const FromToEditor = ({
  value,
  colDef,

  onValueChange
}: CustomCellEditorProps<any>) => {
  const { isPol, invoiceCategory, id } = colDef.cellEditorParams as {
    id: string;
    isPol: boolean;
    invoiceCategory: InvoiceCategory;
  };
  if (invoiceCategory === InvoiceCategory.Service) {
    return (
      <KInput.Autocomplete
        inputProps={{ marginT: '0rem' }}
        fullWidth
        apiURL={ENDPOINTS.unloco()}
        getOptionLabel={AutocompleteUtils.unlocoFormatter}
        apiParams={{
          includeFields: ['timezone', 'client']
        }}
        value={value}
        label={' '}
        onChange={(v: any) => {
          onValueChange(v);
        }}
      />
    );
  } else {
    const apiURL =
      invoiceCategory === InvoiceCategory.Billing
        ? generatePath(END_POINTS.CONSOL.GET_SHIPMENT_ROUTING_ADDRESS, {
            shipmentId: id ?? ''
          })
        : generatePath(END_POINTS.CONSOL.GET_CONSOL_ROUTING_ADDRESS, {
            consolId: id ?? ''
          });
    return (
      <KInput.Autocomplete
        inputProps={{ marginT: '0rem' }}
        fullWidth
        value={value}
        label={trans(' ')}
        apiURL={apiURL}
        apiParams={{
          isPol
        }}
        getOptionLabel={AutocompleteUtils.unlocoFormatter}
        onChange={(v: any) => {
          onValueChange(v);
        }}
      />
    );
  }
};

export const MarkupLevelNumberCellEditor = ({
  value,

  onValueChange
}: CustomCellEditorProps<any>) => {
  return (
    <KInput.TextField
      value={value}
      options={generateOptions(LevelMarkup)}
      onChange={v => {
        onValueChange(v.target.value);
      }}
    />
  );
};

export const SubChargeCodeCellRenderer = ({
  node,
  api
}: ICellRendererParams<any>) => {
  const isCal = [CalculatorType.Composite].includes(
    node.data?.chargeCode?.calculatorType
  );
  const wipSubChargeCodes = node.data?.wipSubChargeCodes || [];
  return (
    <KContainer.View dp="flex" row>
      {wipSubChargeCodes.map((o: any, idx: number) => {
        const marginL = idx === 0 ? 0 : '0.5rem';
        return (
          <KContainer.View
            key={`sub-charge_code-list-${idx}`}
            dp="flex"
            center
            paddingV="0.25rem"
            paddingH="0.75rem"
            br="x"
            brW={1}
            brC={KColors.warning.normal}
            background={'#FFF5E5'}
            marginL={marginL}
          >
            <KLabel.Text>{o?.subChargeCode?.code}</KLabel.Text>
          </KContainer.View>
        );
      })}

      <KLabel.Text hidden={!isCal}>
        <KButton.Icon
          icon="MoreHoriz"
          tintColor={KColors.white}
          background={KColors.warning.normal}
          br="x"
          size="sm"
          onPress={() => {
            onShowSubChargeCodeComposite({
              rowData: node.data,
              onGetValuePopup(v) {
                const { taxAmount, estTotal } = subTotalTaxAmount({
                  taxType: node.data?.taxType,
                  _estSubTotal: v?.estSubTotal
                });
                const newValue = {
                  ...node.data,
                  ...v,
                  taxAmount,
                  estTotal
                };

                node?.updateData(newValue);
                api.applyTransaction({
                  update: [newValue]
                });
                UIUtils.popup.dismiss();
              }
            });
          }}
          marginL="0.25rem"
        />
      </KLabel.Text>
    </KContainer.View>
  );
};

export const UnitPriceQuantityCellRenderer = ({
  node,
  api,
  colDef
}: ICellRendererParams<any>) => {
  const { id, invoiceCategory, screen, serviceType, unitKey } =
    colDef?.cellRendererParams as {
      // serviceId || consolId || shipmentId
      id: string;
      invoiceCategory: InvoiceCategory;
      screen: ArApType;
      serviceType?: ServiceType;
      unitKey: string;
    };

  const isCal = [CalculatorType.Value, CalculatorType.Descriptive].includes(
    node.data?.chargeCode?.calculatorType
  );
  const wipPrices = node.data?.wipPrices || [];

  return (
    <KContainer.View dp="flex" row>
      {wipPrices.map((o: any, idx: number) => {
        const marginL = idx === 0 ? 0 : '0.5rem';
        const v = [Prototype.number.formatNumber(o.unitPrice), o.quantity].join(
          '*'
        );
        return (
          <KContainer.View
            key={`charge-code-level-list-popup-${idx}`}
            dp="flex"
            center
            paddingV="0.25rem"
            paddingH="0.75rem"
            br="x"
            brW={1}
            brC={KColors.primary.normal}
            background="#ACDCD7"
            marginL={marginL}
          >
            <KLabel.Text>{v}</KLabel.Text>
          </KContainer.View>
        );
      })}

      <KLabel.Text hidden={!isCal}>
        <KButton.Icon
          icon="MoreHoriz"
          tintColor={KColors.white}
          background={KColors.primary.normal}
          br="x"
          size="sm"
          onPress={() => {
            onShowUnitPriceQuantityValue({
              rowData: node.data,
              id,
              invoiceCategory,
              serviceType,
              screen,
              unitKey,
              onGetValuePopup(v) {
                const newValue = {
                  ...node.data,
                  ...v
                };
                node?.updateData(newValue);
                api.applyTransaction({
                  update: [newValue]
                });
                UIUtils.popup.dismiss();
              }
            });
          }}
          marginL="0.25rem"
        />
      </KLabel.Text>
    </KContainer.View>
  );
};

export const onShowSubChargeCodeComposite = (params: {
  rowData: any;

  onGetValuePopup: (v: any) => void;
}) => {
  UIUtils.popup.open({
    title: trans('sub_charge_code'),
    maxWidth: 'lg',
    content: () => {
      return <FormSubChargeCode {...params} />;
    }
  });
};

export const onShowUnitPriceQuantityValue = (params: {
  rowData: any;
  // serviceId || consolId || shipmentId
  id: string;
  invoiceCategory: InvoiceCategory;
  screen: ArApType;
  serviceType?: ServiceType;
  unitKey: string;

  onGetValuePopup?: (v: {
    estSubTotal: number;
    wipPrices: any[];
    totalQuantity: number;
    quantity: number;
    taxAmount: number;
    estTotal: number;
  }) => void;
}) => {
  UIUtils.popup.open({
    title: trans('unit_price_quantity'),
    maxWidth: 'lg',
    content: () => {
      return <FormUnitPriceQuantity {...params} />;
    }
  });
};

export const ContainerTypeSizeCellEditor = ({
  data,
  api,
  onValueChange,
  node,
  ...props
}: CustomCellEditorProps<any>) => {
  const value = props.value || data?.containerTypeSize;
  return (
    <KInput.Autocomplete
      value={value}
      inputProps={{ marginT: '0rem' }}
      fullWidth
      apiURL={ENDPOINTS.containerTypeSize()}
      hasAddNew
      onAddNew={onShowContainerSize}
      addNewKey="code"
      hasEdit
      onEdit={onShowContainerSize}
      name="containerTypeSize"
      label={trans(' ')}
      onChange={(v: any) => {
        const newData = {
          ...(node.data as any),
          containerTypeSize: v,
          containerTypeSizeId: v?.id
        };
        onValueChange(v);
        node.updateData(newData);
        api.applyTransaction({
          update: [newData]
        });
      }}
      getOptionLabel={(o: any) => o?.code ?? ''}
    />
  );
};

export const ServiceTypeCellEditor = ({
  node,
  api,
  value
}: CustomCellEditorProps<any>) => {
  return (
    <KInput.TextField
      fullWidth
      value={value}
      options={generateOptions(WipServiceType)}
      onChange={v => {
        const newData = {
          ...(node.data as any),
          serviceType: v.target.value
        };
        node.updateData(newData);
        api.applyTransaction({
          update: [newData]
        });
      }}
    />
  );
};

export const InvoiceNoCellEditor = ({
  value,
  data,
  colDef
}: ICellRendererParams<any>) => {
  const { mode, screen } = colDef?.cellRendererParams as {
    mode: AirOceanType;
    screen: ArApType.Ar;
  };

  const screenLowerCade = screen.toLocaleLowerCase();
  const billingCategory = String(data?.billingCategory).toLowerCase();
  const { shipmentId, serviceId, consolId, arInvoiceId, apInvoiceId } = data;
  const shipmentExist = shipmentId ? 'shipmentExist' : 'shipmentNull';
  const invoiceId = arInvoiceId || apInvoiceId;
  const endPointUrl: Record<string, any> = {
    [InvoiceCategory.Service]: {
      [AirOceanType.Ocean]: {
        ['shipmentExist']: `/ocean-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/ar/${invoiceId}`,
        ['shipmentNull']: `/ocean-freight/service/${serviceId}/billing/${screenLowerCade}/${invoiceId}`
      },
      [AirOceanType.Air]: {
        ['shipmentExist']: `/air-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/${screenLowerCade}/${invoiceId}`,
        ['shipmentNull']: `/service/${serviceId}/billing/${screenLowerCade}/${invoiceId}`
      },
      isRedirect: true
    },
    [InvoiceCategory.Accounting]: {
      [AirOceanType.Ocean]: {
        ['shipmentExist']: `/ocean-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/${screenLowerCade}/${invoiceId}`,
        ['shipmentNull']: `/ocean-freight/consolidation/${consolId}/accounting/${screenLowerCade}/${invoiceId}`
      },
      [AirOceanType.Air]: {
        ['shipmentExist']: `/air-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/${screenLowerCade}/${invoiceId}`,
        ['shipmentNull']: `/air-freight/${consolId}/accounting/${screenLowerCade}/${invoiceId}`
      },
      isRedirect: !!consolId
    },
    [InvoiceCategory.Billing]: {
      [AirOceanType.Ocean]: {
        ['shipmentExist']: `/ocean-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/${screenLowerCade}/${invoiceId}`,
        ['shipmentNull']: `/ocean-freight/consolidation/${consolId}/accounting/${screenLowerCade}/${invoiceId}`
      },
      [AirOceanType.Air]: {
        ['shipmentExist']: `/air-freight/consolidation/${consolId}/shipment/${shipmentId}/billing/${screenLowerCade}/${invoiceId}`,
        ['shipmentNull']: `/air-freight/${consolId}/accounting/${screenLowerCade}/${invoiceId}`
      },
      isRedirect: !!consolId
    }
  };

  const url =
    window.location.origin +
    '/admin' +
    endPointUrl[billingCategory]?.[mode][shipmentExist];

  return (
    <KLabel.Text
      {...(endPointUrl[billingCategory]?.isRedirect && {
        onPress: () => {
          window.open(url, '_blank');
        }
      })}
    >
      {value}
    </KLabel.Text>
  );
};

export const columnDefault: {
  chargeCode: (params: {
    apiParamsChargeCode: {
      [x: string]: boolean | any[];
      includeFields: any[];
    };
    unitKey: string;
    invoiceCategory: InvoiceCategory;
  }) => ColDef<any>;
  currency: (params: { invoiceCategory: InvoiceCategory }) => ColDef<any>;
  taxType: (params: { invoiceCategory: InvoiceCategory }) => ColDef<any>;
  subChargeCode: (params: { invoiceCategory: InvoiceCategory }) => ColDef<any>;
  obhCellEditor: (params: { invoiceCategory: InvoiceCategory }) => ColDef<any>;
} = {
  chargeCode: ({ apiParamsChargeCode, unitKey, invoiceCategory }) => {
    return {
      headerComponent: () => (
        <KLabel.Text textAlign="center" typo="TextNmMedium" width={'100%'}>
          {trans('charge_code')} <span style={{ color: 'red' }}>*</span>
        </KLabel.Text>
      ),
      headerName: trans('charge_code'),
      colId: 'chargeCode',
      field: 'chargeCode',
      maxWidth: 180,
      pinned: 'left',
      lockVisible: true,
      editable: params => !isNotAllowUpdate(params, invoiceCategory),
      onCellValueChanged({ node, newValue, data }) {
        const chargeCodeUnit = newValue?.[unitKey];
        const { taxType, taxTypeId } = subTotalTaxAmount({
          taxType: newValue?.taxType,
          _estSubTotal: 0
        });
        const _newValue = {
          ...data,
          description: newValue?.name,
          chargeCodeId: newValue?.id,
          currency: newValue?.currency,
          currencyId: newValue?.currency?.id,
          // newValue?.chargeCodeUnit exist when BOL
          chargeCodeUnit: newValue?.chargeCodeUnit ?? chargeCodeUnit,
          chargeCodeUnitId: newValue?.chargeCodeUnit?.id ?? chargeCodeUnit?.id,
          taxType,
          taxTypeId
        };
        if (newValue?.calculatorType === CalculatorType.Composite) {
          _newValue.wipPrices = [];
        } else {
          _newValue.wipSubChargeCodes = [];
        }
        node?.updateData(_newValue);
      },
      cellEditor: ({ value, onValueChange }: CustomCellEditorProps<any>) => {
        return (
          <KInput.Autocomplete
            inputProps={{ marginT: '0rem' }}
            hasEdit
            onEdit={onShowChargeCode}
            fullWidth
            name="chargeCode"
            label={' '}
            value={value}
            apiURL={ENDPOINTS.chargeCode()}
            apiParams={apiParamsChargeCode}
            getOptionLabel={(o: any) => o?.name}
            onChange={(e: any) => {
              onValueChange(e);
            }}
          />
        );
      },
      valueFormatter: ({ data }) => {
        return data?.chargeCode?.name;
      }
    };
  },
  currency: ({ invoiceCategory }) => {
    return {
      headerName: trans('currency'),
      colId: 'currency',
      field: 'currency',
      editable: true,
      cellClass: params => {
        return isNotAllowUpdate(params, invoiceCategory)
          ? ['disable', 'text-center']
          : ['text-center'];
      },
      enableCellChangeFlash: true,
      valueFormatter: ({ data }) => {
        return data?.currency?.code ?? '';
      },
      onCellValueChanged({ node, newValue }) {
        node?.updateData({
          ...node.data,
          currencyId: newValue?.id
        });
      },
      cellEditor: CurrencyEditor
    };
  },
  taxType: ({ invoiceCategory }) => {
    return {
      headerName: trans('with_unit.tax'),
      field: 'taxType',
      cellClass: params => {
        return isNotAllowUpdate(params, invoiceCategory)
          ? ['disable', 'text-center']
          : ['text-center'];
      },
      enableCellChangeFlash: true,
      width: 120,
      valueFormatter: ({ data }) => {
        return data?.taxType?.code;
      },
      onCellValueChanged({ node, newValue, data }) {
        const { taxAmount, estTotal } = subTotalTaxAmount({
          taxType: newValue,
          _estSubTotal: data?.estSubTotal
        });
        node?.updateData({
          ...node.data,
          taxTypeId: newValue?.id,
          taxType: newValue,
          taxAmount,
          estTotal
        });
      },
      editable: true,
      cellEditor: ({ value, onValueChange }: CustomCellEditorProps<any>) => {
        return (
          <KInput.Autocomplete
            inputProps={{ marginT: '0rem' }}
            fullWidth
            apiURL={ENDPOINTS.taxType()}
            value={value}
            onChange={(v: any) => {
              onValueChange(v);
            }}
            getOptionLabel={(o: any) => o?.code ?? ''}
          />
        );
      }
    };
  },
  subChargeCode: ({ invoiceCategory }) => {
    return {
      headerName: trans('sub_charge_code'),
      cellClass: params => {
        return isNotAllowUpdate(params, invoiceCategory)
          ? ['disable', 'text-center']
          : ['text-center'];
      },
      width: 400,
      hide: true,
      cellRenderer: SubChargeCodeCellRenderer
    };
  },
  obhCellEditor: ({ invoiceCategory }) => {
    return {
      headerName: trans('obh_client'),
      field: 'onBehalfClient',
      cellClass: params => {
        return isNotAllowUpdate(params, invoiceCategory)
          ? ['disable', 'text-center']
          : ['text-center'];
      },
      onCellValueChanged({ node, newValue }) {
        node?.updateData({
          ...node.data,
          onBehalfClientId: newValue?.id
        });
      },
      valueFormatter: ({ value }) => {
        return value?.name;
      },
      editable: true,
      cellEditor: ({ value, onValueChange }: CustomCellEditorProps) => {
        return (
          <KInput.Autocomplete
            fullWidth
            inputProps={{
              marginT: 0
            }}
            label={trans(' ')}
            value={value}
            apiURL={ENDPOINTS.client()}
            apiParams={{
              includeFields: ['defaultAddress']
            }}
            getOptionLabel={(o: any) => o?.name ?? ''}
            addNewKey="name"
            onChange={(v: any) => onValueChange(v)}
            hasAddNew
            hasEdit
            onAddNew={onShowClient}
            onEdit={onShowClient}
          />
        );
      }
    };
  }
};

export const mapToParamsWipArAp = ({
  wipUpserts,
  category
}: {
  wipUpserts: any[];
  category: InvoiceCategory;
}) => {
  const _wipUpserts = wipUpserts.filter(item => {
    const disable =
      item.isNotAllowUpdate ||
      (item.billingCategory &&
        item.billingCategory !== category.toLocaleUpperCase());
    return !disable;
  });
  const params = _wipUpserts.map(item => {
    const {
      id,
      currencyId,
      serviceType,
      chargeCodeId,
      consolServiceType,
      description,
      arClientId,
      apClientId,
      chargeCodeUnitId,
      addrFromId,
      addrToId,
      containerTypeSizeId,
      taxTypeId,
      quantity,
      baseValue,
      wipSubChargeCodes,
      wipPrices,
      markupLevelNumber,
      onBehalfClientId
    } = item;

    return {
      id,
      currencyId,
      serviceType,
      chargeCodeId,
      consolServiceType,
      description,
      chargeCodeUnitId,
      addrFromId,
      addrToId,
      containerTypeSizeId,
      taxTypeId,
      quantity,
      baseValue,
      markupLevelNumber,
      wipSubChargeCodes,
      wipPrices,
      // account receivable
      arClientId,
      // account payable
      apClientId,
      onBehalfClientId
    };
  });
  return params;
};

export const subTotalTaxAmount = ({
  taxType,
  _estSubTotal
}: {
  taxType: any;
  _estSubTotal: string | number;
}) => {
  if (taxType?.status === 'ACTIVE') {
    const estSubTotal = Number(_estSubTotal || 0);
    const taxAmount = (Number(taxType.defaultRate || 0) * estSubTotal) / 100;
    const estTotal = estSubTotal + taxAmount;
    return { estSubTotal, taxAmount, estTotal, taxType, taxTypeId: taxType.id };
  }
  return {
    estSubTotal: 0,
    taxAmount: 0,
    estTotal: 0,
    taxType: null,
    taxTypeId: null
  };
};

export const isNotAllowUpdate = (
  { data }: EditableCallbackParams | IRowNode,
  invoiceCategory: InvoiceCategory
) => {
  if (
    data.isNotAllowUpdate ||
    (data.billingCategory &&
      data.billingCategory !== invoiceCategory.toLocaleUpperCase())
  ) {
    return true;
  } else {
    return false;
  }
};
