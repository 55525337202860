import React, { useCallback } from 'react';

import {
  ConsolType,
  ExchangeRateTypeALL,
  ExchangeRateTypeExport,
  ExchangeRateTypeImport,
  InvoiceDate,
  generateOptions
} from '@constants';
import { Prototype } from 'core';
import trans from 'translation';
import { KColors, KInput } from 'uikit';

import UIUtils from './ui';
interface ITypes {
  title: string;
  onPress: () => void;
  icon: any;
  color?: any;
}

export const actionType = (types: ITypes) => {
  const { onPress = () => {}, title, icon, color } = types;
  return {
    title: trans(title),
    icon: {
      name: icon,
      color: color || KColors.primary.normal
    },
    onPress
  };
};

export const defaultPersonInCharges = {
  department: undefined,
  staff: undefined,
  // ratio: 0,
  serviceType: null
};

// export const defaultPersonInChart = (
//   personInCharges: any[] = [],
//   serviceType?: 'TRANSPORT' | 'HANDLING' | 'CUSTOMS'
// ) => {
//   const _personInCharges = personInCharges.map(itemP => {
//     return {
//       ...itemP,
//       staff: {
//         ...itemP?.staff,
//         jobTitle: itemP?.jobTitle
//       }
//     };
//   });
//   return personInCharges?.length
//     ? serviceType
//       ? _personInCharges.filter((item: any) => item.serviceType === serviceType)
//       : _personInCharges
//     : [{ ...defaultPersonInCharges, serviceType }];
// };

export const formatReqPIC = (item: any) => {
  return item?.staff?.id
    ? {
        id: item?.id,
        staffId: item?.staff?.id,
        departmentId: item?.department?.id
      }
    : null;
};

export const convertPIC = (item: any) => {
  return {
    ...item,
    staff: !!item?.staff
      ? {
          ...item?.staff,
          jobTitle: item?.jobTitle
        }
      : undefined
  };
};

export const recordPersonInChart = (
  personInCharges: any[],
  type: null | 'CUSTOMS' | 'TRANSPORT' | 'HANDLING' | 'MAIN'
) => {
  let newPerson = personInCharges.map(itemP => {
    return convertPIC(itemP);
  });
  let res = newPerson.find((item: any) => {
    return item?.serviceType === type;
  });

  return res;
};

export const defaultPersonInChart = (personInCharges: any[] = []) => {
  let _listDefaultPer = [
    defaultPersonInCharges,
    { ...defaultPersonInCharges, serviceType: 'CUSTOMS' },
    { ...defaultPersonInCharges, serviceType: 'TRANSPORT' },
    { ...defaultPersonInCharges, serviceType: 'HANDLING' }
  ];

  if (recordPersonInChart(personInCharges, 'MAIN')) {
    _listDefaultPer[0] = recordPersonInChart(personInCharges, 'MAIN');
  }

  if (recordPersonInChart(personInCharges, 'CUSTOMS')) {
    _listDefaultPer[1] = recordPersonInChart(personInCharges, 'CUSTOMS');
  }

  if (recordPersonInChart(personInCharges, 'TRANSPORT')) {
    _listDefaultPer[2] = recordPersonInChart(personInCharges, 'TRANSPORT');
  }

  if (recordPersonInChart(personInCharges, 'HANDLING')) {
    _listDefaultPer[3] = recordPersonInChart(personInCharges, 'HANDLING');
  }

  return _listDefaultPer;
  // personInCharges?.length
  //   ? personInCharges.map(itemP => {
  //       return {
  //         ...itemP,
  //         staff: {
  //           ...itemP?.staff,
  //           jobTitle: itemP?.jobTitle
  //         }
  //       };
  //     })
  //   : [defaultPersonInCharges];
};
/**
  format1= {
     pic1: null,
    pic2: null,
    pic3: null,
    pic4: null
    }
 *   
     format2= {
    picMain: null;
    picTransport: null;
    picHandling: null;
    picCustoms: null;
}
 */
export const convertPersonInCharges = (personInCharges: any[]) => {
  const pics = (personInCharges || [])
    .filter((itemD: any) => {
      return !!itemD?.department?.id || itemD?.isSales;
    })
    .map(itemX => {
      return {
        id: itemX?.id || null,
        staffId: itemX?.staff?.staffId ?? itemX?.staff?.id,
        departmentId: itemX?.department?.id,
        ratio: itemX?.ratio,
        serviceType: itemX?.serviceType
      };
    });

  const format1: Record<
    string,
    { staffId: number; departmentId: number; ratio: number } | null
  > = {
    pic1: null,
    pic2: null,
    pic3: null,
    pic4: null
  };
  pics.forEach((pic, index) => {
    if (index < 4) {
      const key = `pic${index + 1}` as keyof typeof format1;
      format1[key] = {
        staffId: pic.staffId,
        departmentId: pic.departmentId,
        ratio: pic.ratio || 0
      };
    }
  });
  const format2 = pics.reduce(
    (acc: any, curr: any) => {
      const key =
        curr.serviceType === 'MAIN'
          ? 'picMain'
          : `pic${
              `${curr.serviceType}`.charAt(0).toUpperCase() +
              `${curr.serviceType}`.slice(1).toLowerCase()
            }`;

      acc[key] = curr;
      return acc;
    },
    {
      picMain: null,
      picTransport: null,
      picHandling: null,
      picCustoms: null
    }
  );
  return {
    format1,
    format2
  };
};

export const formatSummary = (data: any = {}) => {
  Object.keys(data).map(
    x => (data[x] = Prototype.number.formatNumber(data[x]))
  );
  return data;
};

export const convertItem = () => {
  const addDefaultValue = useCallback((item: any, option: any) => {
    return { ...item, ...option };
  }, []);
  return { addDefaultValue };
};

export const showAlertWarning = (
  message:
    | 'message.all_invoice_will_be_deleted'
    | 'message.all_charge_codes_will_be_deleted'
    | string,
  callbackSubmit: () => void
) => {
  {
    UIUtils.alert.open({
      message: trans(message),
      buttons: [
        {
          title: trans('submit'),
          kind: 'danger',
          color: KColors.warning.normal,
          onPress: () => callbackSubmit()
        },
        {
          title: trans('cancel')
        }
      ]
    });
  }
};

export const useBasicAction = () => {
  const actionAdd = (onPress: () => void) => {
    return actionType({
      title: 'new_statement_of_account',
      icon: 'AddBox',
      onPress
    });
  };

  const actionSendStatus = (onPress: () => void, title?: string) => {
    return actionType({
      title: title ?? 'send_status',
      icon: 'ArrowUpward',
      onPress
    });
  };

  const actionCancel = (onPress: () => void) => {
    return actionType({
      title: 'cancel',
      icon: 'CancelOutlined',
      onPress,
      color: KColors.secondary.normal
    });
  };

  return { actionAdd, actionSendStatus, actionCancel };
};

export const convertHtml = (content: string) => {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const optionExchangeRateType = (dataType: ConsolType) => {
  const isImport = dataType === ConsolType.Import;
  if (isImport) {
    return generateOptions(ExchangeRateTypeImport);
  }

  const isExport = dataType === ConsolType.Export;
  if (isExport) {
    return generateOptions(ExchangeRateTypeExport);
  }

  return generateOptions(InvoiceDate);
};

export const exchangeRateDateValue = (
  v: ExchangeRateTypeALL,
  isEdit: boolean,
  timeImport: any,
  timeExport: any,
  createdAt: any
) => {
  let newDate: any = Prototype.date.now();
  if (v === ExchangeRateTypeALL.ETA) {
    // import
    newDate = Prototype.date.toLocal(timeImport);
  } else if (v === ExchangeRateTypeALL.ETD) {
    // export
    newDate = Prototype.date.toLocal(timeExport);
  } else if (v === ExchangeRateTypeALL.INVOICE_DATE) {
    if (isEdit) {
      newDate = createdAt;
    }
  }

  return newDate;
};

export const showNoteContent = (item?: any) => {
  UIUtils.popup.open({
    title: trans('general_note'),
    maxWidth: 'md',
    content: () => (
      <KInput.TextField multiline minRows={4} value={item?.noteContent ?? ''} />
    )
  });
};
