import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import loading, { ExtraModelsFromLoading } from '@rematch/loading';
import persist from '@rematch/persist';
import selectPlugin from '@rematch/select';
import updatedPlugin from '@rematch/updated';
import { clone } from 'lodash';
import createCachedSelector from 're-reselect';
import storage from 'redux-persist/lib/storage';

import { models, RootModel } from './models';

type FullModel = ExtraModelsFromLoading<RootModel>;

export const store = init<RootModel, FullModel>({
  name: 'apollogix-ff-store',
  models,
  plugins: [
    loading(),
    persist({
      key: 'root',
      storage,
      debug: false,
      whitelist: ['auth', 'user']
    }),
    immerPlugin(),
    selectPlugin({
      selectorCreator: createCachedSelector
    }),
    updatedPlugin()
  ],
  redux: {
    rootReducers: {
      RESET: state => {
        const _clone = clone(state);
        for (const key in _clone) {
          _clone[key] = undefined;
        }

        return {
          ..._clone
        };
      }
    }
  }
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;
