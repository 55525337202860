import {
  UseCUDMutationOptions,
  UseGenericMutationOptions,
  UseMainCUDMutationOptions
} from '@hooks-dto';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { ENDPOINTS, IssueStatus, QUERY_KEYS, tableRef } from '@constants';
import APIManager from '@services';
import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

import { useMutationEnhancer } from './useRequestProcessor';

import { useUser } from '../auth';

export const useGenericMutationEnhancer = <T = unknown>(
  options: UseGenericMutationOptions
) => {
  const {
    endPoint,
    method,
    webTable,
    dependentWebTable,
    returnedData,
    isPage,
    onSuccess,
    noDismiss
  } = options;

  return useMutationEnhancer<boolean | any | undefined, T>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: generatePath(endPoint, data as any),
        method,
        showToast: true,
        body: method === 'DELETE' ? { ids: data } : data
      });
      return (returnedData && method === 'POST') || isPage
        ? res.data
        : res.success;
    },
    onSuccess: (data, variables) => {
      if (data) {
        if (webTable) {
          tableRef?.[webTable]?.init?.();
        }
        dependentWebTable?.forEach(i => {
          tableRef?.[i]?.init?.();
        });
        if (!noDismiss) {
          UIUtils.popup.dismiss();
        }

        onSuccess?.({ data, variables, options });
      }
    },
    mutationKeys: options?.mutationKeys
  });
};

export const useCUDMutationEnhancer = <T = unknown>(
  options: UseCUDMutationOptions
) => {
  const createMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'POST'
  });

  const updateMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'PUT',
    endPoint: `${options.endPoint}/:id`
  });

  const updateCreditNoteMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'PUT',
    endPoint: `${options.endPoint}/:id/credit-note`
  });

  const deleteMutation = useGenericMutationEnhancer<number[]>({
    ...options,
    method: 'DELETE',
    endPoint: `${options.endPoint}/bulk-delete`
  });

  const deleteOneMutation = useGenericMutationEnhancer<number[]>({
    ...options,
    method: 'DELETE',
    endPoint: `${options.endPoint}/:id`
  });

  const defaultMutation = useGenericMutationEnhancer<any>({
    ...options,
    method: 'PUT',
    endPoint: `${options.endPoint}/:id/default`
  });

  const unreconciledMutation = useGenericMutationEnhancer<T>({
    ...options,
    method: 'POST',
    endPoint: `${options.endPoint}/:id/un-reconcile`
  });

  return {
    createMutation,
    updateMutation,
    updateCreditNoteMutation,
    deleteMutation,
    deleteOneMutation,
    defaultMutation,
    unreconciledMutation,
    modifyLoading:
      createMutation.isLoading ||
      updateMutation.isLoading ||
      updateCreditNoteMutation.isLoading
  };
};

export const usePageCUDMutationEnhancer = <T = unknown>(
  options: UseMainCUDMutationOptions
) => {
  const { queryKey, redirectPath, noRedirect, ...rest } = options;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { branch } = useUser();

  const customEndPointCreateMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    isPage: false,
    ...rest,
    endPoint: `${options.endPoint}/:id/:subEndPoint`,
    method: 'POST'
  });

  const createMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data, variables: vars }) => {
      if (data?.id) {
        const p = vars?.redirectPath
          ? `${vars.redirectPath}/${data.id}`
          : `../${data.id}`;
        navigate(p, { replace: true });
      }
    },
    isPage: true,
    ...rest,
    method: 'POST'
  });

  const updateMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.setQueriesData(
          { queryKey: [queryKey], type: 'active' },
          data
        );
      }
    },
    isPage: true,
    ...rest,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id`
  });

  const updateNextMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    isPage: false,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/next-status`
  });

  const updateStatusMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    isPage: false,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/status`
  });

  const updateStatusIssuedApproveMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    isPage: false,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/issued-completed-invoice`
  });

  const updateCreditNoteMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.setQueriesData(
          { queryKey: [queryKey], type: 'active' },
          data
        );
      }
    },
    isPage: true,
    ...rest,
    method: 'PUT',
    endPoint: `${options.endPoint}/:id/credit-note`
  });

  const createCreditNoteMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data?.id) {
        navigate(`../${data.id}`, { replace: true });
      }
    },
    isPage: true,
    ...rest,
    endPoint: `${options.endPoint}/credit-note`,
    method: 'POST'
  });

  const updateBackMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    isPage: false,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/previous-status`
  });

  const attachMutation = useGenericMutationEnhancer<T>({
    ...rest,
    method: 'POST',
    endPoint: `${rest.endPoint}/attach`
  });

  const detachMutation = useGenericMutationEnhancer<T>({
    ...rest,
    method: 'POST',
    endPoint: `${rest.endPoint}/detach`
  });

  const deleteMutation = useGenericMutationEnhancer<number[]>({
    onSuccess: () => {
      if (!noRedirect) {
        if (redirectPath) {
          navigate(redirectPath, { replace: true });
        } else if (rest.webTable && !tableRef?.[rest.webTable]) {
          navigate(-1);
        }
      }
    },
    ...rest,
    method: 'DELETE',
    endPoint: `${rest.endPoint}/bulk-delete`
  });

  const duplicateMutation = useGenericMutationEnhancer<{
    ids: number[];
    branchId?: number;
  }>({
    onSuccess: ({ data, variables }) => {
      if (!tableRef?.[rest.webTable] && data?.[0]?.id) {
        if (!variables.branchId || variables.branchId === branch?.id) {
          navigate(`../${data[0].id}`, { replace: true });
        }
      }
    },
    isPage: true,
    ...rest,
    method: 'POST',
    endPoint: `${rest.endPoint}/duplicate`
  });

  const cancelMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/cancel-booking`
  });

  const cancelInvoiceMutation = useGenericMutationEnhancer<T>({
    onSuccess: ({ data }) => {
      if (data && queryKey) {
        queryClient.invalidateQueries([queryKey], { type: 'active' });
      }
    },
    ...rest,
    method: 'PUT',
    endPoint: `${rest.endPoint}/:id/cancel`
  });

  return {
    createMutation,
    updateMutation,
    updateStatusMutation,
    updateStatusIssuedApproveMutation,
    updateNextMutation,
    updateBackMutation,
    updateCreditNoteMutation,
    createCreditNoteMutation,
    deleteMutation,
    duplicateMutation,
    detachMutation,
    attachMutation,
    cancelMutation,
    cancelInvoiceMutation,
    customEndPointCreateMutation,
    modifyLoading:
      createMutation.isLoading ||
      updateMutation.isLoading ||
      updateStatusMutation.isLoading ||
      updateStatusIssuedApproveMutation.isLoading ||
      updateNextMutation.isLoading ||
      updateBackMutation.isLoading ||
      cancelMutation.isLoading ||
      cancelInvoiceMutation.isLoading ||
      customEndPointCreateMutation.isLoading,
    isLoading:
      createMutation.isLoading ||
      updateMutation.isLoading ||
      updateStatusMutation.isLoading ||
      updateStatusIssuedApproveMutation.isLoading ||
      updateNextMutation.isLoading ||
      updateBackMutation.isLoading ||
      deleteMutation.isLoading ||
      duplicateMutation.isLoading ||
      detachMutation.isLoading ||
      attachMutation.isLoading ||
      cancelMutation.isLoading ||
      cancelInvoiceMutation.isLoading ||
      customEndPointCreateMutation.isLoading
  };
};

export const useAlertMutationEnhancer = (options: {
  mutation: ReturnType<typeof useGenericMutationEnhancer<any>>;
  message?: string;
  okLabel?: string;
}): ReturnType<typeof useGenericMutationEnhancer<any>> & {
  onAlert: (
    mParams: any,
    onSuccess?: () => void,
    onSettled?: () => void
  ) => void;
  onDelete: (
    mParams: any,
    onSuccess?: () => void,
    onSettled?: () => void
  ) => void;
  deleteLoading: boolean;
} => {
  const { mutation, message, okLabel } = options;
  const { mutate, ...rest } = mutation;

  const onDelete = useCallback(
    (mParams: any[], onSuccess?: () => void, onSettled?: () => void) => {
      const ids = mParams.map(i => i.id);

      UIUtils.alert.open({
        message: message || trans('message_delete', { count: ids.length }),
        buttons: [
          {
            title: okLabel || trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids, { onSuccess, onSettled })
          },
          {
            title: trans('cancel'),
            onPress: onSettled ? onSettled : undefined
          }
        ]
      });
    },
    [message, mutate, okLabel]
  );

  return {
    onAlert: onDelete,
    onDelete,
    mutate,
    deleteLoading: rest.isLoading,
    ...rest
  };
};

export const useUpdateStatusIssue = () => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: string | number; status: IssueStatus }
  >({
    mutationKeys: [[QUERY_KEYS.issue]],
    mutationFn: async mParams => {
      const { id, status } = mParams;
      const res = await APIManager.request({
        url: ENDPOINTS.issue(':id/update-status', { id }),
        method: 'PUT',
        body: {
          issueStatus: status
        }
      });

      return res.success;
    }
  });
};
