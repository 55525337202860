import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React, { memo, useCallback, useState } from 'react';
import { KButton, KContainer } from 'uikit';

import { TableName } from '@constants';
import StorageEnhance from 'utils/storage';

export const CustomButtonComponent = memo(
  ({
    data,
    api,
    colDef,
    onEdit,
    onDelete,
    ...props
  }: ICellRendererParams<any> & {
    onEdit: (params: ICellRendererParams) => void;
    onDelete: (params: ICellRendererParams) => void;
  }) => {
    const { isCreditNote, hiddenDuplicate } = colDef?.cellRendererParams || {
      isCreditNote: false,
      hiddenDuplicate: false
    };

    const _onDelete = useCallback(() => {
      if (onDelete && data?.id) {
        onDelete({ ...props, data, api });
      } else {
        api.applyTransaction({ remove: [data] });
      }
    }, [api, data, onDelete, props]);

    const _onEdit = useCallback(() => {
      if (data?.action === 'addLastRow') {
        api?.startEditingCell({
          rowIndex: props.node.rowIndex as number,
          colKey: 'chargeCode'
        });
      } else {
        onEdit({ ...props, data, api });
      }
    }, [api, data, onEdit, props]);

    const _onDuplicate = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = data || {};
      api.applyTransaction({
        add: [rest],
        addIndex: Number(props.node.rowIndex) + 1
      });
    }, [api, data, props.node.rowIndex]);

    return (
      <KContainer.View
        dp="flex"
        row
        justifyContent="center"
        alignItems="center"
        height={'100%'}
      >
        <KButton.Icon
          ref={ref => {
            if (!ref) return;
            ref.onclick = e => {
              e.stopPropagation();
              _onDelete();
            };
          }}
          icon="Delete"
          size="sm"
          avoidParentPress
        />
        <div hidden={isCreditNote || hiddenDuplicate}>
          <KButton.Icon
            ref={ref => {
              if (!ref) return;
              ref.onclick = e => {
                e.stopPropagation();
                _onDuplicate();
              };
            }}
            icon="ContentCopy"
            size="sm"
            avoidParentPress
          />
        </div>
        <div hidden>
          <KButton.Icon icon="EditOutlined" size="sm" onPress={_onEdit} />
        </div>
      </KContainer.View>
    );
  }
);

export const RenderShowHideColumns = (props: {
  columnDefs: any;
  setColumnsVisible: (colId: any, val: boolean) => void;
  tableName: TableName;
}) => {
  const columnState = StorageEnhance.get('columnState');
  const _columnState = columnState?.[props.tableName] ?? props.columnDefs;
  const _columnDefs = _columnState.filter((item: any) => item.headerName);
  const [columnDefs, setColumnDefs] = useState(_columnDefs);
  return (
    <>
      {columnDefs && (
        <Box padding="0.5rem">
          {columnDefs?.map((item: any, idx: number) => {
            return (
              <div>
                <FormControlLabel
                  key={item.colId}
                  control={
                    <Checkbox
                      checked={!item.hide}
                      onChange={e => {
                        props.setColumnsVisible([item.colId], e.target.checked);
                        setColumnDefs((prev: any) => {
                          const temp = [...prev];
                          temp[idx].hide = !e.target.checked;
                          StorageEnhance.set('columnState', {
                            ...columnState,
                            [props.tableName]: temp
                          });
                          return temp;
                        });
                      }}
                    />
                  }
                  label={item.headerName}
                />
              </div>
            );
          })}
        </Box>
      )}
    </>
  );
};
