"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.KRadiusValue = exports.KSpacingValue = exports.useTheme = exports.TypoHelper = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const constants_1 = require("../constants");
require("@fontsource/roboto/300.css");
require("@fontsource/roboto/400.css");
require("@fontsource/roboto/500.css");
require("@fontsource/roboto/700.css");
class Typography {
    constructor() {
        this._appearance = 'light';
        this._KFontscale = 1;
        this.Page = {};
        this.H1 = {};
        this.H2 = {};
        this.H3 = {};
        this.H4 = {};
        this.H5 = {};
        this.H6 = {};
        this.Card = {};
        this.BreadcrumbTitle = {};
        this.TableHeader = {};
        this.Text4xLgBold = {};
        this.Text3xLgBold = {};
        this.Text2xLgBold = {};
        this.TextXLgBold = {};
        this.TextLgBold = {};
        this.TextXMdBold = {};
        this.TextMdBold = {};
        this.TextXNmBold = {};
        this.TextNmBold = {};
        this.TextSmBold = {};
        this.TextXsBold = {};
        this.Text2XsBold = {};
        this.Text4xLgMedium = {};
        this.Text3xLgMedium = {};
        this.Text2xLgMedium = {};
        this.TextXLgMedium = {};
        this.TextLgMedium = {};
        this.TextXMdMedium = {};
        this.TextMdMedium = {};
        this.TextXNmMedium = {};
        this.TextNmMedium = {};
        this.TextSmMedium = {};
        this.TextXsMedium = {};
        this.Text2XsMedium = {};
        this.Text4xLgNormal = {};
        this.Text3xLgNormal = {};
        this.Text2xLgNormal = {};
        this.TextXLgNormal = {};
        this.TextLgNormal = {};
        this.TextXMdNormal = {};
        this.TextMdNormal = {};
        this.TextXNmNormal = {};
        this.TextNmNormal = {};
        this.TextSmNormal = {};
        this.TextXsNormal = {};
        this.Text2XsNormal = {};
        this.getColor = () => this._appearance === 'light' ? constants_1.KColors.black : constants_1.KColors.white;
        this.calSize = (size) => {
            return TypoHelper.scaleFont(size * this._KFontscale);
        };
        this.generateTextStyle = (KFontsize, fontWeight, lineHeight, factor = 1, customStyle = {}) => (Object.assign({ fontFamily: 'Roboto', fontSize: this.calSize(KFontsize) * factor, fontWeight, 
            // lineHeight,
            color: this.getColor() }, customStyle));
    }
    get appearance() {
        return this._appearance;
    }
    get KFontscale() {
        return this._KFontscale;
    }
    updateValue(appearance, KFontscale) {
        this._appearance = appearance;
        this._KFontscale = KFontscale;
        this.H1 = this.generateTextStyle(36, constants_1.KFontWeights.bold, 1.2);
        this.H2 = this.generateTextStyle(32, constants_1.KFontWeights.bold, 1.2);
        this.H3 = this.generateTextStyle(28, constants_1.KFontWeights.bold, 1.2);
        this.H4 = this.generateTextStyle(24, constants_1.KFontWeights.bold, 1.2);
        this.H5 = this.generateTextStyle(20, constants_1.KFontWeights.bold, 1.2);
        this.H6 = this.generateTextStyle(16, constants_1.KFontWeights.bold, 1.5);
        this.Card = {
            color: this._appearance === 'light' ? constants_1.KColors.black : constants_1.KColors.white,
            background: this._appearance === 'light' ? constants_1.KColors.white : '#424242',
            wordWrap: 'break-word',
            boxShadow: `0 4px 4px ${constants_1.KColors.overlay}`,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        };
        this.BreadcrumbTitle = this.generateTextStyle(18, constants_1.KFontWeights.medium, 1.4);
        this.TableHeader = this.generateTextStyle(12, constants_1.KFontWeights.medium, 1.75);
        this.Text4xLgBold = this.generateTextStyle(28, constants_1.KFontWeights.bold, 1.4);
        this.Text3xLgBold = this.generateTextStyle(24, constants_1.KFontWeights.bold, 1.4);
        this.Text2xLgBold = this.generateTextStyle(20, constants_1.KFontWeights.bold, 1.4);
        this.TextXLgBold = this.generateTextStyle(18, constants_1.KFontWeights.bold, 1.4);
        this.TextLgBold = this.generateTextStyle(16, constants_1.KFontWeights.bold, 1.4);
        this.TextXMdBold = this.generateTextStyle(15, constants_1.KFontWeights.bold, 1.4);
        this.TextMdBold = this.generateTextStyle(14, constants_1.KFontWeights.bold, 1.4);
        this.TextXNmBold = this.generateTextStyle(13, constants_1.KFontWeights.bold, 1.5);
        this.TextNmBold = this.generateTextStyle(12, constants_1.KFontWeights.bold, 1.5);
        this.TextSmBold = this.generateTextStyle(11, constants_1.KFontWeights.bold, 1.4);
        this.TextXsBold = this.generateTextStyle(10, constants_1.KFontWeights.bold, 1.4);
        this.Text2XsBold = this.generateTextStyle(8, constants_1.KFontWeights.bold, 1.4);
        this.Text4xLgMedium = this.generateTextStyle(28, constants_1.KFontWeights.medium, 1.4);
        this.Text3xLgMedium = this.generateTextStyle(24, constants_1.KFontWeights.medium, 1.4);
        this.Text2xLgMedium = this.generateTextStyle(20, constants_1.KFontWeights.medium, 1.4);
        this.TextXLgMedium = this.generateTextStyle(18, constants_1.KFontWeights.medium, 1.4);
        this.TextLgMedium = this.generateTextStyle(16, constants_1.KFontWeights.medium, 1.4);
        this.TextXMdMedium = this.generateTextStyle(15, constants_1.KFontWeights.medium, 1.4);
        this.TextMdMedium = this.generateTextStyle(14, constants_1.KFontWeights.medium, 1.4);
        this.TextXNmMedium = this.generateTextStyle(13, constants_1.KFontWeights.medium, 1.5);
        this.TextNmMedium = this.generateTextStyle(12, constants_1.KFontWeights.medium, 1.5);
        this.TextSmMedium = this.generateTextStyle(11, constants_1.KFontWeights.medium, 1.4);
        this.TextXsMedium = this.generateTextStyle(10, constants_1.KFontWeights.medium, 1.4);
        this.Text2XsMedium = this.generateTextStyle(8, constants_1.KFontWeights.medium, 1.4);
        this.Text4xLgNormal = this.generateTextStyle(28, constants_1.KFontWeights.regular, 1.4);
        this.Text3xLgNormal = this.generateTextStyle(24, constants_1.KFontWeights.regular, 1.4);
        this.Text2xLgNormal = this.generateTextStyle(20, constants_1.KFontWeights.regular, 1.4);
        this.TextXLgNormal = this.generateTextStyle(18, constants_1.KFontWeights.regular, 1.4);
        this.TextLgNormal = this.generateTextStyle(16, constants_1.KFontWeights.regular, 1.4);
        this.TextXMdNormal = this.generateTextStyle(15, constants_1.KFontWeights.regular, 1.4);
        this.TextMdNormal = this.generateTextStyle(14, constants_1.KFontWeights.regular, 1.4);
        this.TextXNmNormal = this.generateTextStyle(13, constants_1.KFontWeights.regular, 1.5);
        this.TextNmNormal = this.generateTextStyle(12, constants_1.KFontWeights.regular, 1.5);
        this.TextSmNormal = this.generateTextStyle(11, constants_1.KFontWeights.regular, 1.4);
        this.TextXsNormal = this.generateTextStyle(10, constants_1.KFontWeights.regular, 1.4);
        this.Text2XsNormal = this.generateTextStyle(8, constants_1.KFontWeights.regular, 1.4);
    }
}
const instance = new Typography();
class TypoHelper {
}
exports.TypoHelper = TypoHelper;
_a = TypoHelper;
TypoHelper.isNullOrUndefined = (item) => item === null || item === undefined || item === 'undefined';
TypoHelper.removeObjectProperties = (obj, fields) => {
    for (const f of fields) {
        delete obj[f];
    }
};
TypoHelper.destructLayoutProps = (props = {}) => {
    const style = {};
    if (props.dp) {
        style.display = props.dp;
        if (props.dp === 'flex') {
            style.flexDirection = 'column';
        }
    }
    delete props.dp;
    let direction = props.row ? 'row' : '';
    if (direction && props.reverse) {
        direction += '-reverse';
    }
    if (direction) {
        style.display = 'flex';
        style.flexDirection = direction;
    }
    delete props.row;
    delete props.reverse;
    delete props.direction;
    if (props.center) {
        style.alignItems = 'center';
        style.justifyContent = 'center';
    }
    else {
        if (props.alignItems) {
            style.alignItems =
                props.alignItems === true ? 'center' : props.alignItems;
        }
        if (props.justifyContent) {
            style.justifyContent =
                props.justifyContent === true ? 'center' : props.justifyContent;
        }
    }
    delete props.center;
    delete props.justifyContent;
    delete props.alignItems;
    if (props.alignSelf) {
        style.alignSelf = props.alignSelf === true ? 'center' : props.alignSelf;
    }
    delete props.alignSelf;
    if (props.flex) {
        style.flex = props.flex === true ? 1 : props.flex;
    }
    delete props.flex;
    if (props.flexW) {
        style.flexWrap = props.flexW;
    }
    delete props.flexW;
    if (props.flexS) {
        style.flexShrink = props.flexS;
    }
    delete props.flexS;
    if (props.flexG) {
        style.flexGrow = props.flexG;
    }
    delete props.flexG;
    if (props.position) {
        style.position = props.position;
    }
    delete props.position;
    return style;
};
TypoHelper.destructSpacingProps = (props = {}) => {
    const style = {};
    if (!_a.isNullOrUndefined(props.margin)) {
        style.margin = _a.mapKSpacingToNumber(props.margin);
    }
    if (!_a.isNullOrUndefined(props.marginV)) {
        style.marginTop = _a.mapKSpacingToNumber(props.marginV);
        style.marginBottom = _a.mapKSpacingToNumber(props.marginV);
    }
    if (!_a.isNullOrUndefined(props.marginH)) {
        style.marginLeft = _a.mapKSpacingToNumber(props.marginH);
        style.marginRight = _a.mapKSpacingToNumber(props.marginH);
    }
    if (!_a.isNullOrUndefined(props.marginL)) {
        style.marginLeft = _a.mapKSpacingToNumber(props.marginL);
    }
    if (!_a.isNullOrUndefined(props.marginR)) {
        style.marginRight = _a.mapKSpacingToNumber(props.marginR);
    }
    if (!_a.isNullOrUndefined(props.marginB)) {
        style.marginBottom = _a.mapKSpacingToNumber(props.marginB);
    }
    if (!_a.isNullOrUndefined(props.marginT)) {
        style.marginTop = _a.mapKSpacingToNumber(props.marginT);
    }
    if (!_a.isNullOrUndefined(props.padding)) {
        style.padding = _a.mapKSpacingToNumber(props.padding);
    }
    if (!_a.isNullOrUndefined(props.paddingV)) {
        style.paddingTop = _a.mapKSpacingToNumber(props.paddingV);
        style.paddingBottom = _a.mapKSpacingToNumber(props.paddingV);
    }
    if (!_a.isNullOrUndefined(props.paddingH)) {
        style.paddingLeft = _a.mapKSpacingToNumber(props.paddingH);
        style.paddingRight = _a.mapKSpacingToNumber(props.paddingH);
    }
    if (!_a.isNullOrUndefined(props.paddingL)) {
        style.paddingLeft = _a.mapKSpacingToNumber(props.paddingL);
    }
    if (!_a.isNullOrUndefined(props.paddingR)) {
        style.paddingRight = _a.mapKSpacingToNumber(props.paddingR);
    }
    if (!_a.isNullOrUndefined(props.paddingB)) {
        style.paddingBottom = _a.mapKSpacingToNumber(props.paddingB);
    }
    if (!_a.isNullOrUndefined(props.paddingT)) {
        style.paddingTop = _a.mapKSpacingToNumber(props.paddingT);
    }
    _a.removeObjectProperties(props, [
        'margin',
        'marginH',
        'marginV',
        'marginL',
        'marginR',
        'marginT',
        'marginB',
        'padding',
        'paddingH',
        'paddingV',
        'paddingL',
        'paddingR',
        'paddingT',
        'paddingB'
    ]);
    if (!_a.isNullOrUndefined(props.size)) {
        style.width = props.size;
        style.height = props.size;
    }
    if (!_a.isNullOrUndefined(props.minH)) {
        style.minHeight = props.minH;
    }
    if (!_a.isNullOrUndefined(props.maxH)) {
        style.maxHeight = props.maxH;
    }
    if (!_a.isNullOrUndefined(props.minW)) {
        style.minWidth = props.minW;
    }
    if (!_a.isNullOrUndefined(props.maxW)) {
        style.maxWidth = props.maxW;
    }
    if (!_a.isNullOrUndefined(props.width)) {
        style.width = props.width;
    }
    if (!_a.isNullOrUndefined(props.height)) {
        style.height = props.height;
    }
    _a.removeObjectProperties(props, [
        'size',
        'minH',
        'maxH',
        'minW',
        'maxW',
        'width',
        'height'
    ]);
    if (!_a.isNullOrUndefined(props.gap)) {
        style.gap = props.gap;
    }
    delete props.gap;
    return style;
};
TypoHelper.destructStylingProps = (props = {}) => {
    const style = {};
    if (props.background) {
        style.background = props.background;
    }
    delete props.background;
    if (!_a.isNullOrUndefined(props.br)) {
        style.borderRadius = _a.mapKRadiusToNumber(props.br);
    }
    if (!_a.isNullOrUndefined(props.brTL)) {
        style.borderTopLeftRadius = _a.mapKRadiusToNumber(props.brTL);
    }
    if (!_a.isNullOrUndefined(props.brTR)) {
        style.borderTopRightRadius = _a.mapKRadiusToNumber(props.brTR);
    }
    if (!_a.isNullOrUndefined(props.brBL)) {
        style.borderBottomLeftRadius = _a.mapKRadiusToNumber(props.brBL);
    }
    if (!_a.isNullOrUndefined(props.brBR)) {
        style.borderBottomRightRadius = _a.mapKRadiusToNumber(props.brBR);
    }
    _a.removeObjectProperties(props, ['br', 'brTL', 'brTR', 'brBL', 'brBR']);
    if (props.brW) {
        style.borderWidth = props.brW;
        style.borderStyle = 'solid';
    }
    if (props.brBW) {
        style.borderBottomWidth = props.brBW;
        style.borderBottomStyle = 'solid';
    }
    if (props.brTW) {
        style.borderTopWidth = props.brTW;
        style.borderTopStyle = 'solid';
    }
    if (props.brLW) {
        style.borderLeftWidth = props.brLW;
        style.borderLeftStyle = 'solid';
    }
    if (props.brRW) {
        style.borderRightWidth = props.brRW;
        style.borderRightStyle = 'solid';
    }
    _a.removeObjectProperties(props, ['brW', 'brBW', 'brTW', 'brLW', 'brRW']);
    if (props.brS) {
        style.borderStyle = props.brS;
    }
    delete props.brS;
    if (props.brC) {
        style.borderColor = props.brC;
    }
    if (props.brBC) {
        style.borderBottomColor = props.brBC;
    }
    if (props.brTC) {
        style.borderTopColor = props.brTC;
    }
    if (props.brLC) {
        style.borderLeftColor = props.brLC;
    }
    if (props.brRC) {
        style.borderRightColor = props.brRC;
    }
    _a.removeObjectProperties(props, ['brC', 'brBC', 'brTC', 'brLC', 'brRC']);
    if (props.overflow) {
        style.overflow = props.overflow === true ? 'auto' : props.overflow;
    }
    if (props.overflowY) {
        style.overflowY = props.overflowY === true ? 'auto' : props.overflowY;
    }
    if (props.overflowX) {
        style.overflowX = props.overflowX === true ? 'auto' : props.overflowX;
    }
    delete props.overflow;
    delete props.overflowY;
    delete props.overflowX;
    if (props.pointer) {
        style.cursor = 'pointer';
    }
    delete props.pointer;
    return style;
};
TypoHelper.destructTextProps = (props = {}) => {
    const style = {};
    if (props.color) {
        style.color = props.color;
    }
    delete props.color;
    if (props.textAlign) {
        style.textAlign = props.textAlign === true ? 'center' : props.textAlign;
    }
    delete props.textAlign;
    if (props.underlineColor) {
        style.textDecorationColor = props.underlineColor;
    }
    delete props.underlineColor;
    if (props.underline) {
        style.textDecorationLine = props.underline ? 'underline' : undefined;
    }
    delete props.underline;
    if (props.italic) {
        style.fontStyle = 'italic';
    }
    delete props.italic;
    if (props.textTransform) {
        style.textTransform = props.textTransform;
    }
    delete props.textTransform;
    if (props.numberOfLines) {
        style.overflow = 'hidden';
        style.textOverflow = 'ellipsis';
        style.display = '-webkit-box';
        style.WebkitLineClamp = props.numberOfLines;
        style.lineClamp = props.numberOfLines;
        style.WebkitBoxOrient = 'vertical';
    }
    delete props.numberOfLines;
    const flatten = Object.assign(Object.assign({}, style), props.style);
    return flatten;
};
TypoHelper.destructPropsToStyle = (props) => {
    const layout = _a.destructLayoutProps(props);
    const spacing = _a.destructSpacingProps(props);
    const styling = _a.destructStylingProps(props);
    const text = _a.destructTextProps(props);
    return {
        mStyle: {
            layout,
            spacing,
            styling,
            text
        },
        mProps: props
    };
};
TypoHelper.scaleFont = (KFontsize) => KFontsize;
TypoHelper.mapKSpacingToNumber = (spacing) => {
    var _b;
    if (typeof spacing === 'number') {
        return spacing;
    }
    if (_a.isNullOrUndefined(spacing)) {
        return 0;
    }
    const base = 16;
    let factor = 0;
    try {
        const factorString = ((_b = spacing === null || spacing === void 0 ? void 0 : spacing.split('rem')) === null || _b === void 0 ? void 0 : _b[0]) || '';
        const factorFloat = parseFloat(factorString);
        if (typeof factorFloat === 'number') {
            factor = factorFloat;
        }
    }
    catch (error) {
        //
    }
    return factor * base;
};
TypoHelper.mapKRadiusToNumber = (radius) => {
    const base = 4;
    switch (radius) {
        case 'x':
            return base;
        case '2x':
            return base * 2;
        case '3x':
            return base * 3;
        case '4x':
            return base * 4;
        case '6x':
            return base * 6;
        case 'round':
            return 10000;
        default:
            return 0;
    }
};
TypoHelper.ratio = 1.2;
TypoHelper.getKFontSizeByTypo = (typo = 'TextNmNormal') => {
    const style = instance[typo];
    return (0, lodash_1.get)(style, 'KFontsize', 12);
};
const useTheme = (theme = 'light', KFontscale = 1) => {
    const typo = (0, react_1.useMemo)(() => {
        instance.updateValue(theme, KFontscale);
        return instance;
    }, [theme, KFontscale]);
    return typo;
};
exports.useTheme = useTheme;
const KSpacingValue = {
    0: 0,
    '0rem': 0,
    '0.25rem': TypoHelper.mapKSpacingToNumber('0.25rem'),
    '0.5rem': TypoHelper.mapKSpacingToNumber('0.5rem'),
    '0.75rem': TypoHelper.mapKSpacingToNumber('0.75rem'),
    '1rem': TypoHelper.mapKSpacingToNumber('1rem'),
    '1.25rem': TypoHelper.mapKSpacingToNumber('1.25rem'),
    '1.5rem': TypoHelper.mapKSpacingToNumber('1.5rem'),
    '1.75rem': TypoHelper.mapKSpacingToNumber('1.75rem'),
    '2rem': TypoHelper.mapKSpacingToNumber('2rem'),
    '2.5rem': TypoHelper.mapKSpacingToNumber('2.5rem'),
    '3rem': TypoHelper.mapKSpacingToNumber('3rem'),
    '3.5rem': TypoHelper.mapKSpacingToNumber('3.5rem'),
    '4rem': TypoHelper.mapKSpacingToNumber('4rem'),
    '5rem': TypoHelper.mapKSpacingToNumber('5rem'),
    '6rem': TypoHelper.mapKSpacingToNumber('6rem'),
    '8rem': TypoHelper.mapKSpacingToNumber('8rem'),
    '10rem': TypoHelper.mapKSpacingToNumber('10rem'),
    '12rem': TypoHelper.mapKSpacingToNumber('12rem'),
    '14rem': TypoHelper.mapKSpacingToNumber('14rem'),
    '16rem': TypoHelper.mapKSpacingToNumber('16rem')
};
exports.KSpacingValue = KSpacingValue;
const KRadiusValue = {
    0: 0,
    x: TypoHelper.mapKRadiusToNumber('x'),
    '2x': TypoHelper.mapKRadiusToNumber('2x'),
    '3x': TypoHelper.mapKRadiusToNumber('3x'),
    '4x': TypoHelper.mapKRadiusToNumber('4x'),
    '6x': TypoHelper.mapKRadiusToNumber('6x'),
    round: TypoHelper.mapKRadiusToNumber('round')
};
exports.KRadiusValue = KRadiusValue;
exports.default = instance;
