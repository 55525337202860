import { IApInvoiceFormData, IArInvoiceFormData } from '@form-dto';
import { compact } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { KContainer, KGrid, KInput, KLabel, KTab } from 'uikit';

import {
  ConsolType,
  ENDPOINTS,
  ExpConsolType,
  ImpConsolType,
  SystemChargeCodeUnit
} from '@constants';
import NotesToClient from 'containers/Accounting/NotesToClient';
import { useFetchUserStaffBranch } from 'hooks';
import trans from 'translation';
import { UIUtils } from 'utils';

import { formaValCurrency } from './Shipment/Billing/helpers';

export const objCheckCalculate = {
  [SystemChargeCodeUnit.CON]: true,
  [SystemChargeCodeUnit.M3]: true,
  [SystemChargeCodeUnit.DAY]: true,
  [SystemChargeCodeUnit.KG]: true
};

export const strValInvoiceSummary = (currencyCode: string) => {
  const valSummary = useCallback(
    (labelInvoice: string, totalApInvoice: number, totalArInvoice: number) => {
      const label = `${trans(labelInvoice)} (${currencyCode || ''})`;

      const val = [totalApInvoice, totalArInvoice].join(' - ');
      return `${label}: ${val}`;
    },
    [currencyCode]
  );

  return { valSummary };
};

export const SummuraryAmount = ({
  screen,
  contentRight,
  type,
  disabled = false
}: {
  screen: 'AP' | 'AR';
  contentRight?: {
    subTotal: number;
    taxAmount: number;
    totalAmount: number;
    paymentCurrency: any;
    balance: string | number;
    collect: number;
  };
  type: 'AP_INVOICE' | 'AR_INVOICE' | 'PAYMENT_ADVICE' | 'RECEIVABLE_ADVICE';
  disabled?: boolean;
}) => {
  const {
    subTotal,
    taxAmount,
    totalAmount,
    paymentCurrency,
    balance,
    collect
  } = contentRight ?? {
    subTotal: 0,
    taxAmount: 0,
    totalAmount: 0,
    paymentCurrency: null,
    balance: 0,
    collect: 0
  };

  const methods = useFormContext<IArInvoiceFormData | IApInvoiceFormData>();

  const onShowNotesToClient = (v?: any) => {
    UIUtils.popup.open({
      title: trans('notes_to_client'),
      maxWidth: 'sm',
      content: () => {
        return <NotesToClient item={v} type={type} />;
      }
    });
  };

  return (
    <KGrid.Container justifyContent="space-between" marginTop={5}>
      <KGrid.Item xs={3}>
        <Controller
          name="accountingNote"
          control={methods.control}
          render={({ field }) => {
            return (
              <KInput.Autocomplete
                {...field}
                label={trans('notes_to_client')}
                apiURL={ENDPOINTS.notesToClient()}
                getOptionLabel={(o: any) => {
                  return o.title;
                }}
                apiParams={{
                  type
                }}
                disabled={disabled}
                onAddNew={onShowNotesToClient}
                onEdit={onShowNotesToClient}
                addNewKey="title"
                hasAddNew
                hasEdit
              />
            );
          }}
        />

        <Controller
          name="accountingNote"
          control={methods.control}
          render={({ field }) => {
            return (
              <KInput.TextField
                {...field}
                marginT={'0.75rem'}
                placeholder="Note Details"
                value={field?.value?.detail ?? ''}
                minRows={5}
                multiline
                disabled
              />
            );
          }}
        />
      </KGrid.Item>

      {contentRight && (
        <KGrid.Item xs={3}>
          <KContainer.View height={1} margin="0.5rem" background="black" />
          <KGrid.Container>
            <KGrid.Item xs={8}>
              <KLabel.Text textAlign="right" typo="TextNmBold" isParagraph>
                {trans('sub_total_in')} ({paymentCurrency?.code}):
              </KLabel.Text>

              <KLabel.Text
                textAlign="right"
                typo="TextNmBold"
                isParagraph
                marginV="0.25rem"
              >
                {trans('tax')}:
              </KLabel.Text>

              <KLabel.Text textAlign="right" typo="TextMdBold" isParagraph>
                {trans('total')}:
              </KLabel.Text>
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <KLabel.Text typo="TextNmBold" isParagraph>
                {formaValCurrency(subTotal)}
              </KLabel.Text>
              <KLabel.Text typo="TextNmBold" isParagraph marginV="0.25rem">
                {formaValCurrency(taxAmount)}
              </KLabel.Text>
              <KLabel.Text typo="TextMdBold" isParagraph>
                {formaValCurrency(totalAmount)}
              </KLabel.Text>
            </KGrid.Item>
          </KGrid.Container>

          <KContainer.View height={1} margin="0.5rem" background="black" />

          <KGrid.Container>
            <KGrid.Item xs={8}>
              <KLabel.Text textAlign="right" typo="TextMdBold" isParagraph>
                {screen === 'AR' ? trans('collect') : trans('paid')}:
              </KLabel.Text>
              <KLabel.Text textAlign="right" typo="TextMdBold" isParagraph>
                {trans('balance')}:
              </KLabel.Text>
            </KGrid.Item>
            <KGrid.Item xs={4}>
              <KLabel.Text typo="TextMdBold" isParagraph>
                {formaValCurrency(collect)}
              </KLabel.Text>
              <KLabel.Text typo="TextMdBold" isParagraph color="#0F857D">
                {formaValCurrency(totalAmount - collect)}
              </KLabel.Text>
            </KGrid.Item>
          </KGrid.Container>
        </KGrid.Item>
      )}
    </KGrid.Container>
  );
};

export const useImpExpTab = () => {
  const { currentBranch } = useFetchUserStaffBranch();

  const name = currentBranch?.department?.name ?? '';
  const isImp = name.includes('IMP');
  const isExp = name.includes('EXP');

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useMemo(() => {
    return compact([
      // { key: 'ALL', label: trans('all') },
      !isExp ? { key: 'IMP', label: trans('import') } : undefined,
      !isImp ? { key: 'EXP', label: trans('export') } : undefined,
      { key: 'DOM', label: trans('domestic') }
    ]);
  }, [isExp, isImp]);

  const tabOptions = useMemo(() => {
    return isImp ? ImpConsolType : isExp ? ExpConsolType : ConsolType;
  }, [isExp, isImp]);

  const tabData = useMemo(() => {
    return tabs?.[tabIndex]?.key;
  }, [tabIndex, tabs]);

  const Tab = useCallback(() => {
    return (
      <KTab.White
        tabs={tabs}
        tabIndex={tabIndex}
        onChangeTab={setTabIndex}
        kind="background"
        marginH="0.75rem"
        paddingH="0rem"
        spacing="0rem"
        marginT="0rem"
      />
    );
  }, [tabIndex, tabs]);

  return { Tab, tabData, tabOptions, isImp, isExp };
};
