import React, { useCallback } from 'react';
import { KColors } from 'uikit';

import RelatedPartyForm from 'pages/Client/RelatedParty/Form';
import DepartmentForm from 'pages/SystemSettings/Library/Administration/Department/Form';
import JobTitleForm from 'pages/SystemSettings/Library/Administration/JobTitle/Form';
import ClientCategoryForm from 'pages/SystemSettings/Library/Client/Category/Form';
import ClientStageForm from 'pages/SystemSettings/Library/Client/Stage/Form';
import ClientTypeForm from 'pages/SystemSettings/Library/Client/Type/Form';
import trans from 'translation';
import { UIUtils } from 'utils';

interface IProps {
  item?: any;
  clientId?: string | number;
  onDelete?: (listId: number[]) => void;
  tabName: string;
}

export const useDeleteHelper = (options: { name: string; mutate: any }) => {
  const { name, mutate } = options;

  const onDelete = useCallback(
    (data: any[], onSuccess?: () => void) => {
      const ids = data.map(i => i.id);

      const message = trans('message.delete_global', {
        count: data.length,
        name: trans(name).toLowerCase()
      });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids, { onSuccess })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [name, mutate]
  );

  return onDelete;
};

export const useDeleteOneHelper = (options: { name: string; mutate: any }) => {
  const { name, mutate } = options;

  const onDelete = useCallback(
    (id: number | string, onSuccess?: () => void) => {
      const message = trans('message.delete_global', {
        count: 1,
        name: trans(name).toLowerCase()
      });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate({ id: id }, { onSuccess })
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [name, mutate]
  );

  return onDelete;
};

export const onShowDepartment = (v?: any) => {
  UIUtils.popup.open({
    title: trans('department'),
    maxWidth: 'md',
    content: () => <DepartmentForm item={v} />
  });
};

export const onShowJobTitle = (v?: any) => {
  UIUtils.popup.open({
    title: trans('job_title'),
    maxWidth: 'md',
    content: () => <JobTitleForm item={v} />
  });
};

export const onShowClientCategory = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_category'),
    maxWidth: 'md',
    content: () => <ClientCategoryForm item={v} />
  });
};

export const onShowClientStage = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_stage'),
    maxWidth: 'md',
    content: () => <ClientStageForm item={v} />
  });
};

export const onShowAddress = (v?: any) => {
  window.open(`/admin/client/${v?.clientId}`, '_blank');
};

export const onShowClient = (v?: any) => {
  window.open(`/admin/client/${v?.id ?? 'new'}`, '_blank');
};

export const onShowClientType = (v?: any) => {
  UIUtils.popup.open({
    title: trans('client_type'),
    maxWidth: 'md',
    content: () => <ClientTypeForm item={v} />
  });
};

export const onShowRelatedParty = (Props: IProps) => {
  const { item, clientId, onDelete, tabName } = Props;
  UIUtils.popup.open({
    title: trans('related_party'),
    maxWidth: 'md',
    content: () => (
      <RelatedPartyForm
        item={item}
        clientId={clientId}
        onDelete={onDelete}
        tabName={tabName}
      />
    )
  });
};
