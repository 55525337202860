/* eslint-disable max-lines */
import {
  IApChargeCodeFormData,
  IApInvoiceFormData,
  IArChargeCodeFormData,
  IArInvoiceFormData,
  IConsolFormData,
  IPaymentAdviceFormData,
  IReceivableAdviceFormData,
  IRoutingFormData,
  IServiceFormData,
  IShipmentFormData,
  IWipFormData
} from '@form-dto';
import { get, omit, pick } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  AirOceanType,
  LegRoutingMode,
  LegRoutingModeAir,
  LegRoutingStatus,
  TIMEZONE_DEFAULT,
  InvoiceType,
  ARInvoiceStatus,
  APInvoiceStatus,
  WipServiceType,
  CalculatorType,
  PaymentMode,
  ConsolStatus,
  AgentType,
  ConsolType,
  OceanContainerMode,
  AirContainerMode,
  ShipmentStatus,
  DeliveryMode,
  InvoiceCategory,
  LevelMarkup,
  LinkedAccountType,
  LinkedAccountCode,
  AdviceStatus,
  AdviceType,
  ClientType,
  UIDataType,
  OriginDestinationType,
  TransportStatus,
  ArApType,
  ExchangeRateTypeALL
} from '@constants';
import { ServiceType } from '@constants/enum';
import { formatToFixed } from 'containers/FromWip/helpers';
import { Prototype } from 'core';
import {
  useCalculateWipQuantityWithUnit,
  useFetchLinkedChartOfAccount
} from 'hooks';
import { useFetchSettings } from 'hooks/ssLibrary/setting';
import { useImpExpTab } from 'pages/OceanFreight/helpers';
import { formaValCurrency } from 'pages/OceanFreight/Shipment/Billing/helpers';
import trans from 'translation';
import { KChip, KColors, KContainer } from 'uikit';
import { mappedConsolStatusData, mappedShipmentStatusData } from 'utils';

import {
  actionType,
  convertPersonInCharges,
  defaultPersonInChart
} from './common';
import RequestUtils from './request';
import ValidationUtils from './validation';

export const checkIsCsAccounting = (v?: InvoiceCategory) => {
  return v === InvoiceCategory.Accounting.toUpperCase();
};

export const checkIsSmAccounting = (v?: InvoiceCategory) => {
  return v === InvoiceCategory.Billing.toUpperCase();
};

export const checkIsSvAccounting = (v?: InvoiceCategory) => {
  return v === InvoiceCategory.Service.toUpperCase();
};

export const checkApWarningStatus = (v: APInvoiceStatus) => {
  const invalidStatus: { [key in any]: boolean } = {
    [APInvoiceStatus.APPROVED]: true,
    [APInvoiceStatus.COMPLETE]: true,
    [APInvoiceStatus.CANCEL]: true
  };
  return !invalidStatus[v];
};

/**
 *
 * @param id Current Record Id
 * @param freightMode Ocean | Air
 * @returns
 */
export const useNormalizeData = (
  id?: number | string,
  freightMode: AirOceanType = AirOceanType.Ocean
) => {
  const normalizeCsData = useCallback(
    (data: any) => {
      const {
        firstLoading,
        firstEtd,
        firstAtd,
        onboardDate,

        lastDischarge,
        lastEta,
        lastAta,

        ...rest
      } = data;

      const mParams = {
        id,
        freightMode,

        firstLoadingId: firstLoading?.id,
        firstLoadingTimezoneId: TIMEZONE_DEFAULT,
        firstEtd: Prototype.date.applyTz(
          Prototype.date.toMoment(firstEtd)?.format()
        ),
        firstAtd: firstAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(firstAtd)?.format())
          : undefined,
        onboardDate: onboardDate
          ? Prototype.date.applyTz(
              Prototype.date.toMoment(onboardDate)?.format()
            )
          : undefined,

        lastDischargeId: lastDischarge?.id,
        lastDischargeTimezoneId: TIMEZONE_DEFAULT,
        lastEta: Prototype.date.applyTz(
          Prototype.date.toMoment(lastEta)?.format()
        ),
        lastAta: lastAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(lastAta)?.format())
          : undefined,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'sendingAgent',
            'receivingAgent',
            'shippingLine',
            'airline',
            'coLoader',
            'oblReleasedType',
            'pblReleasedType',
            'serviceLevel',
            'freightPayment',
            'shippingLineAgent',
            'airlineAgent',
            'coLoaderAgent',
            'goodsCurrency'
          ],
          dateFields: [
            'shippingLineBookingDate',
            'airlineBookingDate',
            'coLoaderBookingDate'
          ],
          dateTimeFields: ['oblReleasedDate', 'pblReleasedDate']
        })
      };

      return mParams;
    },
    [freightMode, id]
  );

  const normalizeSmData = useCallback(
    (data: any) => {
      const {
        pol,
        polEtd,
        polAtd,

        pod,
        podEta,
        podAta,
        onboardDate,

        firstOrigin,
        firstEtd,
        firstAtd,

        finalDes,
        finalEta,
        finalAta,
        personInCharges,

        ...rest
      } = data;

      const newPersonInCharges =
        convertPersonInCharges(personInCharges).format1;

      const mParams = {
        id,
        freightMode,

        polId: pol?.id,
        polTimezoneId: TIMEZONE_DEFAULT,
        polEtd: Prototype.date.applyTz(
          Prototype.date.toMoment(polEtd)?.format()
        ),
        polAtd: polAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(polAtd)?.format())
          : undefined,
        onboardDate: onboardDate
          ? Prototype.date.applyTz(
              Prototype.date.toMoment(onboardDate)?.format()
            )
          : undefined,

        podId: pod?.id,
        podTimezoneId: TIMEZONE_DEFAULT,
        podEta: Prototype.date.applyTz(
          Prototype.date.toMoment(podEta)?.format()
        ),
        podAta: podAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(podAta)?.format())
          : undefined,

        firstOriginId: firstOrigin?.id,
        firstOriginTimezoneId: TIMEZONE_DEFAULT,
        firstEtd: firstEtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(firstEtd)?.format())
          : undefined,
        firstAtd: firstAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(firstAtd)?.format())
          : undefined,

        finalDesId: finalDes?.id,
        finalDesTimezoneId: TIMEZONE_DEFAULT,
        finalEta: finalEta
          ? Prototype.date.applyTz(Prototype.date.toMoment(finalEta)?.format())
          : undefined,
        finalAta: finalAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(finalAta)?.format())
          : undefined,
        ...newPersonInCharges,
        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'bookingClient',
            'quotation',
            'shipmentType',
            'incoterm',
            'releasedType',
            'serviceLevel',
            'accountReceivable',
            'releasedAgent',
            'customsBroker',
            'notifyParty',
            'consignor',
            'consignee',
            'goodsCurrency',
            'freightPayment'
          ],
          dateTimeFields: ['releasedDate', 'expiredDate']
        })
      };

      return mParams;
    },
    [freightMode, id]
  );

  const normalizeRtData = useCallback(
    (data: IRoutingFormData) => {
      const {
        pol,
        polEtd,
        polAtd,

        pod,
        podEta,
        podAta,
        onboardDate,

        order,
        pId,
        category,

        ...rest
      } = data;

      const mParams: any = {
        id,
        routingOrder: order,

        polId: pol?.id,
        polTimezoneId: TIMEZONE_DEFAULT,
        polEtd: Prototype.date.applyTz(
          Prototype.date.toMoment(polEtd)?.format()
        ),
        polAtd: polAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(polAtd)?.format())
          : undefined,
        onboardDate: onboardDate
          ? Prototype.date.applyTz(
              Prototype.date.toMoment(onboardDate)?.format()
            )
          : undefined,

        podId: pod?.id,
        podTimezoneId: TIMEZONE_DEFAULT,
        podEta: Prototype.date.applyTz(
          Prototype.date.toMoment(podEta)?.format()
        ),
        podAta: podAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(podAta)?.format())
          : undefined,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'shippingLineCarrier',
            'airline',
            'oblReleasedType',
            'pblReleasedType',
            'hblReleasedType',
            'type'
          ]
        }),
        dateTimeFields: [
          'oblReleasedDate',
          'pblReleasedDate',
          'hblReleasedDate'
        ]
      };

      if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else {
        mParams.shipmentId = pId;
      }

      return mParams;
    },
    [id]
  );

  const normalizeIvData = useCallback(
    (data: IArInvoiceFormData | IApInvoiceFormData) => {
      const {
        resources,
        dueDateType,
        category,
        pId,
        cId,
        arChargeCodes,
        apChargeCodes,
        arApType,

        ...rest
      } = data as any;

      const isAr = arApType === ArApType.Ar;

      const chargeCodes = isAr ? arChargeCodes : apChargeCodes;

      const _chargeCodes = (chargeCodes ?? []).map((i: any) => {
        return {
          ...i,
          paymentSubTotal: +formatToFixed(i?.paymentSubTotal),
          paymentTaxAmount: +formatToFixed(i?.paymentTaxAmount),
          paymentTotalAmount: +formatToFixed(i?.paymentTotalAmount)
        };
      });

      const payment = (_chargeCodes || []).reduce(
        (acc: any, cur: any) => {
          acc.paymentSubTotal = acc.paymentSubTotal + cur.paymentSubTotal;
          acc.paymentTaxAmount = acc.paymentTaxAmount + cur.paymentTaxAmount;
          acc.paymentTotalAmount =
            acc.paymentTotalAmount + cur.paymentTotalAmount;
          return acc;
        },
        {
          paymentSubTotal: 0,
          paymentTaxAmount: 0,
          paymentTotalAmount: 0
        }
      );

      const resourceIds = resources.map((o?: any) => o?.id);

      const mParams: any = {
        id,
        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'paymentCurrency',
            'paymentMode',
            // AR
            'accountReceivable',
            'arBank',
            'arAddress',
            'chartOfAccountReceivable',
            // AP
            'accountPayable',
            'companyBank',
            'accountPayableAddress',
            'chartOfAccountPayable',
            'accountingNote'
          ],
          dateFields: [
            'dueDate',
            'exchangeRateDate',
            'issuedDate',
            'refApInvoiceDate'
          ]
        }),
        ...payment,
        resourceIds,
        arChargeCodes: isAr ? _chargeCodes : undefined,
        apChargeCodes: !isAr ? _chargeCodes : undefined,
        dueDateType: dueDateType?.dueDateType,
        onBehalfClientId:
          rest.invoiceType === InvoiceType.OBH && !isAr
            ? apChargeCodes?.[0]?.onBehalfClient?.id
            : undefined
      };

      if (category === UIDataType.Sv) {
        mParams.serviceId = pId;
      } else if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else {
        mParams.shipmentId = pId;
        if (cId) {
          mParams.consolId = cId;
        }
      }

      return mParams;
    },
    [id]
  );

  const normalizeWipData = useCallback(
    (data: IWipFormData) => {
      const {
        wipPrices: _wipPrices,
        wipSubChargeCodes: _wipSubChargeCodes,

        category,
        pId,
        cId,

        ...rest
      } = data;

      const wipPrices = _wipPrices.map((o: any) => ({
        ...o,
        estTotal: Number(o?.quantity || 0) * Number(o?.unitPrice || 0)
      }));

      const wipSubChargeCodes = _wipSubChargeCodes.map((v: any) => {
        const { subChargeCode, ...subRest } = v;
        return {
          ...subRest,
          subChargeCodeId: subChargeCode?.id
        };
      });

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'apClient',
            'penaltyType',
            'chargeCode',
            'currency',
            'addrFrom',
            'addrTo',
            'containerTypeSize',
            'taxType',
            'chargeCodeUnit'
          ]
        }),
        wipPrices,
        wipSubChargeCodes
      };

      if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else if (category === UIDataType.Sm) {
        mParams.shipmentId = pId;
        if (cId) {
          mParams.consolId = cId;
        }
      } else {
        mParams.serviceId = pId;
      }

      return mParams;
    },
    [id]
  );

  const normalizeApChargeCodeData = useCallback(
    (data: IApChargeCodeFormData) => {
      const {
        apChargeCodeLevels: _apChargeCodeLevels,
        apSubChargeCodes: _apSubChargeCodes,

        category,
        pId,
        cId,

        ...rest
      } = data;

      const apChargeCodeLevels = _apChargeCodeLevels.map((o: any) => ({
        ...o,
        estTotal: Number(o?.quantity || 0) * Number(o?.unitPrice || 0)
      }));

      const apSubChargeCodes = _apSubChargeCodes.map((v: any) => {
        return {
          ...v,
          subChargeCodeId: v?.subChargeCode?.id,
          currencyId: v?.currency?.id
        };
      });

      if (rest.calculatorType === CalculatorType.Composite) {
        const seen = new Set();
        const isDup = apSubChargeCodes.some((v: any) => {
          if (seen.has(v.subChargeCodeId)) {
            return true;
          }
          seen.add(v.subChargeCodeId);
          return false;
        });

        if (isDup) {
          toast.error(trans('message.duplicate_sub_charge_code'));
          return undefined;
        }
      }

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'currency',
            'apChargeCodeFrom',
            'apChargeCodeTo',
            'containerTypeSize',
            'taxType',
            'chargeCodeUnit'
          ]
        }),
        apChargeCodeLevels,
        apSubChargeCodes
      };

      if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else {
        mParams.shipmentId = pId;
        if (cId) {
          mParams.consolId = cId;
        }
      }

      return mParams;
    },
    [id]
  );

  const normalizeArChargeCodeData = useCallback(
    (data: IArChargeCodeFormData) => {
      const {
        arChargeCodeLevels: _arChargeCodeLevels,
        arSubChargeCodes: _arSubChargeCodes,

        category,
        pId,
        cId,

        ...rest
      } = data;

      const arChargeCodeLevels = _arChargeCodeLevels.map((o: any) => ({
        ...o,
        estTotal: Number(o?.quantity || 0) * Number(o?.unitPrice || 0)
      }));

      const arSubChargeCodes = _arSubChargeCodes.map((v: any) => {
        return {
          ...v,
          subChargeCodeId: v?.subChargeCode?.id,
          currencyId: v?.currency?.id
        };
      });

      if (rest.calculatorType === CalculatorType.Composite) {
        const seen = new Set();
        const isDup = arSubChargeCodes.some((v: any) => {
          if (seen.has(v.subChargeCodeId)) {
            return true;
          }
          seen.add(v.subChargeCodeId);
          return false;
        });

        if (isDup) {
          toast.error(trans('message.duplicate_sub_charge_code'));
          return undefined;
        }
      }

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'accountPayable',
            'currency',
            'chargeCode',
            'arChargeCodeFrom',
            'arChargeCodeTo',
            'containerTypeSize',
            'taxType',
            'chargeCodeUnit'
          ]
        }),
        arChargeCodeLevels,
        arSubChargeCodes
      };

      if (category === UIDataType.Cs) {
        mParams.consolId = pId;
      } else {
        mParams.shipmentId = pId;
        if (cId) {
          mParams.consolId = cId;
        }
      }

      return mParams;
    },
    [id]
  );

  const normalizeAdviceData = useCallback(
    (data: IReceivableAdviceFormData | IPaymentAdviceFormData) => {
      const { resources, adviceDetails, adviceType, ...rest } = data;

      const resourceIds = resources.map((o?: any) => o?.id);

      const isPayment = adviceType === AdviceType.Payment;

      const mParams: any = {
        id,

        ...RequestUtils.normalizeData({
          data: rest,
          idFields: [
            'currency',
            'paymentMode',
            'companyBank',
            'accountingNote',
            // Receivable
            'accountReceivable',
            'accountReceivableAddress',
            // Payment
            'accountPayable',
            'accountPayableAddress'
          ]
        }),
        resourceIds,
        paymentAdviceDetails: isPayment
          ? adviceDetails.map((val: any) => {
              return {
                apInvoiceId: val.id,
                id: val.id,
                amount: val.amount
              };
            })
          : [],
        receivableAdviceDetails: !isPayment
          ? adviceDetails.map((val: any) => {
              return {
                arInvoiceId: val.id,
                id: val.id,
                amount: val.amount
              };
            })
          : []
      };

      return mParams;
    },
    [id]
  );

  const normalizeSvData = useCallback(
    (data: IServiceFormData) => {
      const {
        firstEtd,
        firstAtd,
        onboardDate,

        finalEta,
        finalAta,

        polEtd,
        polAtd,

        podEta,
        podAta,

        isLinkedToSystemShipment,
        personInCharges,

        ...rest
      } = data;
      const fields = [
        'bookingClient',
        'serviceStatus',
        'transportServiceType',
        'isNewClient',
        'isTransport',
        'isHandling',
        'isCustoms',
        'isGoodsInsurance',
        'noteContent'
      ];

      const sParams = {
        ...RequestUtils.normalizeData({
          data: omit(rest, fields),
          idFields: [
            'consignor',
            'consignee',
            'accountReceivable',
            'customsBroker',
            'goodsCurrency',
            'hblReleasedType',
            'oblReleasedType',
            'pblReleasedType',
            'serviceLevel',
            'quotation',
            'incoterm',
            'freightPayment',
            'firstOrigin',
            'pol',
            'pod',
            'finalDes',
            'coLoader',
            'coLoaderAgent',
            'shippingLine',
            'shippingLineAgent',
            'airline',
            'airlineAgent'
          ],
          dateTimeFields: [
            'hblReleasedDate',
            'oblReleasedDate',
            'pblReleasedDate'
          ]
        }),

        firstOriginTimezoneId: TIMEZONE_DEFAULT,
        firstEtd: Prototype.date.applyTz(
          Prototype.date.toMoment(firstEtd)?.format()
        ),
        firstAtd: firstAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(firstAtd)?.format())
          : undefined,
        onboardDate: onboardDate
          ? Prototype.date.applyTz(
              Prototype.date.toMoment(onboardDate)?.format()
            )
          : undefined,

        polTimezoneId: TIMEZONE_DEFAULT,
        polEtd: Prototype.date.applyTz(
          Prototype.date.toMoment(polEtd)?.format()
        ),
        polAtd: polAtd
          ? Prototype.date.applyTz(Prototype.date.toMoment(polAtd)?.format())
          : undefined,

        podTimezoneId: TIMEZONE_DEFAULT,
        podEta: Prototype.date.applyTz(
          Prototype.date.toMoment(podEta)?.format()
        ),
        podAta: podAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(podAta)?.format())
          : undefined,

        finalDesTimezoneId: TIMEZONE_DEFAULT,
        finalEta: finalEta
          ? Prototype.date.applyTz(Prototype.date.toMoment(finalEta)?.format())
          : undefined,
        finalAta: finalAta
          ? Prototype.date.applyTz(Prototype.date.toMoment(finalAta)?.format())
          : undefined
      };

      const parasPersonInCharges =
        convertPersonInCharges(personInCharges).format2;

      const mParams = {
        id,

        generalService: isLinkedToSystemShipment ? undefined : sParams,
        ...parasPersonInCharges,
        ...RequestUtils.normalizeData({
          data: pick(rest, fields),
          idFields: ['bookingClient']
        })
      };
      return mParams;
    },
    [id]
  );

  return {
    normalizeCsData,
    normalizeSmData,
    normalizeRtData,
    normalizeIvData,
    normalizeWipData,
    normalizeApChargeCodeData,
    normalizeArChargeCodeData,
    normalizeAdviceData,
    normalizeSvData
  };
};

const dateNow = Prototype.date.now();

export const useNormalizeInitValues = (
  category: UIDataType = UIDataType.Cs,
  mode: AirOceanType = AirOceanType.Ocean
) => {
  const { data: setting } = useFetchSettings();

  // const { data: defAccounts } = useFetchLinkedChartOfAccount();

  const { isExp } = useImpExpTab();

  const normalizeCsInitValues = useCallback(
    (item?: any, branch?: any) => {
      const initValues: IConsolFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        agentType: item?.agentType ?? AgentType.Agent,
        consolType:
          item?.consolType ?? (isExp ? ConsolType.Export : ConsolType.Import),
        contMode:
          item?.contMode ??
          (mode === AirOceanType.Ocean
            ? OceanContainerMode.FCL
            : AirContainerMode.LSE_LCL),

        vessel: item?.vessel,
        voyage: item?.voyage ?? '',

        flight: item?.flight,

        firstLoading: item?.firstLoading,
        firstEtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.firstEtd)?.format()
        ),
        firstAtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.firstAtd)?.format()
        ),
        onboardDate: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.onboardDate)?.format()
        ),

        lastDischarge: item?.lastDischarge,
        lastEta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.lastEta)?.format()
        ),
        lastAta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.lastAta)?.format()
        ),

        oblNo: item?.oblNo ?? '',
        oblReleasedType: item?.oblReleasedType,
        oblReleasedDate: Prototype.date.toLocal(item?.oblReleasedDate),
        oblOriginal: item?.oblOriginal,
        oblCopy: item?.oblCopy,

        pblNo: item?.pblNo ?? '',
        pblReleasedType: item?.pblReleasedType,
        pblReleasedDate: Prototype.date.toLocal(item?.pblReleasedDate),
        pblOriginal: item?.pblOriginal,
        pblCopy: item?.pblCopy,

        serviceLevel: item?.serviceLevel,
        paymentMode: item?.paymentMode ?? PaymentMode.Prepaid,
        freightPayment: item?.freightPayment,

        isCharter: item?.isCharter ?? false,
        isCargoOnly: item?.isCargoOnly ?? false,
        isGoodsInsurance: item?.isGoodsInsurance ?? false,
        consolStatus: item?.consolStatus ?? ConsolStatus.Draft,

        branchName: item?.branch?.name ?? branch?.name,
        branchId: item?.branchId ?? branch?.id,

        sendingAgent: item?.sendingAgent,
        receivingAgent: item?.receivingAgent,

        shippingLine: item?.shippingLine,
        shippingLineBookingNo: item?.shippingLineBookingNo ?? '',
        shippingLineBookingDate: Prototype.date.toLocal(
          item?.shippingLineBookingDate
        ),
        shippingLineAgent: item?.shippingLineAgent,

        airline: item?.airline,
        airlineBookingNo: item?.airlineBookingNo ?? '',
        airlineBookingDate: Prototype.date.toLocal(item?.airlineBookingDate),
        airlineAgent: item?.airlineAgent,

        coLoader: item?.coLoader,
        coLoaderBookingNo: item?.coLoaderBookingNo ?? '',
        coLoaderBookingDate: Prototype.date.toLocal(item?.coLoaderBookingDate),
        coLoaderAgent: item?.coLoaderAgent,

        goodsHSCode: item?.goodsHSCode ?? '',
        goodsMarksNumbers: item?.goodsMarksNumbers ?? '',
        goodsValue: item?.goodsValue ?? '',
        goodsInsurance: item?.goodsInsurance ?? '',
        goodsCommodity: item?.goodsCommodity ?? '',
        goodsDescription: item?.goodsDescription ?? '',
        goodsCurrency: item?.goodsCurrency ?? setting?.currency,

        noteContent: item?.noteContent ?? '',
        jobOrderNumber: item?.jobOrderNumber ?? ''
      };

      return initValues;
    },
    [category, isExp, mode, setting?.currency]
  );

  const normalizeSmInitValues = useCallback(
    (item?: any, consol?: any) => {
      const initValues: IShipmentFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        shipmentNumber: item?.shipmentNumber ?? '',
        bookingClient: item?.bookingClient,
        quotation: item?.quotation,

        freightPayment: item?.freightPayment,
        refClientBookingNo: item?.refClientBookingNo ?? '',

        controlledConsolId: item?.controlledConsolId ?? consol?.id,
        arId: item?.arId,
        shipmentType: item?.shipmentType,
        type:
          consol?.consolType ??
          item?.type ??
          (isExp ? ConsolType.Export : ConsolType.Import),
        amsNo: item?.amsNo ?? '',
        ifsNo: item?.ifsNo ?? '',
        contMode:
          mode === AirOceanType.Ocean
            ? consol?.contMode === OceanContainerMode.FCL
              ? OceanContainerMode.FCL
              : OceanContainerMode.LCL
            : consol?.contMode === AirContainerMode.ULD_FCL
            ? AirContainerMode.ULD_FCL
            : AirContainerMode.LSE_LCL,
        incoterm: item?.incoterm,
        deliveryMode: item?.deliveryMode ?? DeliveryMode.CY_CY,
        hblNo: item?.hblNo ?? '',
        releasedDate: Prototype.date.toLocal(item?.releasedDate),
        hblOriginal: item?.hblOriginal ?? '',
        hblCopy: item?.hblCopy ?? '',
        releasedType: item?.releasedType,
        serviceLevel: item?.serviceLevel,

        shipmentStatus: item?.shipmentStatus ?? ShipmentStatus.Draft,

        personInCharges: [item?.pic1, item?.pic2, item?.pic3, item?.pic4],

        accountReceivable: item?.accountReceivable,
        releasedAgent:
          consol?.agentType === AgentType.Direct
            ? undefined
            : item?.releasedAgent,
        customsBroker: item?.customsBroker,
        notifyParty: item?.notifyParty,
        consignor: item?.consignor,
        consignee: item?.consignee,

        originIsTransport: item?.originIsTransport ?? false,
        originIsCustoms: item?.originIsCustoms ?? false,
        originIsHandling: item?.originIsHandling ?? false,

        desIsTransport: item?.desIsTransport ?? false,
        desIsCustoms: item?.desIsCustoms ?? false,
        desIsHandling: item?.desIsHandling ?? false,
        isGoodsInsurance: item?.isGoodsInsurance ?? false,

        goodsHSCode: item?.goodsHSCode ?? '',
        goodsMarksNumbers: item?.goodsMarksNumbers ?? '',
        goodsValue: item?.goodsValue ?? '',
        goodsInsurance: item?.goodsInsurance ?? '',
        goodsCommodity: item?.goodsCommodity ?? '',
        goodsDescription: item?.goodsDescription ?? '',
        goodsCurrency: item?.goodsCurrency ?? setting?.currency,

        pol: item?.pol ?? consol?.firstLoading,
        polEtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.polEtd ?? consol?.firstEtd)?.format()
        ),
        polAtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.polAtd ?? consol?.firstAtd)?.format()
        ),
        onboardDate: Prototype.date.applyTzLocal(
          Prototype.date
            .toTz(consol?.onboardDate || item?.onboardDate)
            ?.format()
        ),

        lastDischarge: consol?.lastDischarge,
        lastEta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(consol?.lastEta)?.format()
        ),
        lastAta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(consol?.lastAta)?.format()
        ),

        pod: item?.pod,
        podEta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.podEta)?.format()
        ),
        podAta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.podAta)?.format()
        ),

        firstOrigin: item?.firstOrigin,
        firstEtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.firstEtd)?.format()
        ),
        firstAtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.firstAtd)?.format()
        ),

        finalDes: item?.finalDes,
        finalEta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.finalEta)?.format()
        ),
        finalAta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(item?.finalAta)?.format()
        ),

        agentType: consol?.agentType,
        parentShipmentId: item?.parentShipmentId,

        isFinalShipment: item?.isFinalShipment ?? true,
        isRootShipment: item?.isRootShipment ?? true,
        handlingInfo: item?.handlingInfo ?? '',
        noteContent: item?.noteContent ?? '',
        jobOrderNumber: item?.jobOrderNumber ?? ''
      };

      return initValues;
    },
    [category, isExp, mode, setting?.currency]
  );

  const normalizeRtInitValues = useCallback(
    (pId: any, rItem?: any, pItem?: any) => {
      const cItem = rItem ?? pItem;
      const initValues: IRoutingFormData = {
        id: rItem?.id,
        freightMode: mode,
        category,

        order: rItem?.routingOrder ?? (pItem?.routingOrder ?? 0) + 1,
        pId,
        pCode:
          category === UIDataType.Cs
            ? cItem?.consolNumber
            : cItem?.shipmentNumber,

        isLink: rItem?.isLink ?? false,
        isDomestic: rItem?.isDomestic ?? false,

        pol: rItem?.pol,
        polEtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(rItem?.polEtd)?.format()
        ),
        polAtd: Prototype.date.applyTzLocal(
          Prototype.date.toTz(rItem?.polAtd)?.format()
        ),
        onboardDate: Prototype.date.applyTzLocal(
          Prototype.date.toTz(rItem?.onboardDate)?.format()
        ),

        pod: cItem?.pod,
        podEta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(cItem?.podEta)?.format()
        ),
        podAta: Prototype.date.applyTzLocal(
          Prototype.date.toTz(cItem?.podAta)?.format()
        ),

        mode:
          rItem?.mode ??
          (mode === AirOceanType.Ocean
            ? LegRoutingMode.Ocean
            : LegRoutingModeAir.Air),
        type: rItem?.type,

        vessel: rItem?.vessel,
        voyage: rItem?.voyage ?? '',

        flight: rItem?.flight,

        service: rItem?.service ?? '',
        serviceNo: rItem?.serviceNo ?? '',
        tripNo: rItem?.tripNo ?? '',

        routingStatus: rItem?.routingStatus ?? LegRoutingStatus.Pending,

        shippingLineCarrier: rItem?.shippingLine,
        airline: rItem?.airline,

        oblNo: rItem?.oblNo ?? '',
        oblReleasedType: rItem?.oblReleasedType,
        oblReleasedDate: Prototype.date.toLocal(rItem?.oblReleasedDate),

        hblNo: rItem?.hblNo ?? '',
        hblReleasedType: rItem?.hblReleasedType,
        hblReleasedDate: Prototype.date.toLocal(rItem?.hblReleasedDate),

        pblNo: rItem?.pblNo ?? '',
        pblReleasedType: rItem?.pblReleasedType,
        pblReleasedDate: Prototype.date.toLocal(rItem?.pblReleasedDate)
      };

      return initValues;
    },
    [category, mode]
  );

  const normalizeArInitValues = (
    item?: any,
    dataDueDate?: any,
    options?: {
      pId?: number | string;
      cId?: number | string;
      accountReceivable?: any;
    }
  ) => {
    const { pId, cId, accountReceivable } = options || {};

    const { data: defAccounts } = useFetchLinkedChartOfAccount();

    const defAccount = defAccounts
      ? defAccounts[LinkedAccountType.Sales].find(i =>
          setting?.currency?.code === 'USD'
            ? i.code === LinkedAccountCode.SalesAssetUSD
            : i.code === LinkedAccountCode.SalesAssetVND
        )?.chartOfAccount
      : undefined;

    const initValues: IArInvoiceFormData = {
      id: item?.id,
      freightMode: mode,
      category,

      pId,
      cId,

      invoiceType: item?.invoiceType ?? InvoiceType.ORDINARY,
      code: item?.code ?? '',
      refArInvoiceNo: item?.refArInvoiceNo ?? '',
      issuedDate: Prototype.date.toLocal(item?.issuedDate),

      arApType: ArApType.Ar,

      dueDate: Prototype.date.toLocal(item?.dueDate),
      defaultDueDate: Prototype.date.toLocal(item?.dueDate),
      dueDateType: { dueDateType: item?.dueDateType },
      listDueDateType: dataDueDate?.dueDates ?? [],
      isOneDueDate: (dataDueDate?.dueDates ?? []).length < 2,
      isOverrideDueDate: item?.isOverrideDueDate ?? false,
      paymentCurrency: item?.paymentCurrency ?? setting?.currency,
      exchangeRateType:
        item?.exchangeRateType ?? ExchangeRateTypeALL.INVOICE_DATE,
      exchangeRateDate: item?.exchangeRateDate ?? dateNow,
      exchangeRateDateDefault: item?.exchangeRateDate ?? dateNow,
      paymentSubTotal: item?.paymentSubTotal ?? 0,
      taxAmount: item?.taxAmount ?? 0,
      paymentTaxAmount: item?.paymentTaxAmount ?? 0,
      totalAmount: item?.totalAmount ?? 0,
      description: item?.description ?? '',
      paymentMode: item?.paymentMode,
      collect: item?.collect ?? 0,
      balance: (formaValCurrency(item?.balance) as any) ?? '',
      syncStatus: item?.syncStatus ?? '',
      syncBy: item?.syncBy,
      syncDate: Prototype.date.toLocal(item?.syncDate),
      paymentStatus: item?.paymentStatus ?? '',
      arInvoiceStatus: item?.arInvoiceStatus?.code ?? ARInvoiceStatus.DRAFT,
      accountReceivable: item?.accountReceivable ?? accountReceivable,
      arAddress: item?.arAddress,
      resources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      defaultResources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      arChargeCodes: item?.arChargeCodes ?? [],
      isExceededBalanceDue: dataDueDate?.isExceededBalanceDue,
      companyBank: item?.companyBank,
      isWarning: false,
      subTotal: item?.subTotal ?? 0,
      consolId: cId,
      chartOfAccountReceivable: item?.id
        ? item?.chartOfAccountReceivable
        : defAccount,

      accountingNote: item?.accountingNote,
      vatInvoiceNo: item?.vatInvoiceNo,
      vatInvoiceExRate: item?.vatInvoiceExRate,
      vatInvoiceDate: Prototype.date.toLocal(item?.vatInvoiceDate),

      createdBy: item?.createdUsername ?? '',
      createdAt: Prototype.date.toLocal(item?.createdAt),
      updatedBy: item?.updatedUsername ?? '',
      updatedAt: Prototype.date.toLocal(item?.updatedAt)
    };

    return initValues;
  };

  // const dateNow = Prototype.date.now();

  const normalizeApInitValues = useCallback(
    (
      item?: any,
      dataDueDate?: any,
      options?: {
        pId?: number | string;
        cId?: number | string;
      }
    ) => {
      const { pId, cId } = options || {};

      const { data: defAccounts } = useFetchLinkedChartOfAccount();

      const defAccount = defAccounts
        ? defAccounts[LinkedAccountType.Purchases].find(i =>
            setting?.currency?.code === 'USD'
              ? i.code === LinkedAccountCode.PurchasesAssetUSD
              : i.code === LinkedAccountCode.PurchasesAssetVND
          )?.chartOfAccount
        : undefined;

      const initValues: IApInvoiceFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        pId,
        cId,

        invoiceType: item?.invoiceType ?? InvoiceType.ORDINARY,
        code: item?.code,
        issuedDate: Prototype.date.toLocal(item?.issuedDate),

        arApType: ArApType.Ap,

        dueDate: Prototype.date.toLocal(item?.dueDate),
        defaultDueDate: Prototype.date.toLocal(item?.dueDate),
        dueDateType: { dueDateType: item?.dueDateType },
        listDueDateType: dataDueDate?.dueDates ?? [],
        isOneDueDate: (dataDueDate?.dueDates ?? []).length < 2,
        isOverrideDueDate: item?.isOverrideDueDate ?? false,
        paymentCurrency: item?.paymentCurrency ?? setting?.currency,
        exchangeRateType:
          item?.exchangeRateType ?? ExchangeRateTypeALL.INVOICE_DATE,
        exchangeRateDate: item?.exchangeRateDate ?? dateNow,
        exchangeRateDateDefault: item?.exchangeRateDate ?? dateNow,
        paymentSubTotal: item?.subTotal ?? '',
        subTotal: item?.subTotal ?? 0,
        taxAmount: item?.taxAmount ?? 0,
        paymentTaxAmount: item?.paymentTaxAmount ?? 0,
        totalAmount: item?.totalAmount ?? 0,
        description: item?.description ?? '',
        paymentMode: item?.paymentMode,
        paid: item?.paid ?? 0,
        balance: (Prototype.number.formatNumber(item?.balance) as any) ?? '',

        syncStatus: item?.syncStatus ?? '',
        syncBy: item?.syncBy,
        syncDate: Prototype.date.toLocal(item?.syncDate),

        paymentStatus: item?.paymentStatus ?? '',
        apInvoiceStatus: item?.apInvoiceStatus?.code ?? APInvoiceStatus.DRAFT,
        refApInvoiceNo: item?.refApInvoiceNo ?? '',
        refApInvoiceDate: Prototype.date.toLocal(item?.refApInvoiceDate),
        refNo: item?.refNo ?? '',
        accountPayable: item?.accountPayable,
        accountPayableAddress: item?.accountPayableAddress,
        companyBank: item?.companyBank,
        resources:
          item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ??
          [],
        defaultResources:
          item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ??
          [],
        creditLimit: dataDueDate?.creditLimit,

        apChargeCodes: item?.apChargeCodes ?? [],

        isWarning: false,
        chartOfAccountPayable: item?.id
          ? item?.chartOfAccountPayable
          : defAccount,

        accountingNote: item?.accountingNote,
        vatInvoiceExRate: item?.vatInvoiceExRate,

        createdBy: item?.createdUsername ?? '',
        createdAt: Prototype.date.toLocal(item?.createdAt),
        updatedBy: item?.updatedUsername ?? '',
        updatedAt: Prototype.date.toLocal(item?.updatedAt)
      };

      return initValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category, mode, setting?.currency]
  );

  const normalizeWipInitValues = useCallback(
    (
      item?: any,
      options?: {
        pId?: number | string;
        cId?: number | string;
        initParams: any;
        containerMode?: OceanContainerMode | AirContainerMode;
        category: UIDataType;
      }
    ) => {
      const { pId, cId, initParams, category: cat } = options || {};

      const { data: quantityWithUnit } = useCalculateWipQuantityWithUnit(
        cat,
        initParams
      );

      const unit = get(item, 'chargeCodeUnit');

      const mQuantity = !!unit ? quantityWithUnit?.[unit?.['code']] : undefined;

      const isComposite =
        item?.chargeCode?.calculatorType === CalculatorType.Composite;

      const initValues: IWipFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        pId,
        cId,

        serviceType: item?.serviceType ?? WipServiceType.Origin,
        apClient: item?.apClient,
        chargeCode: item?.chargeCode,
        calculatorType: item?.calculatorType ?? '',
        currency: item?.currency,
        addrFrom: item?.addrFrom,
        addrTo: item?.addrTo,
        containerTypeSize: item?.containerTypeSize,
        baseValue: item?.baseValue ?? 0,
        taxType: item?.taxType,
        chargeCodeUnit: item?.chargeCodeUnit,
        estSubTotal: item?.estSubTotal ?? '',
        taxAmount: item?.taxAmount ?? '',
        estTotal: item?.estTotal ?? '',
        levelType: item?.chargeCode?.levelType ?? '',

        quantity: item?.quantity ?? 1,
        clientQuantity: !!item?.chargeCode
          ? !isComposite
            ? mQuantity?.clientQuantity ?? 1
            : 1
          : 1,
        chargeableQuantity: !!item?.chargeCode
          ? !isComposite
            ? mQuantity?.chargeableQuantity ?? 1
            : 1
          : 1,
        wipPrices: item?.wipPrices ?? [],
        wipSubChargeCodes: item?.wipSubChargeCodes ?? []
      };

      return initValues;
    },
    [category, mode]
  );

  const normalizeApChargeCodeInitValues = useCallback(
    (
      item?: any,
      options?: {
        invoiceType?: InvoiceType;
        pId?: number | string;
        cId?: number | string;
      }
    ) => {
      const { itemChargeCodeLevels, itemSubChargeCodes } = useDefaultValue();
      const { invoiceType, pId, cId } = options || {};
      const initValues: IApChargeCodeFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        pId,
        cId,

        serviceType: item?.serviceType ?? WipServiceType.Origin,
        apClient: item?.apClient,
        chargeCode: item?.chargeCode,
        chargeCodeSubCategory: item?.chargeCode?.chargeCodeSubCategory,
        chargeCodeCategory:
          item?.chargeCode?.chargeCodeSubCategory?.chargeCodeCategory,
        calculatorType: item?.calculatorType ?? '',
        currency: item?.currency,
        apChargeCodeFrom: item?.apChargeCodeFrom,
        apChargeCodeTo: item?.apChargeCodeTo,
        containerTypeSize: item?.containerTypeSize,
        baseValue: item?.baseValue ?? 0,
        taxType: item?.taxType,
        chargeCodeUnit: item?.chargeCodeUnit,
        subTotal: item?.subTotal ?? '',
        taxAmount: item?.taxAmount ?? '',
        totalAmount: item?.totalAmount ?? '',
        levelType: item?.chargeCode?.levelType ?? '',
        exchangeRate: item?.exchangeRate ?? 1,
        isCreditNote: invoiceType === InvoiceType.CREDIT_NOTE,

        quantity: item?.quantity ?? 1,
        apChargeCodeLevels: item?.apChargeCodeLevels?.length
          ? item?.apChargeCodeLevels
          : [{ ...itemChargeCodeLevels, quantity: item?.quantity ?? 1 }],
        apSubChargeCodes:
          item?.apSubChargeCodes?.length > 0
            ? item?.apSubChargeCodes
            : [itemSubChargeCodes]
      };

      return initValues;
    },
    [category, mode]
  );

  const normalizeArChargeCodeInitValues = useCallback(
    (
      item?: any,
      options?: {
        arInvoice?: any;
        pId?: number | string;
        cId?: number | string;
      }
    ) => {
      const { itemChargeCodeLevels, itemSubChargeCodes } = useDefaultValue();
      const { arInvoice, pId, cId } = options || {};
      const initValues: IArChargeCodeFormData = {
        id: item?.id,
        freightMode: mode,
        category,

        pId,
        cId,

        serviceType: item?.serviceType ?? WipServiceType.Origin,
        accountPayable: item?.accountPayable,
        chargeCode: item?.chargeCode,
        chargeCodeSubCategory: item?.chargeCode?.chargeCodeSubCategory,
        chargeCodeCategory:
          item?.chargeCode?.chargeCodeSubCategory?.chargeCodeCategory,
        calculatorType: item?.calculatorType ?? '',
        arCurrency: arInvoice?.paymentCurrency,
        currency: item?.currency,
        arChargeCodeFrom: item?.arChargeCodeFrom,
        arChargeCodeTo: item?.arChargeCodeTo,
        containerTypeSize: item?.containerTypeSize,
        baseValue: item?.baseValue ?? 0,
        taxType: item?.taxType,
        chargeCodeUnit: item?.chargeCodeUnit,
        subTotal: item?.subTotal ?? '',
        taxAmount: item?.taxAmount ?? '',
        totalAmount: item?.totalAmount ?? '',
        levelType: item?.chargeCode?.levelType ?? '',
        exchangeRate: item?.exchangeRate ?? 1,
        markupLevelNumber: item?.markupLevelNumber ?? LevelMarkup?.MarkupLevel1,
        costSubTotal: item?.costSubTotal ?? 0,
        wipSubTotal: item?.wipSubTotal ?? 0,
        uplift: item?.uplift ?? 0,
        chargeableExchangeRate: '',

        quantity: item?.quantity ?? 1,
        arChargeCodeLevels: item?.arChargeCodeLevels?.length
          ? item?.arChargeCodeLevels
          : [{ ...itemChargeCodeLevels, quantity: item?.quantity ?? 1 }],
        arSubChargeCodes:
          item?.arSubChargeCodes?.length > 0
            ? item?.arSubChargeCodes
            : [itemSubChargeCodes],
        isWarningSubtotal: false
      };

      return initValues;
    },
    [category, mode]
  );

  const normalizePaymentAdviceInitValues = useCallback((item?: any) => {
    const initValues: IPaymentAdviceFormData = {
      id: item?.id,
      code: item?.code,
      adviceType: AdviceType.Payment,

      accountPayable: item?.accountPayable,
      accountPayableAddress: item?.accountPayableAddress,
      defaultAdviceStatus: item?.paymentAdviceStatus,
      paymentAdviceStatus: item?.paymentAdviceStatus ?? AdviceStatus.Pending,

      refNo: item?.refNo ?? '',
      currency: item?.currency,
      totalAmount: item?.totalAmount,
      paymentMode: item?.paymentMode,
      companyBank: item?.companyBank,
      description: item?.description ?? '',
      defaultResources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      resources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],

      adviceDetails: (item?.paymentAdviceDetails ?? []).map((val: any) => {
        return {
          ...val.apInvoice,
          accountPayable: item?.accountPayable,
          amount: val.amount
        };
      }),

      accountingNote: item?.accountingNote,

      isLinkToMisa: item?.isLinkToMisa ?? false,
      syncStatus: item?.syncStatus
    };

    return initValues;
  }, []);

  const normalizeReceivableAdviceInitValues = useCallback((item?: any) => {
    const initValues: IReceivableAdviceFormData = {
      id: item?.id,
      code: item?.code,
      adviceType: AdviceType.Receivable,

      accountReceivable: item?.accountReceivable,
      accountReceivableAddress: item?.accountReceivableAddress,
      defaultAdviceStatus: item?.receivableAdviceStatus,
      receivableAdviceStatus:
        item?.receivableAdviceStatus ?? AdviceStatus.Pending,

      refNo: item?.refNo ?? '',
      currency: item?.currency,
      totalAmount: item?.totalAmount,
      paymentMode: item?.paymentMode,
      companyBank: item?.companyBank,
      description: item?.description ?? '',

      defaultResources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],
      resources:
        item?.resources?.map((o?: any) => ({ ...o, name: o?.fileName })) ?? [],

      adviceDetails: (item?.receivableAdviceDetails ?? []).map((val: any) => {
        return {
          ...val.arInvoice,
          accountReceivable: item?.accountReceivable,
          amount: val.amount
        };
      }),

      accountingNote: item?.accountingNote,

      isLinkToMisa: item?.isLinkToMisa ?? false,
      syncStatus: item?.syncStatus
    };

    return initValues;
  }, []);

  const normalizeSvInitValues = useCallback((item?: any) => {
    const _item = !!item?.shipmentId ? item?.shipment : item?.generalService;
    const _itemConsol = !!item?.shipmentId
      ? item?.consol
      : item?.generalService;
    const _mode =
      item?.consol?.freightMode ?? _item?.mode ?? AirOceanType.Ocean;

    const _onboardDate = !!item?.shipmentId
      ? item?.consol?.onboardDate
      : item?.generalService?.onboardDate;

    const initValues: IServiceFormData = {
      id: item?.id,
      code: item?.code ?? '',
      mode: _mode,
      shipmentId: item?.shipmentId,
      isLinkedToSystemShipment: !!item?.shipmentId ?? false,

      vessel: _itemConsol?.vessel ?? '',
      voyage: _itemConsol?.voyage ?? '',
      flight: _itemConsol?.flight ?? '',

      coLoader: _itemConsol?.coLoader,
      coLoaderBookingDate: _itemConsol?.coLoaderBookingDate ?? '',
      coLoaderBookingNo: _itemConsol?.coLoaderBookingNo ?? '',
      coLoaderAgent: _itemConsol?.coLoaderAgent ?? '',
      shippingLine: _itemConsol?.shippingLine ?? '',
      shippingLineBookingDate: _itemConsol?.shippingLineBookingDate ?? '',
      shippingLineBookingNo: _itemConsol?.shippingLineBookingNo ?? '',
      shippingLineAgent: _itemConsol?.shippingLineAgent ?? '',
      airlineBookingDate: _itemConsol?.airlineBookingDate ?? '',
      airlineBookingNo: _itemConsol?.airlineBookingNo ?? '',
      airline: _itemConsol?.airline ?? '',
      airlineAgent: _itemConsol?.airlineAgent ?? '',

      isCustoms: item?.isCustoms ?? false,
      isTransport: item?.isTransport ?? false,
      isHandling: item?.isHandling ?? false,

      isNewClient: item?.isNewClient ?? false,
      isGoodsInsurance: item?.isGoodsInsurance ?? false,

      transportServiceType:
        item?.transportServiceType ?? OriginDestinationType.Origin,
      type: _item?.type ?? ConsolType.Import,

      shipmentNumber: _item?.shipmentNumber ?? '',
      shipmentType: _item?.shipmentType,

      contMode:
        _item?.contMode ??
        (_mode === AirOceanType.Ocean
          ? OceanContainerMode.FCL
          : AirContainerMode.LSE_LCL),

      oblNo: _itemConsol?.oblNo ?? '',
      oblReleasedDate:
        Prototype.date.toLocal(_itemConsol?.oblReleasedDate) ?? '',
      oblReleasedType: _itemConsol?.oblReleasedType,

      hblNo: _item?.hblNo ?? '',
      hblReleasedDate:
        Prototype.date.toLocal(
          item?.shipment?.releasedDate || item?.generalService?.hblReleasedDate
        ) ?? '',
      hblReleasedType:
        item?.shipment?.releasedType || item?.generalService?.hblReleasedType,

      pblNo: _itemConsol?.pblNo ?? '',
      pblReleasedDate:
        Prototype.date.toLocal(_itemConsol?.pblReleasedDate) ?? '',
      pblReleasedType: _itemConsol?.pblReleasedType,

      deliveryMode: item?.shipment?.deliveryMode ?? DeliveryMode.CFS_CFS,
      incoterm: _item?.incoterm,

      firstOrigin: _item?.firstOrigin,
      firstEtd: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.firstEtd)?.format()
      ),
      firstAtd: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.firstAtd)?.format()
      ),

      finalDes: _item?.finalDes,
      finalEta: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.finalEta)?.format()
      ),
      finalAta: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.finalAta)?.format()
      ),

      pol: _item?.pol,
      polEtd: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.polEtd)?.format()
      ),
      polAtd: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.polAtd)?.format()
      ),

      pod: _item?.pod,
      podEta: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.podEta)?.format()
      ),
      podAta: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_item?.podAta)?.format()
      ),

      freightPayment: _item?.freightPayment,

      serviceLevel: _item?.serviceLevel,
      bookingClient: item?.bookingClient,
      refClientBookingNo: _item?.refClientBookingNo,

      createdAt: item?.createdAt ?? '',
      createdBy: item?.createdUsername ?? '',
      updatedBy: item?.updatedUsername ?? '',
      updatedAt: item?.updatedAt ?? '',

      consignor: _item?.consignor,
      consignee: _item?.consignee,
      customsBroker: _item?.customsBroker,
      accountReceivable: _item?.accountReceivable,

      totalPacks: _item?.totalPacks ?? '',
      totalVolume: _item?.totalVolume ?? '',
      totalGrossWeight: _item?.totalGrossWeight ?? '',
      totalNetWeight: _item?.totalNetWeight ?? '',
      totalChargeableWeightVolume: _item?.totalChargeableWeightVolume ?? '',

      goodsHSCode: _item?.goodsHSCode ?? '',
      goodsCommodity: _item?.goodsCommodity ?? '',
      goodsDescription: _item?.goodsDescription ?? '',
      goodsMarksNumbers: _item?.goodsMarksNumbers ?? '',
      goodsValue: _item?.goodsValue ?? '',
      goodsInsurance: _item?.goodsInsurance ?? '',
      goodsCurrency: _item?.goodsCurrency,

      // customsDutyAmount: item?.customsDutyAmount ?? '',
      // customsTax: item?.customsTax ?? '',
      // customsCurrency: item?.customsCurrency,
      serviceStatus: item?.serviceStatus ?? TransportStatus.Draft,
      defaultServiceStatus: item?.serviceStatus ?? TransportStatus.Draft,
      onboardDate: Prototype.date.applyTzLocal(
        Prototype.date.toTz(_onboardDate)?.format()
      ),

      personInCharges: defaultPersonInChart([
        { ...item?.picMain, serviceType: 'MAIN' },
        { ...item?.picCustoms, serviceType: ServiceType.Customs },
        { ...item?.picTransport, serviceType: ServiceType.Transport },
        { ...item?.picHandling, serviceType: ServiceType.Handling }
      ]),
      noteContent: item?.noteContent ?? '',
      jobOrderNumber: item?.jobOrderNumber ?? ''
    };

    return initValues;
  }, []);

  return {
    normalizeCsInitValues,
    normalizeSmInitValues,
    normalizeRtInitValues,
    normalizeArInitValues,
    normalizeApInitValues,
    normalizeWipInitValues,
    normalizeApChargeCodeInitValues,
    normalizeArChargeCodeInitValues,
    normalizePaymentAdviceInitValues,
    normalizeReceivableAdviceInitValues,
    normalizeSvInitValues
  };
};

export const useInitSchema = () => {
  const apSchema = () =>
    yup.object().shape({
      paymentMode: ValidationUtils.requiredAny(),
      paymentCurrency: ValidationUtils.requiredAny(),
      dueDate: ValidationUtils.date(),
      paid: ValidationUtils.requiredNum(),
      accountPayable: ValidationUtils.requiredAny(),
      chartOfAccountPayable: ValidationUtils.nullableAny().test(
        'chartOfAccountPayable',
        trans('validation.required.default'),
        function (v: any) {
          const invoiceType = this.parent.invoiceType;
          if (invoiceType === InvoiceType.PRE_SOA) {
            return true;
          }
          return !!v;
        }
      )
    });

  const arSchema = () =>
    yup.object().shape({
      paymentMode: ValidationUtils.requiredAny(),
      paymentCurrency: ValidationUtils.requiredAny(),
      collect: ValidationUtils.requiredNum(),
      accountReceivable: ValidationUtils.requiredAny(),
      chartOfAccountReceivable: ValidationUtils.nullableAny().test(
        'chartOfAccountReceivable',
        trans('validation.required.default'),
        function (v: any) {
          const invoiceType = this.parent.invoiceType;
          if (invoiceType === InvoiceType.PRE_SOA) {
            return true;
          }
          return !!v;
        }
      )
    });

  const wipSchema = () =>
    yup.object().shape({
      chargeCodeUnit: yup
        .mixed()
        .nonNullable()
        .when('calculatorType', ([calculatorType], _schema) => {
          if (calculatorType !== CalculatorType.Composite) {
            return _schema.nullable().required();
          }
          return _schema.notRequired();
        }),
      quantity: ValidationUtils.requiredNum().moreThan(0),
      chargeCode: ValidationUtils.requiredAny(),
      currency: ValidationUtils.requiredAny(),
      wipPrices: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType !== CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  quantity: ValidationUtils.requiredNum().moreThan(0),
                  unitPrice: ValidationUtils.requiredMinNumber(0)
                })
              )
            : _schema;
        }),
      wipSubChargeCodes: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType === CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  subChargeCode: ValidationUtils.requiredAny(),
                  ratio: ValidationUtils.requiredNum().moreThan(0).max(100),
                  currency: yup
                    .mixed()
                    .nullable()
                    .test(
                      'subChargeCodeCurrency',
                      trans('validation.conflicted_currency_with_this_wip'),
                      function (v: any) {
                        if (!v?.code) return true;
                        const isCheck =
                          (this.options as any)?.['from']?.[1]?.value?.currency
                            ?.code === v?.code;
                        return isCheck;
                      }
                    )
                })
              )
            : _schema;
        })
    });

  const apChargeCodeSchema = () =>
    yup.object().shape({
      currency: ValidationUtils.requiredAny(),
      exchangeRate: ValidationUtils.requiredNum(),
      chargeCodeUnit: ValidationUtils.requiredAny(),
      quantity: ValidationUtils.requiredNum().moreThan(0),
      apChargeCodeLevels: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType !== CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  quantity: ValidationUtils.requiredNum().moreThan(0),
                  unitPrice: ValidationUtils.requiredMinNumber(0)
                })
              )
            : _schema;
        }),
      apSubChargeCodes: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType === CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  subChargeCode: ValidationUtils.requiredAny(),
                  ratio: ValidationUtils.requiredMinMaxNumber(0, 100),
                  currency: yup
                    .mixed()
                    .nullable()
                    .test(
                      'subChargeCodeCurrency',
                      trans('validation.conflicted_currency_with_this_wip'),
                      function (v: any) {
                        if (!v?.code) return true;
                        const isCheck =
                          (this.options as any)?.['from']?.[1]?.value?.currency
                            ?.code === v?.code;
                        return isCheck;
                      }
                    )
                })
              )
            : _schema;
        })
    });

  const arChargeCodeSchema = () =>
    yup.object().shape({
      currency: ValidationUtils.requiredAny(),
      exchangeRate: ValidationUtils.requiredNum(),
      uplift: ValidationUtils.requiredNum(),
      markupLevelNumber: ValidationUtils.requiredAny(),
      chargeCodeUnit: yup
        .mixed()
        .nonNullable()
        .when('calculatorType', ([calculatorType], _schema) => {
          if (calculatorType !== CalculatorType.Composite) {
            return _schema.nullable().required();
          }
          return _schema.notRequired();
        }),
      quantity: ValidationUtils.requiredNum().moreThan(0),
      chargeCode: ValidationUtils.requiredAny(),
      chargeCodeSubCategory: ValidationUtils.requiredAny(),
      chargeCodeCategory: ValidationUtils.requiredAny(),
      arChargeCodeLevels: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType !== CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  quantity: ValidationUtils.requiredNum().moreThan(0),
                  unitPrice: ValidationUtils.requiredMinNumber(0)
                })
              )
            : _schema;
        }),
      arSubChargeCodes: yup
        .array()
        .when('calculatorType', ([calculatorType], _schema) => {
          return calculatorType === CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  subChargeCode: ValidationUtils.requiredAny(),
                  ratio: ValidationUtils.requiredMinMaxNumber(0, 100),
                  currency: yup
                    .mixed()
                    .nullable()
                    .test(
                      'subChargeCodeCurrency',
                      trans('validation.conflicted_currency_with_this_wip'),
                      function (v: any) {
                        if (!v?.code) return true;
                        const isCheck =
                          (this.options as any)?.['from']?.[1]?.value?.currency
                            ?.code === v?.code;
                        return isCheck;
                      }
                    )
                })
              )
            : _schema;
        })
    });

  const arChargeCodeCreditNoteSchema = () =>
    yup.object().shape({
      exchangeRate: ValidationUtils.requiredNum(),
      chargeCode: ValidationUtils.requiredAny(),
      arChargeCodeLevels: yup
        .array()
        .when('chargeCode', ([chargeCode], _schema) => {
          return chargeCode?.calculatorType !== CalculatorType.Composite
            ? _schema.of(
                yup.object().shape({
                  quantity: ValidationUtils.requiredNum().moreThan(0),
                  unitPrice: ValidationUtils.requiredMinNumber(0)
                })
              )
            : _schema;
        })
    });

  return {
    apSchema,
    arSchema,
    wipSchema,
    apChargeCodeSchema,
    arChargeCodeSchema,
    arChargeCodeCreditNoteSchema
  };
};

export const useShipmentSchema = () => {
  const shipmentSchema = () =>
    yup.object().shape({
      shipmentType: ValidationUtils.requiredAny(),
      consignor: yup
        .mixed()
        .nullable()
        .when('type', ([type, _schema]) => {
          return type === ConsolType.Export
            ? ValidationUtils.requiredAny()
            : _schema;
        }),
      consignee: yup
        .mixed()
        .nullable()
        .when('type', ([type, _schema]) => {
          return type === ConsolType.Import
            ? ValidationUtils.requiredAny()
            : _schema;
        }),
      accountReceivable: ValidationUtils.requiredAny().test(
        'arIsCreditHold',
        trans('validation.client_ar_credit_hold'),
        function (v: any) {
          if (v?.arIsCreditHold) {
            return false;
          }
          return true;
        }
      ),
      onboardDate: ValidationUtils.nullableDate(),
      hblOriginal: ValidationUtils.nullableNum().min(0),
      hblCopy: ValidationUtils.nullableNum().min(0),
      goodsValue: ValidationUtils.nullableNum(),
      goodsInsurance: ValidationUtils.nullableNum(),
      goodsCurrency: ValidationUtils.requiredAny(),

      firstEtd: ValidationUtils.nullableDate(),
      pod: ValidationUtils.requiredAny(),
      podEta: ValidationUtils.requiredDate(),
      finalEta: ValidationUtils.nullableDate(),
      firstAtd: ValidationUtils.nullableDate(),
      podAta: ValidationUtils.nullableDate(),
      finalAta: ValidationUtils.nullableDate(),
      hblNo: ValidationUtils.required(),
      personInCharges: yup.array().of(
        yup.object().shape({
          staff: yup
            .mixed()
            .nullable()
            .when('department', ([department], _schema) => {
              if (department?.id) {
                return _schema.nullable().required();
              }
              return _schema.notRequired();
            })
          // ratio: ValidationUtils.nullableNum()
          //   .min(0)
          //   .max(100)
          //   .test(
          //     'ratio_sales_person',
          //     trans('validation.the_total_of_the_sale_must_be_100'),
          //     function (v: any) {
          //       const isDepartment = this.parent.department?.id;

          //       const itemVal = this.options?.['from']?.[1]?.value;
          //       const totalRatio = (itemVal?.personInCharges || []).reduce(
          //         (pre: number, cur: any) => {
          //           pre = pre + Number(cur?.ratio || 0);
          //           return pre;
          //         },
          //         0
          //       );

          //       if (!isDepartment) return true;
          //       return totalRatio === 100;
          //     }
          //   )
        })
      )
    });

  const shipmentNoConsolSchema = () => {
    return yup.object().shape({
      shipmentType: ValidationUtils.requiredAny(),
      accountReceivable: ValidationUtils.requiredAny().test(
        'arIsCreditHold',
        trans('validation.client_ar_credit_hold'),
        function (v: any) {
          if (v?.arIsCreditHold) {
            return false;
          }
          return true;
        }
      ),
      consignor: yup
        .mixed()
        .nullable()
        .when('type', ([type, _schema]) => {
          return type === ConsolType.Export
            ? ValidationUtils.requiredAny()
            : _schema;
        }),
      consignee: yup
        .mixed()
        .nullable()
        .when('type', ([type, _schema]) => {
          return type === ConsolType.Import
            ? ValidationUtils.requiredAny()
            : _schema;
        }),
      // incoterm: ValidationUtils.requiredAny(),
      onboardDate: ValidationUtils.nullableDate(),
      // serviceLevel: ValidationUtils.requiredAny(),
      goodsValue: ValidationUtils.nullableNum(),
      goodsInsurance: ValidationUtils.nullableNum(),
      goodsCurrency: ValidationUtils.requiredAny(),

      hblOriginal: ValidationUtils.nullableNum().min(0),
      hblCopy: ValidationUtils.nullableNum().min(0),

      firstEtd: ValidationUtils.nullableDate(),

      pol: ValidationUtils.requiredAny(),

      pod: ValidationUtils.requiredAny(),

      finalEta: ValidationUtils.nullableDate(),

      firstAtd: ValidationUtils.nullableDate(),

      polAtd: ValidationUtils.nullableDate(),

      podAta: ValidationUtils.nullableDate(),

      finalAta: ValidationUtils.nullableDate(),

      personInCharges: yup.array().of(
        yup.object().shape({
          staff: yup
            .mixed()
            .nullable()
            .when('department', ([department], _schema) => {
              if (department?.id) {
                return _schema.nullable().required();
              }
              return _schema.notRequired();
            })
          // ratio: ValidationUtils.nullableNum()
          //   .min(0)
          //   .max(100)
          //   .test(
          //     'ratio_sales_person',
          //     trans('validation.the_total_of_the_sale_must_be_100'),
          //     function (v: any) {
          //       const isDepartment = this.parent.department?.id;

          //       const itemVal = this.options?.['from']?.[1]?.value;
          //       const totalRatio = (itemVal?.personInCharges || []).reduce(
          //         (pre: number, cur: any) => {
          //           pre = pre + Number(cur?.ratio || 0);
          //           return pre;
          //         },
          //         0
          //       );

          //       if (!isDepartment) return true;
          //       return totalRatio === 100;
          //     }
          //   )
        })
      )
    });
  };

  return { shipmentSchema, shipmentNoConsolSchema };
};

export const commonStatusArInvoice = (arInvoiceStatus: ARInvoiceStatus) => {
  const valueStatus = {
    allUpdate: 'allUpdate',
    backNext: 'backNext',
    notUpdate: 'notUpdate',
    back: 'back'
  };

  const statusUpdate = {
    [ARInvoiceStatus.DRAFT]: valueStatus.allUpdate,
    [ARInvoiceStatus.CONFIRM_REQUEST]: valueStatus.allUpdate,
    [ARInvoiceStatus.CONFIRMED]: valueStatus.backNext,
    [ARInvoiceStatus.INVOICE_REQUEST]: valueStatus.backNext,
    [ARInvoiceStatus.ISSUED]: valueStatus.back,
    [ARInvoiceStatus.COMPLETE]: valueStatus.notUpdate,
    [ARInvoiceStatus.CANCEL]: valueStatus.notUpdate
  };

  return {
    isDraftConfirmRequest:
      statusUpdate[arInvoiceStatus] === valueStatus.allUpdate,
    isNextBack: statusUpdate[arInvoiceStatus] === valueStatus.backNext,
    isComplete: statusUpdate[arInvoiceStatus] === valueStatus.notUpdate,
    isIssued: statusUpdate[arInvoiceStatus] === valueStatus.back,
    isCancel: arInvoiceStatus === ARInvoiceStatus.CANCEL
  };
};

export const commonStatusApInvoice = (apInvoiceStatus: APInvoiceStatus) => {
  const valueStatus = {
    allUpdate: 'allUpdate',
    backNext: 'backNext',
    notUpdate: 'notUpdate',
    back: 'back'
  };

  const statusUpdate = {
    [APInvoiceStatus.DRAFT]: valueStatus.allUpdate,
    [APInvoiceStatus.PAYMENT_REQUEST]: valueStatus.allUpdate,
    [APInvoiceStatus.VERIFIED]: valueStatus.backNext,
    [APInvoiceStatus.APPROVED]: valueStatus.back,
    [APInvoiceStatus.COMPLETE]: valueStatus.notUpdate,
    [APInvoiceStatus.CANCEL]: valueStatus.notUpdate
  };

  return {
    isDraftPaymentRequest:
      statusUpdate[apInvoiceStatus] === valueStatus.allUpdate,
    isNextBack: statusUpdate[apInvoiceStatus] === valueStatus.backNext,
    isCompleteCancel: statusUpdate[apInvoiceStatus] === valueStatus.notUpdate,
    isApproved: statusUpdate[apInvoiceStatus] === valueStatus.back,
    isCancel: apInvoiceStatus === APInvoiceStatus.CANCEL
  };
};

export const commonStatusInvoice = (
  invoiceStatus: ARInvoiceStatus | APInvoiceStatus,
  clientType: ClientType.AccountPayable | ClientType.AccountReceivable
) => {
  switch (clientType) {
    case ClientType.AccountReceivable:
      const arInvoice = commonStatusArInvoice(invoiceStatus as ARInvoiceStatus);
      return {
        isDraft: arInvoice.isDraftConfirmRequest,
        isNextBack: arInvoice.isNextBack,
        isComplete: arInvoice.isComplete,
        isApprovedIssued: arInvoice.isIssued,
        isCancel: arInvoice.isCancel
      };
    default:
      const apInvoice = commonStatusApInvoice(invoiceStatus as APInvoiceStatus);
      return {
        isDraft: apInvoice.isDraftPaymentRequest,
        isNextBack: apInvoice.isNextBack,
        isComplete: apInvoice.isCompleteCancel,
        isApprovedIssued: apInvoice.isApproved,
        isCancel: apInvoice.isCancel
      };
  }
};

export const useFreightAction = () => {
  const actionAdd = (onPress: () => void) => {
    return actionType({ title: 'new_ar_invoice', icon: 'AddBox', onPress });
  };

  const actionNext = (onPress: () => void) => {
    return actionType({
      title: 'send_invoice_status',
      icon: 'ArrowUpward',
      onPress
    });
  };

  const actionBack = (onPress: () => void) => {
    return actionType({
      title: 'back_invoice_status',
      icon: 'ArrowDownward',
      onPress
    });
  };

  const actionCancel = (onPress: () => void) => {
    return actionType({
      title: 'cancel',
      icon: 'CancelOutlined',
      onPress,
      color: KColors.secondary.normal
    });
  };

  const actionImport = (onPress: () => void) => {
    return actionType({
      title: 'import',
      icon: 'ImportExportOutlined',
      onPress
    });
  };

  return { actionAdd, actionNext, actionBack, actionCancel, actionImport };
};

export const useDefaultValue = () => {
  const itemChargeCodeLevels = {
    level: 1,
    quantity: 1,
    unitPrice: 1
  };

  const itemSubChargeCodes = {
    subChargeCode: null,
    currency: null,
    ratio: 0,
    subTotal: 0
  };

  return { itemChargeCodeLevels, itemSubChargeCodes };
};

export const useBookingCancelView = (item?: any) => {
  const bookingCancel = useMemo(() => {
    if (item?.isBookingCancel) {
      return (
        <KChip
          background={KColors.hexToRgba(KColors.secondary.normal, 0.31)}
          brC={KColors.transparent}
          label={trans('cancel')}
          textTransform={'uppercase'}
          color={KColors.secondary.normal}
          typo="TextMdMedium"
          padding="0.25rem"
          marginL="1.5rem"
        />
      );
    }

    return null;
  }, [item?.isBookingCancel]);

  if (!item) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {bookingCancel}
    </KContainer.View>
  );
};

export const useStatusView = (item?: any, isCs?: boolean) => {
  const props = useMemo(
    () =>
      isCs
        ? mappedConsolStatusData()[item?.consolStatus]
        : mappedShipmentStatusData()[item?.shipmentStatus],
    [isCs, item?.consolStatus, item?.shipmentStatus]
  );

  const status = useMemo(
    () => (
      <KChip
        textTransform="uppercase"
        typo="TextXMdMedium"
        padding="0.5rem"
        marginH="3.5rem"
        {...props}
      />
    ),
    [props]
  );

  const bookingCancel = useMemo(() => {
    if (item?.isBookingCancel) {
      return (
        <KChip
          background={KColors.hexToRgba(KColors.secondary.normal, 0.31)}
          brC={KColors.transparent}
          label={trans('booking_cancel')}
          textTransform={'uppercase'}
          color={KColors.secondary.normal}
          typo="TextXMdMedium"
          padding="0.5rem"
        />
      );
    }

    return null;
  }, [item?.isBookingCancel]);

  if (!item) {
    return null;
  }

  return (
    <KContainer.View row alignItems>
      {status}
      {bookingCancel}
    </KContainer.View>
  );
};

export const checkLinkToShipment = (shipmentId?: string | number) => {
  return !!shipmentId;
};
