import {
  CalculatorType,
  LevelType,
  MarkupType,
  Status,
  WIP,
  WipType
} from '@constants';

export interface IFormData {
  chargeCodeCategory?: any;
  chargeCodeSubCategory?: any;
  code: string;
  name: string;
  status: Status;
  description?: string;
  remark?: string;
  levelType: LevelType;
  wipSourceType: WIP;
  mode: [boolean, boolean];
  account: [any, any];
  type: [boolean, boolean, boolean];
  kind: [boolean, boolean];
  unit: {
    isChecked: boolean;
    data?: any;
    mode?: 'air' | 'ocean';
    containerMode: string;
  }[];

  containerTypeSizes: any[];
  currency: any;
  taxType: any;
  // revenueAccount: any;
  // costAccount: any;

  calculatorType: CalculatorType;
  instruction?: string;
  baseValue?: number | null;
  isNoCost: boolean;
  wipType?: WipType | null;
  wipValue?: number | null;
  minSelling: number;
  markupType: MarkupType;
  markupLevel1: number | null;
  markupLevel2: number | null;
  markupLevel3: number | null;

  subChargeCodes: { subChargeCode: any; ratio: number }[];
  isSystem: boolean;
}

export const UNIT_SECTION = {
  OCEAN: ['FCL', 'FCX', 'FAK', 'LCL'],
  AIR: ['ULD_FCL', 'BCN_FCX', 'ULD_FAK', 'LSE_LCL']
};
