/* eslint-disable max-lines */
import { IMetadata } from '@dto';
import moment from 'moment-timezone';
import React, { useCallback, memo } from 'react';
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { KGrid, KInput, KLabel, KSwitch, KForm, KPicker, KColors } from 'uikit';
import * as yup from 'yup';

import { ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm } from 'hooks';
import {
  useCUDBranchSetting,
  useFetchBranchSetting
} from 'hooks/ssLibrary/setting';
import { onShowBankAccount } from 'pages/Company/helper';
import trans from 'translation';
import { ValidationUtils } from 'utils';

interface IFormData {
  id?: number;
  primaryCurrency?: IMetadata;
  secondaryCurrency?: IMetadata;
  profitPercent?: number;
  profitValue?: number;
  autoExchangeTime?: any;
  autoExchangeErrorNotify?: any;
  isAutoExchange?: boolean;
  isMisa?: boolean;
  isXero?: boolean;
  isEcus?: boolean;
  companyBank1?: any;
  companyBank2?: any;
}

interface IProps {
  branch?: any;
}

const schema = yup.object().shape({
  primaryCurrency: ValidationUtils.requiredAny().test(
    'PrimaryCurrency',
    trans('not_the_same_primary_currency'),
    function (v?: any) {
      const secondaryCurrency = this.parent.secondaryCurrency;
      return v?.id !== secondaryCurrency?.id;
    }
  ),
  secondaryCurrency: ValidationUtils.requiredAny().test(
    'SecondaryCurrency',
    trans('not_the_same_secondary_currency'),
    function (v?: any) {
      const primaryCurrency = this.parent.primaryCurrency;
      return v?.id !== primaryCurrency?.id;
    }
  ),
  profitPercent: ValidationUtils.requiredMinMaxNumber(0, 99),
  profitValue: ValidationUtils.requiredMinNumber(0)
});

const General = ({ branch }: IProps) => {
  const {
    updateMutation: { mutate: updateMutation, isLoading }
  } = useCUDBranchSetting();
  const { data: setting } = useFetchBranchSetting(branch?.id);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        id: setting?.id,
        primaryCurrency: setting?.primaryCurrency,
        secondaryCurrency: setting?.secondaryCurrency,
        profitPercent: setting?.profitPercent ?? 0,
        profitValue: setting?.profitValue ?? 0,
        autoExchangeTime: setting?.autoExchangeTime
          ? Prototype.date.toMoment(
              new Date().toISOString().slice(0, 11) +
                (setting?.autoExchangeTime ?? '00:00:00') +
                'Z'
            )
          : null,
        autoExchangeErrorNotify: setting?.autoExchangeErrorNotify ?? {},
        isAutoExchange: setting?.isAutoExchange ?? false,
        isMisa: setting?.isMisa ?? false,
        isXero: setting?.isXero ?? false,
        isEcus: setting?.isEcus ?? false,
        companyBank1: setting?.companyBank1,
        companyBank2: setting?.companyBank2
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const {
        primaryCurrency,
        secondaryCurrency,
        autoExchangeErrorNotify,
        autoExchangeTime,
        companyBank1: _companyBank1,
        companyBank2: _companyBank2,
        ...rest
      } = data;

      const mParams = {
        primaryCurrencyId: primaryCurrency?.id,
        secondaryCurrencyId: secondaryCurrency?.id,
        autoExchangeErrorNotifyId: autoExchangeErrorNotify?.id,
        companyBank1Id: _companyBank1?.id,
        companyBank2Id: _companyBank2?.id,
        autoExchangeTime: autoExchangeTime
          ? moment.tz(autoExchangeTime, 'Greenwich').format().slice(11, 16)
          : null,
        ...rest
      };

      updateMutation(mParams);
    },
    [updateMutation]
  );

  const [isMisa, isXero, primaryCurrency] = useWatch({
    control: methods.control,
    name: ['isMisa', 'isXero', 'primaryCurrency']
  });

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('primary_currency')}{' '}
                  <KLabel.Text color={KColors.secondary.normal}>*</KLabel.Text>
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <Controller
                  name="primaryCurrency"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        apiURL={ENDPOINTS.currency()}
                        getOptionLabel={(o: any) => o?.code ?? ''}
                        inputProps={{
                          required: true,
                          message: error?.message
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('secondary_currency')}{' '}
                  <KLabel.Text color={KColors.secondary.normal}>*</KLabel.Text>
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <Controller
                  name="secondaryCurrency"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        apiURL={ENDPOINTS.currency()}
                        getOptionLabel={(o: any) => o?.code ?? ''}
                        inputProps={{
                          required: true,
                          message: error?.message
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('profit_threshold_percent')}
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <Controller
                  name="profitPercent"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        type="number"
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('with_unit.profit_threshold_value', {
                    unit: primaryCurrency?.code
                  })}
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <Controller
                  name="profitValue"
                  control={methods.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        type="number"
                        message={error?.message}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('auto_exchange_rate')}
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <Controller
                  name="isAutoExchange"
                  control={methods.control}
                  render={({ field }) => {
                    return <KSwitch {...field} checked={field.value} />;
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KLabel.Text typo="TextNmBold">{trans('time')}</KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <Controller
                  name="autoExchangeTime"
                  control={methods.control}
                  render={({ field }) => {
                    return <KPicker.Time {...field} />;
                  }}
                />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KInput.TextField
                  name="currentGMTOffset"
                  value={Prototype.getTimezoneOffset()}
                  disabled
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KGrid.Container>
              <KGrid.Item xs={4}>
                <KLabel.Text typo="TextNmBold">
                  {trans('error_notification')}
                </KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={8}>
                <Controller
                  name="autoExchangeErrorNotify"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        getOptionLabel={(o: any) => o?.fullName ?? ''}
                        apiURL={ENDPOINTS.staff()}
                        apiParams={{
                          branchId: branch?.id
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KLabel.Text typo="TextNmBold">
              {trans('accounting_synchronization')}
            </KLabel.Text>
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KGrid.Container>
              <KGrid.Item xs={2}>
                <KLabel.Text typo="TextNmBold">{trans('misa')}</KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <Controller
                  name="isMisa"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KSwitch
                        {...field}
                        checked={field.value}
                        onChange={v => {
                          methods.setValue('isMisa', v);
                          if (isXero) {
                            methods.setValue('isXero', !v);
                          }
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>
          <KGrid.Item xs={12}>
            <KGrid.Container>
              <KGrid.Item xs={2}>
                <KLabel.Text typo="TextNmBold">{trans('xero')}</KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <Controller
                  name="isXero"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KSwitch
                        {...field}
                        checked={field.value}
                        onChange={v => {
                          methods.setValue('isXero', v);
                          if (isMisa) {
                            methods.setValue('isMisa', !v);
                          }
                        }}
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KLabel.Text typo="TextNmBold">
              {trans('customs_synchronization')}
            </KLabel.Text>
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KGrid.Container>
              <KGrid.Item xs={2}>
                <KLabel.Text typo="TextNmBold">{trans('thaison')}</KLabel.Text>
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <Controller
                  name="isEcus"
                  control={methods.control}
                  render={({ field }) => {
                    return <KSwitch {...field} checked={field.value} />;
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>
        </KGrid.Container>
        <CompanyBank name="companyBank1" numericalOrder="primary" />
        <CompanyBank name="companyBank2" numericalOrder="secondary" />
        <BottomActions
          btnProps={[
            {
              isLoading
            }
          ]}
        />
      </KForm>
    </FormProvider>
  );
};

export default memo(General);

const CompanyBank = ({
  name,
  numericalOrder
}: {
  name: string;
  numericalOrder: 'primary' | 'secondary';
}) => {
  const methods = useFormContext<any>();
  const [companyBank] = useWatch({
    control: methods.control,
    name: [name]
  });
  return (
    <KGrid.Container my="0.75rem">
      <KGrid.Item xs={3}>
        <Controller
          name={name}
          control={methods.control}
          render={({ field }) => {
            return (
              <KInput.Autocomplete
                {...field}
                label={trans(`${numericalOrder}_bank_account`)}
                getOptionLabel={(o: any) => o?.accountNumber ?? ''}
                apiURL={ENDPOINTS.companyBankAccount()}
                addNewKey="accountNumber"
                hasAddNew
                hasEdit
                onAddNew={onShowBankAccount}
                onEdit={onShowBankAccount}
              />
            );
          }}
        />
      </KGrid.Item>
      <KGrid.Item xs={3}>
        <KInput.TextField
          name="address"
          label={trans('address')}
          value={companyBank?.branch?.address ?? ''}
          disabled
        />
      </KGrid.Item>
      <KGrid.Item xs={3}>
        <KInput.TextField
          name="companyBankSwiftCode"
          label={trans('swift_code')}
          value={companyBank?.swiftCode ?? ''}
          disabled
        />
      </KGrid.Item>
      <KGrid.Item xs={3}>
        <KInput.TextField
          name="companyBankAccountName"
          label={trans('account_name')}
          value={companyBank?.accountName ?? ''}
          disabled
        />
      </KGrid.Item>
      <KGrid.Item xs={4}>
        <KInput.TextField
          name="bsbNumber"
          label={trans('bsb_number')}
          value={companyBank?.bsbNumber ?? ''}
          disabled
        />
      </KGrid.Item>
      <KGrid.Item xs={4}>
        <KInput.TextField
          name="companyBankAccountNumber"
          label={trans('account_number')}
          value={companyBank?.accountNumber ?? ''}
          disabled
        />
      </KGrid.Item>
      <KGrid.Item xs={4}>
        <KInput.TextField
          name="currency"
          label={trans(`${numericalOrder}_currency`)}
          value={companyBank?.currency?.code ?? ''}
          disabled
        />
      </KGrid.Item>
    </KGrid.Container>
  );
};
