/* eslint-disable max-lines */
import React, { createRef, memo, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { AirOceanType, ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable, usePopperListItem } from 'hooks';
import { useImpExpTab } from 'pages/OceanFreight/helpers';
import trans from 'translation';
import {
  KBreadcrumbs,
  KChip,
  KColors,
  KContainer,
  KLabel,
  KListItemBaseItemProps
} from 'uikit';
import { TableUtils, UIUtils, mappedShipmentStatusData } from 'utils';
import { showNoteContent } from 'utils/common';

// import Attach from './Attach';
// import { useShipmentActions } from './helpers';

const OFShipment = () => {
  const navigate = useNavigate();

  const ref = createRef<HTMLButtonElement>();

  const { Tab, tabData } = useImpExpTab();

  const onShowDetails = useCallback(
    (item: any) => {
      if (item?.consolNumber) {
        return window.open(
          `/admin/air-freight/consolidation/${item?.controlledConsolId}/shipment/${item?.id}`
        );
      }
      navigate(`${item.id}`);
    },
    [navigate]
  );

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowDetails(item)
            }
          ])
        },
        {
          label: trans('number'),
          name: 'shipmentNumber',
          options: {
            ...TableUtils.options.baseXLg,
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    onShowDetails(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('consol_no'),
          name: 'consolNumber',
          options: {
            ...TableUtils.options.baseXLg,
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    !v || onShowDetails(item);
                  }}
                >
                  {v || (
                    <KChip
                      label={'N/A'}
                      width={'5rem'}
                      background={KColors.secondary.light}
                      color={KColors.secondary.normal}
                    />
                  )}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('job_order_no'),
          name: 'jobOrderNumber',
          options: {
            ...TableUtils.options.base
          }
        },
        {
          label: trans('status'),
          name: 'shipmentStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 194,
            css: { textAlign: 'center' },
            mapLabelData: mappedShipmentStatusData()
          })
        },
        {
          label: trans('booking_cancel'),
          name: 'isBookingCancel',
          options: TableUtils.options.iconCheck({ revert: true })
        },
        {
          label: trans('type'),
          name: 'type',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('container_mode'),
          name: 'contMode'
        },
        {
          label: trans('incoterm'),
          name: 'incotermCode'
        },
        {
          label: trans('delivery_mode'),
          name: 'deliveryMode',
          options: TableUtils.options.mappedOption()
        },
        {
          label: trans('consignor'),
          name: 'consignorName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('consignee'),
          name: 'consigneeName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('account_receivable'),
          name: 'accountReceivableName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('notify_party'),
          name: 'notifyPartyName',
          options: TableUtils.options.base2XLg
        },
        {
          label: trans('hbl_no'),
          name: 'hblNo'
        },
        {
          label: trans('container'),
          name: 'containerNumberList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onPress: item => {
              UIUtils.popup.open({
                title: trans('container'),
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {item?.map((v, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`container-number-list-popup-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.primary.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">{v}</KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            },
            renderItem: (v: any, idx) => {
              const marginL = idx === 0 ? 0 : '0.25rem';
              return (
                <KContainer.View
                  key={`container-number-list-${idx}`}
                  minW={150}
                  height={20}
                  marginL={marginL}
                  dp="flex"
                  center
                  background="#ACDCD7"
                  br="2x"
                  brW={1}
                  brC={KColors.primary.normal}
                >
                  <KLabel.Text
                    typo="TextSmMedium"
                    color={KColors.primary.normal}
                  >
                    {v}
                  </KLabel.Text>
                </KContainer.View>
              );
            }
          })
        },
        {
          label: trans('total_packs'),
          name: 'totalPacks',
          options: TableUtils.options.number()
        },
        {
          label: trans('with_unit.total_weight'),
          name: 'totalWeight',
          options: TableUtils.options.number()
        },
        {
          label: trans('client_code'),
          name: 'bookingClientCode',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    if (item?.bookingClientId) {
                      window.open(
                        `/admin/client/${item?.bookingClientId}`,
                        '_blank'
                      );
                    }
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('quotation'),
          name: 'quotationCode'
        },
        {
          label: trans('last_update'),
          name: 'updatedAt'
        },
        {
          label: trans('unloco_1st_origin'),
          name: 'firstOriginCode'
        },
        {
          label: trans('unloco_final_destination'),
          name: 'finalDesCode'
        },
        {
          label: trans('origin_service'),
          name: 'originService',
          options: {
            ...TableUtils.options.baseCenter,
            sort: false,
            customBodyRenderLite: (index: number) => {
              const rowData = data?.[index];
              if (rowData) {
                const { originIsCustoms, originIsHandling, originIsTransport } =
                  rowData;
                return (
                  <KContainer.View row dp="flex" center>
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={originIsTransport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">TR</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={originIsHandling}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">HD</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={originIsCustoms}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">CU</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },
        {
          label: trans('destination_service'),
          name: 'destinationService',
          options: {
            ...TableUtils.options.baseCenter,
            sort: false,
            customBodyRenderLite: (index: number) => {
              const rowData = data?.[index];
              if (rowData) {
                const { desIsCustoms, desIsHandling, desIsTransport } = rowData;
                return (
                  <KContainer.View row dp="flex" center>
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={desIsTransport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">TR</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={desIsHandling}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">HD</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={desIsCustoms}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background="rgba(250, 149, 149, 1)"
                          brW={1}
                          brC="rgba(212, 72, 62, 1)"
                          width="fit-content"
                        >
                          <KLabel.Text typo="TextMdNormal">CU</KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },
        {
          label: trans('1st_origin_etd'),
          name: 'firstEtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('1st_origin_atd'),
          name: 'firstAtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('final_des_eta'),
          name: 'finalEta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('final_des_ata'),
          name: 'finalAta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('unloco_port_of_loading'),
          name: 'polCode'
        },
        {
          label: trans('unloco_port_of_discharge'),
          name: 'podCode'
        },
        {
          label: trans('pol_etd'),
          name: 'polEtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('pol_atd'),
          name: 'polAtd',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('pod_eta'),
          name: 'podEta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('pod_ata'),
          name: 'podAta',
          options: TableUtils.options.dateWithOffset(data)
        },
        {
          label: trans('general_note'),
          name: 'noteContent',
          options: TableUtils.options.contentMore((item: any) => {
            showNoteContent(item);
          }, data)
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        }
      ];
    },
    [onShowDetails]
  );

  const mappedFields = useMemo(() => {
    return {
      incotermCode: 'incoterm',
      consignorName: 'consignor',
      consigneeName: 'consignee',
      accountReceivableName: 'accountReceivable',
      notifyPartyName: 'notifyParty',
      bookingClientCode: 'bookingClient',
      firstOriginCode: 'firstOrigin',
      finalDesCode: 'finalDes',
      polCode: 'pol',
      podCode: 'pod'
    };
  }, []);

  const otherParams = useMemo(() => {
    return {
      includeFields: [
        'finalDesTimezone',
        'firstOriginTimezone',
        'polTimezone',
        'podTimezone'
      ],
      freightMode: AirOceanType.Air,
      type: tabData
    };
  }, [tabData]);

  const table = useDataTable({
    name: WebTable.CONSOL_SHIPMENT,
    tableName: TableName.SHIPMENT,
    showTimeRange: true,
    columnsFunc,
    apiURL: ENDPOINTS.shipment(),
    otherParams,
    mappedFields,
    otherOptions: {
      selectableRows: 'none'
    }
  });

  useGlobalTable(table);

  const toolsData = useMemo(() => {
    const _data: KListItemBaseItemProps[] = [
      {
        title: trans('import_excel'),
        icon: {
          name: 'CloudUploadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('export_excel'),
        icon: {
          name: 'CloudDownloadOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {}
      },
      {
        title: trans('print'),
        icon: {
          name: 'Print',
          color: KColors.primary.normal
        },
        onPress: () => {}
      }
    ];
    return _data;
  }, []);

  const { onPress } = usePopperListItem({ btnRef: ref, data: toolsData });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.air_freight'), trans('routes.shipment')]}
        rightNode={{
          tools: {
            ref,
            onPress
          }
        }}
      />

      {Tab()}

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable {...table} showStatus={true} />
      </KContainer.Card>

      <Outlet />
    </>
  );
};

export default memo(OFShipment);
