import loadable from '@loadable/component';
import React, { useState, memo, useMemo } from 'react';

import trans from 'translation';
import { KContainer, KTab, KBreadcrumbs } from 'uikit';

import Client from './Client';
import Freight from './Freight';
import SaleMarketing from './Sales';

const Service = loadable(() => import('./Service'));
const Accounting = loadable(() => import('./Accounting'));
const Administration = loadable(() => import('./Administration'));
const Ecus = loadable(() => import('./Ecus'));
const Other = loadable(() => import('./Other'));

const Library = () => {
  const tabs = useMemo(() => {
    return [
      {
        key: 'client',
        label: trans('tab.client'),
        hasAccordion: true
      },
      {
        key: 'freight',
        label: trans('tab.freight')
      },
      {
        key: 'service',
        label: trans('tab.service')
      },
      {
        key: 'accounting',
        label: trans('tab.accounting')
      },
      {
        key: 'sales_marketing',
        label: trans('tab.sales_marketing')
      },
      // {
      //   key: 'report',
      //   label: trans('tab.report')
      // },
      {
        key: 'administration',
        label: trans('tab.administration'),
        hasAccordion: true
      },
      {
        key: 'ecus',
        label: trans('tab.ecus')
      },
      {
        key: 'other',
        label: trans('tab.other'),
        hasAccordion: true
      }
    ];
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.library'), trans('routes.category')]}
      />

      <KTab.Main tabs={tabs} onChangeTab={setTabIndex} />

      <KContainer.View
        paddingH="0.75rem"
        paddingV="0.25rem"
        marginB="4rem"
        // marginT={tabs[tabIndex].hasAccordion ? 0 : '0.75rem'}
      >
        <KTab.Panel value={tabIndex} index={0}>
          <Client />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={1}>
          <Freight />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={2}>
          <Service />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={3}>
          <Accounting />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={4}>
          <SaleMarketing />
        </KTab.Panel>

        {/* <KTab.Panel value={tabIndex} index={5}>
          report
        </KTab.Panel> */}

        <KTab.Panel value={tabIndex} index={5}>
          <Administration />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={6}>
          <Ecus />
        </KTab.Panel>

        <KTab.Panel value={tabIndex} index={7}>
          <Other />
        </KTab.Panel>
      </KContainer.View>
    </>
  );
};

export default memo(Library);
