/* eslint-disable max-lines */
import { IModifyMisa } from '@request-dto';
import { generatePath } from 'react-router-dom';

import {
  InvoiceCategory,
  ConsolType,
  ENDPOINTS,
  QUERY_KEYS,
  OriginDestinationType,
  WebTable,
  WipServiceType,
  tableRef,
  ShipmentType,
  SyncMisa
} from '@constants';
import APIManager, { END_POINTS } from '@services';
import { UIUtils } from 'utils';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchApInvoice = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.apInvoice(InvoiceCategory.Accounting, ':id', { id }),
        body: {
          includeFields: [
            'apInvoiceStatus',
            'paymentCurrency',
            'accountPayable',
            'accountPayableBank',
            'paymentMode',
            'companyBank'
          ]
        }
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchArInvoice = (id?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.arInvoice, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(InvoiceCategory.Accounting, ':id', { id }),
        body: {
          includeFields: [
            'arInvoiceStatus',
            'paymentCurrency',
            'accountReceivable',
            'arBank',
            'paymentMode',
            'companyBank'
          ]
        }
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchDefaultCalculateDueDateAp = (
  consolId?: string | number,
  apInvoiceId?: string | number
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.calculateDueDateAp, apInvoiceId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AP, {
          billingCategory: InvoiceCategory.Accounting
        }),
        body: {
          apInvoiceId,
          consolId
        }
      });

      return res?.data;
    },
    enabled: !!apInvoiceId && !!consolId
  });
};

export const useFetchDefaultCalculateDueDateAr = (
  arInvoiceId?: string | number
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.calculateDueDateAr, arInvoiceId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_DUE_DATE_AR, {
          billingCategory: InvoiceCategory.Accounting
        }),
        body: {
          arInvoiceId
        }
      });

      return res?.data;
    },
    enabled: !!arInvoiceId
  });
};

interface ReqWip {
  formData: FormData;
  shouldValidate: boolean;
}

export const useImportWip = (category = InvoiceCategory.Accounting) => {
  return useMutationEnhancer<any, ReqWip>({
    mutationFn: async req => {
      const { formData } = req;
      const res = await APIManager.request({
        url: ENDPOINTS.wip(category, 'import-excel'),
        method: 'POST',
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.data;
    },
    onSuccess: (_, req) => {
      const { shouldValidate } = req;
      if (!shouldValidate) {
        tableRef?.[WebTable.WIP]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useFetchCurrencyUplift = async (
  currencyId?: string | number,
  type?: ConsolType | ShipmentType,
  contMode?: string,
  clientId?: string | number,
  chargeCodeId?: string | number
) => {
  if (!!currencyId && !!type && !!contMode && !!clientId && !!chargeCodeId) {
    const res = await APIManager.request({
      url: generatePath(END_POINTS.CONSOL.GET_CURRENCY_UPLIFT, {
        clientId
      }),
      body: {
        currencyId,
        consolType: type,
        contMode,
        chargeCodeId
      }
    });
    return res?.data?.data;
  }
  return undefined;
};

export const useFetchCalculateWipCost = async (
  consolId?: string | number,
  // accountPayableId?: string | number,
  chargeCodeId?: string | number,
  arChargeCodeFromId?: string | number,
  arChargeCodeToId?: string | number,
  containerTypeSizeId?: string | number,
  serviceType?: WipServiceType
) => {
  const isCallRequest = !!consolId && !!chargeCodeId && !!serviceType;

  if (isCallRequest) {
    const res = await APIManager.request({
      url: generatePath(END_POINTS.CONSOL.GET_CALCULATE_WIP_COST, {
        billingCategory: InvoiceCategory.Accounting
      }),
      body: {
        consolId,
        chargeCodeId,
        arChargeCodeFromId,
        arChargeCodeToId,
        containerTypeSizeId,
        serviceType
      }
    });
    return res.data;
  }
  return null;
};

export const useValidateImportArChargeCode = (
  arInvoiceId: string | number,
  category = InvoiceCategory.Accounting
) => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(
          category,
          ':arInvoiceId/ar-charge-code/import-validate',
          { arInvoiceId }
        ),
        method: 'POST',
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    }
  });
};

export const useImportArChargeCode = (
  arInvoiceId: string | number,
  category = InvoiceCategory.Accounting
) => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.arInvoice(
          category,
          ':arInvoiceId/ar-charge-code/import-excel',
          { arInvoiceId }
        ),
        method: 'POST',
        body: formData,
        showToast: true,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    },
    onSuccess: () => {
      tableRef?.[WebTable.AR_CHARGE_CODE_LIST]?.init?.();
      UIUtils.popup.dismiss();
    }
  });
};

export const useFetchListForTransport = (
  consolId?: string | number,
  type?: OriginDestinationType
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.listForTransport],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CONSOL.GET_LIST_FOR_TRANSPORT, {
          consolId
        }),
        body: {
          consolId,
          type
        }
      });

      return res?.data?.[0] || null;
    },
    enabled: !!consolId && !!type
  });
};

export const useFetchAccountingStatistics = (
  mParams: any,
  secondCurrencyId?: number | string
) => {
  const { category, ...rest } = mParams;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.accountingStatistics, secondCurrencyId, mParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statistics(category),
        body: {
          secondCurrencyId,
          ...rest
        }
      });

      return res?.data;
    },
    keepPreviousData: false,
    enabled: !!mParams.consolId || !!mParams.shipmentId || !!mParams.serviceId
  });
};

export const useCUDMisa = (path: SyncMisa, webTable: WebTable) => {
  const {
    createMutation: { mutate },
    modifyLoading
  } = useCUDMutationEnhancer<IModifyMisa>({
    endPoint: ENDPOINTS.syncMisa(path),
    webTable: webTable,
    mutationKeys: [
      [
        QUERY_KEYS.arInvoice,
        QUERY_KEYS.apInvoice,
        QUERY_KEYS.billingArInvoice,
        QUERY_KEYS.billingApInvoice
      ]
    ]
  });

  return {
    createMutation: mutate,
    modifyLoading
  };
};

export const useCUDStatementPayment = () => {
  return usePageCUDMutationEnhancer({
    mutationKeys: [[QUERY_KEYS.statementPayment]],
    endPoint: ENDPOINTS.statementPayment(),
    webTable: WebTable.ACCOUNTING_STATEMENT_PAYMENT,
    queryKey: QUERY_KEYS.statementPayment
  });
};

export const useFetchStatementPaymentDetail = (paymentId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.statementPayment, paymentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.statementPayment('/:paymentId', {
          paymentId: paymentId
        }),
        body: {
          includeFields: [
            'currency',
            'paymentMode',
            'client',
            'clientAddress',
            'companyBank',
            'details'
          ]
        }
      });

      return res?.data;
    },
    enabled: !!paymentId
  });
};
